<form #userForm="ngForm" (submit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="user">
    <h1 class="text-center">
      {{ user.person.first_name || 'Agrega un nombre a la persona'}}
    </h1>
    <div class="ui-form-group one-half">
      <ui-input required label="Correo electrónico" name="user_email" [(ngModel)]="user.email"
        placeholder="ejemplo@email.com">
      </ui-input>
      <ui-select *ngIf="currentUserRole === 'admin'" required label="Estado" name="user_status" [(ngModel)]="user.status"
        [options]="statusOptions">
      </ui-select>
      <ui-select *ngIf="currentUserRole === 'admin'" required label="Grupo" name="user_user_group" [(ngModel)]="user.user_group"
        [options]="userGroupsOpts">
      </ui-select>
      <ui-input required type="password" label="Contraseña" name="user_password" [(ngModel)]="user.password"
        placeholder="Contraseña de 8 digitos">
      </ui-input>
      <ui-input required label="Nombre" name="user_person_first_name" [(ngModel)]="user.person.first_name"
        placeholder="Nombre">
      </ui-input>
      <ui-input required label="Apellido" name="user_person_last_name" [(ngModel)]="user.person.last_name"
        placeholder="Apellido">
      </ui-input>
      <ui-select required label="Género" name="user_person_gender" [(ngModel)]="user.person.gender"
        [options]="genderOptions">
      </ui-select>
      <ui-datepicker label="Fecha de nacimiento" [(ngModel)]="user.person.birth_date" name="user_person_birth_date">
      </ui-datepicker>
      <ui-input label="Telefono" name="user_person_phone" [(ngModel)]="user.person.phone"
        placeholder="(+57) 310 123 1234">
      </ui-input>
      <ui-input label="Nombre corto" name="user_person_nickname" [(ngModel)]="user.person.nickname"
        placeholder="Nombre corto">
      </ui-input>
      <ui-input label="Url personal" name="user_person_url" [(ngModel)]="user.person.url"
        placeholder="https://...">
      </ui-input>
      <ui-input label="Ocupación" name="user_person_occupation" [(ngModel)]="user.person.occupation"
        placeholder="Ocupación">
      </ui-input>
      <ui-input label="Cargo" name="user_person_job_title" [(ngModel)]="user.person.job_title"
        placeholder="Cargo">
      </ui-input>
      <!--<ui-select label="Tipo Id" name="user_person_identification_type"
        [(ngModel)]="user.person.identification_type" placeholder="Cédula, NIT, Pasaporte, etc."
        [options]="customersSrv.idTypes">
      </ui-select>-->
      <!-- <ui-input label="Id" name="user_person_identificacion" [(ngModel)]="user.person.identification"
        placeholder="4815162342">
      </ui-input> -->
      <!--<ui-select name="project_customer_id" label="Cliente" [(ngModel)]="project.customer_id"
        [options]="customersOpts" placeholder="Selecciona un cliente de la lista">
      </ui-select>-->
    </div>
  </div>

  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="saveUser()" hint="Guardar persona"
        [disabled]="!userForm.form.valid" icon="save">
      </ui-btn>
    </div>
  </div>
</form>

<div class="ui-panel no-padding">
  <ng-container *ngIf="!isLoading; else loading">
    <task-collection hideColumns="group" [tasks]="tasks" orderOnUpdate="true">
    </task-collection>
    <mat-paginator [pageIndex]="paginator.pageIndex" [length]="paginator.length" [pageSize]="paginator.pageSize"
      [pageSizeOptions]="paginator.pageSizeOptions" (page)="changePage($event)">
    </mat-paginator>
  </ng-container>
  <ng-template #loading>
    <div class="padding" fxLayout="row" fxLayoutAlign="center cener">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__secondary">
    <ui-btn *ngIf="filtered_by == 'active'" fab color="success" (uiClick)="disableFilterBy()"
      hint="Ver todas las tareas" icon="list-check">
    </ui-btn>
    <ui-btn *ngIf="filtered_by == 'all'" fab color="warning" (uiClick)="filterByStatus()"
      hint="Ver solo tareas pendientes" icon="list">
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/tasks/tasks/new')" hint="Crear tarea" icon="add">
    </ui-btn>
  </div>
</div>


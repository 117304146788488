import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';
import { PurchaseOrdersService } from 'src/app/core/services/purchaseOrders.service';

import { OrdersService } from '../../core/services/orders.service';
import { UiService } from '../../core/services/ui.service';
import { OrderStatus } from '../../shared/enums';
import { OrderStatusPipe } from '../../shared/pipes/';
import { buyOrders, sellOrders } from './modeHelpers';
@Component({
  selector: 'orders-index',
  templateUrl: 'orders-index.template.html',
})
export class OrdersIndexComponent implements OnInit {
  public ORDER_STATUSES: any[];
  public isLoading: boolean;
  public mode: 'buy' | 'sell';
  private DEFAULT_PAGE_SIZE = 25;
  private DEFAULT_PAGE_INDEX = 0;
  public paginator = {
    pageIndex: this.DEFAULT_PAGE_INDEX,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  };
  public filtersForm: FormGroup;
  public filters = {
    orderName: null,
    orderStatus: null,
    customerName: null,
    orderSequence: null,
    orderCreatedAtStart: null,
    orderCreatedAtEnd: null,
  };
  private orders;
  private orderStatusPipe = new OrderStatusPipe();
  private noSyncForm = false;

  constructor(
    private ordersSrv: OrdersService,
    private purchaseOrdersSrv: PurchaseOrdersService,
    private router: Router,
    private ui: UiService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initFiltersForm();

    this.activatedRoute.data.subscribe(({ mode }) => {
      this.mode = mode;
      this.ui.toolbar.title = this.modeTexts.listTitle;
      this.ORDER_STATUSES = this.orderStatusesOptions;
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (!this.noSyncForm && Object.keys(params).length > 0) {
        this.syncFilterWithUrl(params);
      } else {
        this.noSyncForm = false;
      }
      this.isLoading = Object.keys(params).length === 0 ? undefined : this.isLoading;
      if (this.isLoading === undefined) {
        this.updateRouterQueryParams();
      }
      this.fetch();
    });
  }

  initFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      orderName: [],
      customerName: [],
      orderStatus: [],
      orderSequence: [],
      orderCreatedAtStart: [],
      orderCreatedAtEnd: [],
    });
  }

  fetch() {
    this.isLoading = true;
    if (this.mode === 'buy') {
      this.purchaseOrdersSrv
        .fetch(this.indexQueryParams)
        .then((res) => {
          this.paginator.length = res.pagination.total;
          this.orders = res.collection.asArray().reverse();
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    } else {
      this.ordersSrv
        .fetch(this.indexQueryParams)
        .then((res) => {
          this.paginator.length = res.pagination.total;
          this.orders = res.collection.asArray().reverse();
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    }
  }

  changePage(paginator: any) {
    this.paginator.pageIndex = paginator.pageIndex;
    this.paginator.pageSize = paginator.pageSize;
    this.updateRouterQueryParams();
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }

  filter() {
    const value = this.filtersForm.value;
    Object.assign(this.filters, value);
    this.paginator.pageIndex = 0;
    this.noSyncForm = true;
    this.updateRouterQueryParams();
  }

  clearFilter(
    filterKey:
      | 'orderName'
      | 'customerName'
      | 'orderStatus'
      | 'orderSequence'
      | 'orderCreatedAtStart'
      | 'orderCreatedAtEnd'
  ) {
    this.filters[filterKey] = null;
    this.filtersForm.get(filterKey).setValue(null);
    this.filter();
  }

  get activeFilters() {
    return (
      +!!this.filters.orderName +
      +!!this.filters.customerName +
      +!!this.filters.orderStatus +
      +!!this.filters.orderSequence +
      +!!this.filters.orderCreatedAtStart +
      +!!this.filters.orderCreatedAtEnd
    );
  }

  private syncFilterWithUrl(queryParams: Params) {
    // console.warn('syncFilterWithUrl', queryParams);

    this.paginator.pageIndex = queryParams.page > -1 ? queryParams.page - 1 : this.DEFAULT_PAGE_INDEX;
    this.paginator.pageSize = this.isPageSizeValid(queryParams.per_page)
      ? Number(queryParams.per_page)
      : this.DEFAULT_PAGE_SIZE;
    this.filters.customerName = queryParams.customer_name;
    this.filters.orderName = queryParams.name;
    this.filters.orderSequence = queryParams.sequence;
    this.filters.orderStatus = queryParams.status;
    this.filters.orderCreatedAtStart = queryParams.created_at_gteq;
    this.filters.orderCreatedAtEnd = queryParams.created_at_lteq;

    this.updateFiltersForm();
  }

  private updateFiltersForm() {
    this.filtersForm.controls.customerName.setValue(this.filters.customerName);
    this.filtersForm.controls.orderName.setValue(this.filters.orderName);
    this.filtersForm.controls.orderSequence.setValue(this.filters.orderSequence);
    this.filtersForm.controls.orderStatus.setValue(this.filters.orderStatus);
    this.filtersForm.controls.orderCreatedAtStart.setValue(this.filters.orderCreatedAtStart);
    this.filtersForm.controls.orderCreatedAtEnd.setValue(this.filters.orderCreatedAtEnd);
  }

  private updateRouterQueryParams() {
    const queryParams = this.formatToQueryParams;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  private isPageSizeValid(pageSize: string) {
    return this.paginator.pageSizeOptions.indexOf(Number(pageSize)) !== -1;
  }

  private get indexQueryParams() {
    const pageSize = this.paginator.pageSize;
    const pageIndex = this.paginator.pageIndex + 1;

    return `?page=${pageIndex}&per_page=${pageSize}${this.queryFilters}`;
  }

  private get queryFilters() {
    const {
      orderName,
      customerName,
      orderStatus,
      orderSequence,
      orderCreatedAtStart,
      orderCreatedAtEnd,
    } = this.filters;

    let url = '';

    if (this.activeFilters > 0) {
      if (customerName) {
        const userParam = this.mode === 'buy' ? 'supplier_name_cont' : 'customer_name_cont';
        url += `&q[${userParam}]=${customerName}`;
      }
      if (orderName) {
        url += `&q[name_cont]=${orderName}`;
      }
      if (orderStatus) {
        url += `&q[status_eq]=${orderStatus}`;
      }
      if (orderSequence) {
        url += `&q[sequence_eq]=${orderSequence}`;
      }
      if (orderCreatedAtStart) {
        url += `&q[created_at_gteq]=${moment(orderCreatedAtStart, 'YYYY-MM-DD')
          .set({ hour: 0, minute: 0, second: 0 })
          .format()}`;
      }
      if (orderCreatedAtEnd) {
        url += `&q[created_at_lteq]=${moment(orderCreatedAtEnd, 'YYYY-MM-DD')
          .set({ hour: 23, minute: 59, second: 59 })
          .format()}`;
      }
    }
    return url;
  }

  private get orderStatusesOptions() {
    return Object.values(OrderStatus)
      .filter((status) => this.mode === 'sell' || ['finished', 'initial', 'canceled'].includes(status))
      .map((status) => ({
        label: this.orderStatusPipe.transform(status),
        value: status,
      }));
  }

  private get formatToQueryParams() {
    const {
      orderName,
      customerName,
      orderStatus,
      orderSequence,
      orderCreatedAtStart,
      orderCreatedAtEnd,
    } = this.filters;

    const params = {
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
    } as Params;

    if (orderName) {
      params.name = orderName;
    }
    if (orderSequence) {
      params.sequence = orderSequence;
    }
    if (orderStatus) {
      params.status = orderStatus;
    }
    if (customerName) {
      params.customer_name = customerName;
    }
    if (customerName) {
      params.customer_name = customerName;
    }
    if (orderCreatedAtStart) {
      params.created_at_gteq = orderCreatedAtStart;
    }
    if (orderCreatedAtEnd) {
      params.created_at_lteq = orderCreatedAtEnd;
    }
    return params;
  }

  downloadCSV() {
    this.ordersSrv
      .downloadData(`download?pagin=false${this.queryFilters}`, 'text/csv', `Reporte órdenes y costos.csv`)
      .then(() => {
        this.ui.snackbar.show('Reporte CSV generado correctamente!');
      })
      .catch(() => {
        this.ui.snackbar.show('Error al generar el reporte CSV');
      });
  }

  public get modeTexts() {
    return this.mode === 'sell' ? sellOrders : buyOrders;
  }
}

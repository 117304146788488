<form #productForm="ngForm" (submit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="product">
    <h1 class="text-center" [class.color-disabled]="!product.name">
      {{ product.name || 'Agrega un nombre de producto'}}
    </h1>
    <ui-input label="Nombre" name="product_name" [(ngModel)]="product.name" placeholder="Producto de ejemplo" required>
    </ui-input>
    <ui-input type="textarea" label="Descripción" name="product_description" [(ngModel)]="product.description"
      placeholder="Añade la descripción de tu producto aquí">
    </ui-input>
    <div class="ui-form-group one-half">
      <ui-input type="number" label="Costo" name="product_base_cost" [(ngModel)]="product.base_cost"
        (ngModelChange)="productBaseCostChangeHandler($event)" (focus)="productBaseCostFocusHandler()" placeholder="">
      </ui-input>
      <ui-input type="number" label="Porcentaje de ganancia" name="product_profit_rate"
        [(ngModel)]="product.profit_rate" (ngModelChange)="productProfitRateChangeHandler($event)"
        (focus)="productProfitRateFocusHandler()" placeholder="">
      </ui-input>
      <ui-input type="number" label="Precio base" name="product_base_price" [(ngModel)]="product.base_price"
        (ngModelChange)="productBasePriceChangeHandler($event)" (focus)="productBasePriceFocusHandler()" placeholder="">
      </ui-input>
      <ui-input type="number" label="Cantidad mínima" name="minimum_quantity" [(ngModel)]="product.minimum_quantity"
        placeholder="">
      </ui-input>
      <ui-input type="number" label="Cantidad máxima" name="maximum_quantity" [(ngModel)]="product.maximum_quantity"
        placeholder="">
      </ui-input>
    </div>
    <ui-check label="Activo" [(ngModel)]="product.status" name="status" [disabled]="!isAuthorized()"></ui-check>
    <ui-check label="Precio bloqueado" [(ngModel)]="product.price_locked" name="price_locked"
      [disabled]="!isAuthorized()"></ui-check>
    <ui-check label="Genérico" [(ngModel)]="product.generic" name="generic" [disabled]="!isAuthorized()"></ui-check>
  </div>
  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="saveProduct()" hint="Guardar producto" [disabled]="!productForm.form.valid"
        icon="save">
      </ui-btn>
    </div>
  </div>
</form>
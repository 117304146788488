import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import * as moment from 'moment';

import { TasksService } from '../../core/services/tasks.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';
import { TasksOrderBy, TasksDateRange, TaskStatus } from '../../shared/enums';
import { TasksOrderByPipe, TaskStatusPipe } from '../../shared/pipes/';

@Component({
  selector: 'tasks-index',
  templateUrl: 'tasks-index.template.html',
})
export class TasksIndexComponent implements OnInit {
  private tasks;
  public TASKS_ORDER_OPTIONS: any[];
  public TASK_STATUSES: any[];
  public DATE_RANGE_OPTIONS = [
    {
      value: TasksDateRange.TODAY,
      label: 'Para hoy',
    },
    {
      value: TasksDateRange.TOMORROW,
      label: 'Para mañana',
    },
    {
      value: TasksDateRange.THIS_WEEK,
      label: 'Para esta semana',
    },
  ];

  private DEFAULT_PAGE_SIZE = 25;
  private DEFAULT_PAGE_INDEX = 0;
  public paginator = {
    pageIndex: this.DEFAULT_PAGE_INDEX,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  };
  public filtersForm: FormGroup;
  public filters = {
    taskUserGroup: null,
    taskUser: null,
    taskStatus: 'active',
    tasksOrderBy: null,
    taskDueDateStart: null,
    taskDueDateEnd: null,
    dateRange: null,
  };
  public isLoading: boolean;
  private isAuthorized: boolean = false;
  private noSyncForm: boolean = false;
  private tasksOrderByPipe = new TasksOrderByPipe();
  private taskStatusPipe = new TaskStatusPipe();

  constructor(
    private tasksSrv: TasksService,
    private router: Router,
    private ui: UiService,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.ui.toolbar.title = 'Tareas';

    this.isAuthorized = this.auth.isAuthorized();
    this.TASKS_ORDER_OPTIONS = this.tasksOrderByOptions;
    this.TASK_STATUSES = this.taskStatuses;

    this.initFiltersForm();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (!this.noSyncForm && Object.keys(params).length > 0) {
        this.syncFilterWithUrl(params);
      } else {
        this.noSyncForm = false;
      }
      this.isLoading = Object.keys(params).length === 0 ? undefined : this.isLoading;
      if (this.isLoading === undefined) {
        this.updateRouterQueryParams();
      }
      this.fetch();
    });
  }

  initFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      taskUserGroup: [],
      taskUser: [],
      taskStatus: [],
      tasksOrderBy: [],
      taskDueDateStart: [],
      taskDueDateEnd: [],
      dateRange: [],
    });

    this.filtersForm.get('taskDueDateStart').valueChanges.subscribe((val) => {
      this.filtersForm.get('dateRange').setValue(null, { emitEvent: false });
    });

    this.filtersForm.get('taskDueDateEnd').valueChanges.subscribe((val) => {
      this.filtersForm.get('dateRange').setValue(null, { emitEvent: false });
    });

    this.filtersForm.get('dateRange').valueChanges.subscribe((val) => {
      let date;
      switch (val) {
        case TasksDateRange.TODAY:
          date = moment().format('YYYY-MM-DD');
          this.filtersForm.get('taskDueDateStart').setValue(date, { emitEvent: false });
          this.filtersForm.get('taskDueDateEnd').setValue(date, { emitEvent: false });
          break;
        case TasksDateRange.TOMORROW:
          date = moment().add(1, 'days').format('YYYY-MM-DD');
          this.filtersForm.get('taskDueDateStart').setValue(date, { emitEvent: false });
          this.filtersForm.get('taskDueDateEnd').setValue(date, { emitEvent: false });
          break;
        case TasksDateRange.THIS_WEEK:
          date = moment().startOf('week');
          this.filtersForm.get('taskDueDateStart').setValue(date.format('YYYY-MM-DD'), { emitEvent: false });
          this.filtersForm
            .get('taskDueDateEnd')
            .setValue(date.add(6, 'days').format('YYYY-MM-DD'), { emitEvent: false });
          break;
        default:
          break;
      }
    });
  }

  fetch() {
    this.isLoading = true;
    this.tasksSrv
      .fetch(this.indexQueryParams)
      .then((res) => {
        this.paginator.length = res.pagination.total;
        this.tasks = res.collection;
        this.isLoading = false;
      })
      .catch(() => (this.isLoading = false));
  }

  changePage(paginator: any) {
    this.paginator.pageIndex = paginator.pageIndex;
    this.paginator.pageSize = paginator.pageSize;
    this.updateRouterQueryParams();
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }

  filter() {
    const value = this.filtersForm.value;
    Object.assign(this.filters, value);
    this.paginator.pageIndex = 0;
    this.noSyncForm = true;
    this.updateRouterQueryParams();
  }

  clearFilter(
    filterKey: 'taskUserGroup' | 'taskUser' | 'taskStatus' | 'tasksOrderBy' | 'taskDueDateStart' | 'taskDueDateEnd'
  ) {
    this.filtersForm.get(filterKey).setValue(null);
    this.filter();
  }

  get activeFilters() {
    return (
      +!!this.filters.taskUserGroup +
      +!!this.filters.taskUser +
      +!!this.filters.taskStatus +
      +!!this.filters.tasksOrderBy +
      +!!this.filters.taskDueDateStart +
      +!!this.filters.taskDueDateEnd
    );
  }

  private syncFilterWithUrl(queryParams: Params) {
    // console.warn('syncFilterWithUrl', queryParams);

    this.paginator.pageIndex = queryParams.page > -1 ? queryParams.page - 1 : this.DEFAULT_PAGE_INDEX;
    this.paginator.pageSize = this.isPageSizeValid(queryParams.per_page)
      ? Number(queryParams.per_page)
      : this.DEFAULT_PAGE_SIZE;
    this.filters.taskUserGroup = queryParams.user_group;
    this.filters.taskUser = queryParams.assigned_at;
    this.filters.taskStatus = queryParams.status;
    this.filters.tasksOrderBy = queryParams.order_by;
    this.filters.taskDueDateStart = queryParams.due_date_start;
    this.filters.taskDueDateEnd = queryParams.due_date_end;
    this.filters.dateRange = queryParams.date_range;

    this.updateFiltersForm();
  }

  private updateFiltersForm() {
    this.filtersForm.controls.taskUserGroup.setValue(this.filters.taskUserGroup);
    this.filtersForm.controls.taskUser.setValue(this.filters.taskUser);
    this.filtersForm.controls.taskStatus.setValue(this.filters.taskStatus);
    this.filtersForm.controls.tasksOrderBy.setValue(this.filters.tasksOrderBy);
    this.filtersForm.controls.taskDueDateStart.setValue(this.filters.taskDueDateStart);
    this.filtersForm.controls.taskDueDateEnd.setValue(this.filters.taskDueDateEnd);
    this.filtersForm.controls.dateRange.setValue(this.filters.dateRange);
  }

  private updateRouterQueryParams() {
    const queryParams = this.formatToQueryParams;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  private isPageSizeValid(pageSize: string) {
    return this.paginator.pageSizeOptions.indexOf(Number(pageSize)) !== -1;
  }

  private get indexQueryParams() {
    const pageSize = this.paginator.pageSize;
    const pageIndex = this.paginator.pageIndex + 1;

    return `?page=${pageIndex}&per_page=${pageSize}${this.queryFilters}`;
  }

  private get queryFilters() {
    const { taskUserGroup, taskUser, taskStatus, tasksOrderBy, taskDueDateStart, taskDueDateEnd } = this.filters;

    let url = '';
    if (this.activeFilters > 0) {
      if (taskUserGroup) {
        url += `&q[user_group_name_cont]=${taskUserGroup}`;
      }
      if (taskUser) {
        url += `&q[user_person_first_name_cont]=${taskUser}`;
      }
      if (taskDueDateStart) {
        url += `&q[due_date_gteq]=${moment(taskDueDateStart, 'YYYY-MM-DD')
          .set({ hour: 0, minute: 0, second: 0 })
          .format()}`;
      }
      if (taskDueDateEnd) {
        url += `&q[due_date_lteq]=${moment(taskDueDateEnd, 'YYYY-MM-DD')
          .set({ hour: 23, minute: 59, second: 59 })
          .format()}`;
      }
      if (tasksOrderBy) {
        url += `&order_by=${tasksOrderBy}`;
      }
      if (taskStatus) {
        url += `&q[status_eq]=${taskStatus}`;
      }
    }
    return url;
  }

  private get tasksOrderByOptions() {
    return (<any>Object).values(TasksOrderBy).map((orderBy: string) => {
      return {
        label: this.tasksOrderByPipe.transform(orderBy),
        value: orderBy,
      };
    });
  }

  private get taskStatuses() {
    return (<any>Object).values(TaskStatus).map((taskStatus: string) => {
      return {
        label: this.taskStatusPipe.transform(taskStatus),
        value: taskStatus,
      };
    });
  }

  private get formatToQueryParams() {
    const {
      taskUserGroup,
      taskUser,
      taskStatus,
      tasksOrderBy,
      taskDueDateStart,
      taskDueDateEnd,
      dateRange,
    } = this.filters;
    const params = {
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
    } as Params;

    if (taskUserGroup) {
      params.user_group = taskUserGroup;
    }
    if (taskUser) {
      params.assigned_at = taskUser;
    }
    if (taskStatus) {
      params.status = taskStatus;
    }
    if (tasksOrderBy) {
      params.order_by = tasksOrderBy;
    }
    if (taskDueDateStart) {
      params.due_date_start = taskDueDateStart;
    }
    if (taskDueDateEnd) {
      params.due_date_end = taskDueDateEnd;
    }
    if (dateRange) {
      params.date_range = dateRange;
    }
    if (this.isLoading === undefined) {
      if (!taskStatus) {
        params.status = 'active';
      }
      if (!tasksOrderBy) {
        params.order_by = 'due_date';
      }
    }
    return params;
  }

  createTaskRediret() {
    if (this.isAuthorized) {
      this.goTo(`/tasks/tasks/new`);
    } else {
      this.ui.snackbar.show('No tienes permisos para crear tareas.');
    }
  }

  handleTaskUpdate() {
    this.fetch();
  }

  downloadCSV() {
    this.tasksSrv
      .downloadData(`download?pagin=false${this.queryFilters}`, 'text/csv', `Reporte tareas.csv`)
      .then(() => {
        this.ui.snackbar.show('Reporte CSV generado correctamente!');
      })
      .catch(() => {
        this.ui.snackbar.show('Error al generar el reporte CSV');
      });
  }
}

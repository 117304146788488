import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trunc'
})
export class TruncPipe implements PipeTransform {
  transform(value: any, length:any): Array<Object> {
    // console.warn('executing filter pipe');
    var addText;
    if (value) {
      if (value.length > length) {
        addText = '...';
      } else {
        addText = '';
      }
      return value.substr(0,length)+addText;
    } else {
      return addText;
    }
  }
}

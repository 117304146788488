<div *ngIf="task">
  <div
    class="ui-panel"
    [class.ui-panel--canceled]="(tasksSrv.taskIsDue(task) == 'canceled')"
    [class.ui-panel--warning]="(tasksSrv.taskIsDue(task) == 'pending')"
    [class.ui-panel--success]="(tasksSrv.taskIsDue(task) == 'done')"
    [class.ui-panel--error]="(tasksSrv.taskIsDue(task) == 'due')"
  >
    <h1 class="text-center">{{ task.name }}</h1>
    <div class="ui-field-group one-half">
      <ui-field-display label="Asignado el" [value]="task.assigned_at | date: 'dd/MM/yyyy'"></ui-field-display>
      <ui-field-display label="Vence el" [value]="task.due_date | date: 'dd/MM/yyyy'"></ui-field-display>
      <ui-field-display label="Cerrada el" [value]="task.finished_at | date: 'dd/MM/yyyy'"></ui-field-display>
      <ui-field-display
        label="Proyecto"
        [value]="task.project?.name"
        (click)="goTo('/tasks/projects/' + task.project?.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display
        label="Asignado a"
        *ngIf="task.user"
        [value]="task.user?.person.first_name + ' ' + task.user?.person.last_name"
        (click)="goTo('/tasks/users/' + task.user?.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display
        label="Asignado al grupo"
        [value]="task.user_group?.name"
        (click)="goTo('/tasks/usergroups/' + task.user_group.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display
        label="Registrado por"
        *ngIf="task.registered_by"
        [value]="task.registered_by?.person.first_name + ' ' + task.registered_by?.person.last_name"
        (click)="goTo('/tasks/users/' + task.registered_by?.id )"
        clickable
      >
      </ui-field-display>
    </div>
    <ui-field-display label="Descripción" [value]="task.description"></ui-field-display>
    <div class="margin-top">
      <ui-btn *ngIf="(task.status == 'active')" color="success" (uiClick)="finishTask()" icon="check">
        Terminar Tarea
      </ui-btn>
    </div>
  </div>
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-paperclip"></span>
    Archivos adjuntos
  </h3>
  <div class="ui-panel no-border">
    <attachment-collection
      #attCollectionEl
      [attachments]="task.attachments"
      [attachableId]="task.id"
      attachableType="Task"
      (afterDelete)="onDeleteFinish($event)"
      (afterUpload)="onUploadFinish($event)"
    >
    </attachment-collection>
  </div>
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-chat"></span>
    Comentarios
  </h3>
  <div class="ui-panel">
    <form class="ui-task-comment" (submit)="addComment($event)">
      <img class="ui-task-comment__avatar" [src]="currentUser.person.avatar_url" />
      <ui-input
        *ngIf="comment"
        type="textarea"
        class="ui-task-comment--new__input"
        [(ngModel)]="comment.comment"
        name="comment"
        placeholder="Añadir comentario"
      ></ui-input>
      <ui-btn class="ui-task-comment--new__btn" [type]="'submit'" icon="arrow-forward"></ui-btn>
    </form>
  </div>
  <div *ngFor="let c of task.comments" class="ui-panel no-padding no-border">
    <div class="ui-task-comment">
      <div class="ui-task-comment--wrapper">
        <img class="ui-task-comment__avatar" [src]="c.user.person.avatar_url" />
        <div class="ui-task-comment__message">
          <div>
            <span class="ui-task-comment__message__name">{{c.user.person.first_name}} {{c.user.person.last_name}}</span>
            <span class="ui-task-comment__message__date">{{c.created_at}}</span>
          </div>
          <span *ngIf="editComment != c" class="ui-task-comment__message__comment">{{ c.comment }}</span>
          <form
            class="ui-task-comment__message__edit"
            *ngIf="editComment && editComment == c"
            (submit)="updateComment($event)"
          >
            <ui-input
              type="textarea"
              class="ui-task-comment__message__edit__input"
              [(ngModel)]="editComment.comment"
              name="comment"
              placeholder="Añadir comentario"
            ></ui-input>
            <ui-btn class="ui-task-comment--new__btn" [type]="'submit'" icon="arrow-forward"></ui-btn>
          </form>
        </div>
        <div class="ui-task-comment__message__options">
          <a
            *ngIf="c.user.id === currentUser.id"
            class="ui-task-comment__btn"
            (click)="showEditComment(c)"
            title="Editar comentario"
          >
            <span class="icon icon-edit"></span>
          </a>
          <a
            *ngIf="c.user.id === currentUser.id"
            class="ui-task-comment__btn"
            (click)="deleteComment(c)"
            title="Eliminar comentario"
          >
            <span class="icon icon-delete"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ui-modal [isOpen]="modalDialogOpen">
  <h2>¿Confirmas que deseas cancelar la tarea?</h2>
  <div class="margin-top text-end">
    <ui-btn (uiClick)="closeCancelModal()" flat notab> Cancelar </ui-btn>
    <ui-btn (uiClick)="cancelTask()" icon="check"> ¡Confirmo! </ui-btn>
  </div>
</ui-modal>

<div class="ui-speed-dial" *ngIf="task">
  <div class="ui-speed-dial__secondary">
    <!-- Cancel task -->
    <ui-btn
      *ngIf="isAuthorized && task.status == 'active'"
      fab
      color="error"
      (uiClick)="showCancelModal()"
      hint="Cancelar tarea"
      icon="close"
    >
    </ui-btn>
    <!-- ACtivate task -->
    <ui-btn
      *ngIf="isAuthorized && task.status == 'canceled'"
      fab
      color="warning"
      (uiClick)="activateTask()"
      hint="Activar tarea"
      icon="renew"
    >
    </ui-btn>
    <!-- Attach file -->
    <ui-btn
      *ngIf="isAuthorized && attachFilesAllowed() && task.status !== 'canceled'"
      fab
      color="info"
      (uiClick)="attachFile()"
      hint="Adjuntar un archivo"
      icon="paperclip"
    >
    </ui-btn>
    <!-- Finish task -->
    <ui-btn
      *ngIf="(task.status == 'active')"
      fab
      color="success"
      (uiClick)="finishTask()"
      hint="Terminar tarea"
      icon="check"
    >
    </ui-btn>
    <!-- Edit task -->
    <ui-btn
      *ngIf="isAuthorized"
      fab
      color="accent"
      (uiClick)="goTo('/tasks/tasks/' + (task?.id || 0) + '/edit')"
      hint="Editar tarea"
      icon="edit"
    >
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <!-- Open menu -->
    <ui-btn fab color="accent" hint="Opciones" icon="menu"> </ui-btn>
  </div>
</div>

<div *ngIf="condition">
  <div class="ui-panel">
    <h1 class="text-center">{{ condition.name }}</h1>
    <ui-field-display label="Descripción" [value]="condition.summary"></ui-field-display>
    <div class="ui-field-group one-half">
      <ui-field-display
        label="Registrado por"
        *ngIf="condition.registered_by"
        [value]="condition.registered_by?.person.first_name + ' ' + condition.registered_by?.person.last_name"
        (click)="goTo('/tasks/users/' + condition.registered_by?.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display label="Porcentaje" [value]="condition.rate | percent"></ui-field-display>
    </div>
  </div>
</div>

<div class="ui-speed-dial" *ngIf="condition">
  <div class="ui-speed-dial__main">
    <ui-btn
      fab
      color="accent"
      (uiClick)="goTo('/orders/conditions/' + (condition?.id || 0) + '/edit')"
      hint="Editar condición"
      icon="edit"
    >
    </ui-btn>
  </div>
</div>

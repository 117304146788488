import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { DummiesAppComponent } from './dummies-app.component';

import { DummiesIndexComponent } from './dummies/dummies-index.component';
import { DummiesShowComponent } from './dummies/dummies-show.component';
import { DummiesCreateComponent } from './dummies/dummies-create.component';
import { DummiesEditComponent } from './dummies/dummies-edit.component';

const routes: Routes = [
  { path: '', component: DummiesAppComponent, children: [
    { path: '', component: DummiesIndexComponent },
    { path: 'new', component: DummiesCreateComponent },
    { path: ':id', component: DummiesShowComponent },
    { path: ':id/edit', component: DummiesEditComponent }
  ] }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
  providers: [ ]
})
export class DummiesAppRoutesModule {}

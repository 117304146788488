import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class OrderItemsService extends ApiService {
  private baseOrderItem = {
    product_name: '',
    quantity: '',
    product_cost: '',
    price: '',
    profit_rate: '',
    total: '',
    product_id: null
  };
  constructor(
    private http: HttpX,
    private auth: AuthService
  ) {
    super('order_item', 'order_items', http, auth);
  }
  create(opts = {}) {
    return {
      ...this.baseOrderItem,
      ...opts
    };
  }
}

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AttachmentsService } from '../../core/services/attachments.service';
import { UiService } from '../../core/services/ui.service';
import { isIn, textClamp } from '../../shared/helpers';
import { UiFileLoaderComponent } from '../../ui/ui-file-loader/ui-file-loader.component';
import * as moment from 'moment';

@Component({
  selector: 'attachment-collection',
  templateUrl: 'attachment-collection.template.html',
  styleUrls: ['attachment-collection.styles.scss'],
})
export class AttachmentCollectionComponent {
  @ViewChild('fileLoaderEl') fileLoaderEl: UiFileLoaderComponent;
  @Input() attachments: Array<any>;
  @Input() attachableType: string = '';
  @Input() attachableId: number = null;
  @Output() afterUpload = new EventEmitter();
  @Output() afterDelete = new EventEmitter();

  constructor(private attachmentsSrv: AttachmentsService, private ui: UiService) {}
  openFileLoader() {
    this.fileLoaderEl.open();
  }
  relativeDate(dateString) {
    return moment(dateString).fromNow();
  }
  textClamp(string) {
    return textClamp(string, 65);
  }
  attachFilesAllowed() {
    return this.attachments.length < 20;
  }
  uploadFiles(files) {
    let attachment = {
      attachable_id: this.attachableId,
      attachable_type: this.attachableType,
    };
    return Promise.all(
      files.map((file) => {
        let newAttachment = {
          extension: '---',
          name: file.name,
          uploading: true,
        };
        //this.attachments.unshift(newAttachment);
        this.attachments = [...this.attachments, newAttachment];

        return this.attachmentsSrv.save(attachment, { file }).then((savedAttachment) => {
          Object.assign(newAttachment, savedAttachment);
          newAttachment.uploading = false;
          return newAttachment;
        });
      })
    );
  }
  fileLoadHandler(loadedFiles) {
    if (loadedFiles.errors) {
      if (loadedFiles.errors.maxSizeExceeded)
        this.ui.snackbar.show(
          'Tu carga supera el tamaño máximo permitido. Puedes realizar cargas de hasta 20 megas a la vez.'
        );
    } else {
      if (loadedFiles.size > this.attachmentsSrv.maxLoadSize) {
        this.ui.snackbar.show(
          'Tu carga supera el tamaño máximo permitido. Puedes realizar cargas de hasta 20 megas a la vez.'
        );
      } else {
        if (loadedFiles.files.length > this.attachmentsSrv.maxFileCount) {
          this.ui.snackbar.show(
            'El número de archivos supera el máximo permitido. Puedes subir hasta 5 archivos a la vez.'
          );
        } else {
          if (!isIn(this.attachmentsSrv.allowedExtensions, loadedFiles.extensions)) {
            this.ui.snackbar.show('Estás intentando cargar un archivo con una extensión no permitida.');
          } else {
            this.uploadFiles(loadedFiles.files).then((data) => {
              this.afterUpload.emit(data);
            });
          }
        }
      }
    }
  }
  confirmAttachmentDeletion(att) {
    this.ui.alert.show({
      title: '¿Estás seguro?',
      message: `Borrarás el archivo "${att.name}". Esta operación no se puede deshacer.`,
      onOk: () => {
        att.deleting = true;
        this.attachmentsSrv.delete(att).then(() => {
          att.deleting = false;
          this.afterDelete.emit(att);
          this.ui.snackbar.show('Archivo adjunto eliminado correctamente.');
        });
      },
    });
  }
}

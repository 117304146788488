import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { TasksAppComponent } from './tasks-app.component';

import { DashboardComponent } from './dashboard/dashboard.component';

import { CustomersComponent } from './customers/customers.component';
import { CustomersIndexComponent } from './customers/customers-index.component';
import { CustomersShowComponent } from './customers/customers-show.component';
import { CustomersCreateComponent } from './customers/customers-create.component';
import { CustomersEditComponent } from './customers/customers-edit.component';

import { ProjectsComponent } from './projects/projects.component';
import { ProjectsIndexComponent } from './projects/projects-index.component';
import { ProjectsShowComponent } from './projects/projects-show.component';
import { ProjectsCreateComponent } from './projects/projects-create.component';
import { ProjectsEditComponent } from './projects/projects-edit.component';

import { TasksComponent } from './tasks/tasks.component';
import { TasksIndexComponent } from './tasks/tasks-index.component';
import { TasksShowComponent } from './tasks/tasks-show.component';
import { TasksCreateComponent } from './tasks/tasks-create.component';
import { TasksEditComponent } from './tasks/tasks-edit.component';
import { TasksMyTasksComponent } from './tasks/tasks-mytasks.component';

import { UserGroupsComponent } from './usergroups/usergroups.component';
import { UserGroupsIndexComponent } from './usergroups/usergroups-index.component';
import { UserGroupsShowComponent } from './usergroups/usergroups-show.component';
import { UserGroupsCreateComponent } from './usergroups/usergroups-create.component';
import { UserGroupsEditComponent } from './usergroups/usergroups-edit.component';

import { UsersComponent } from './users/users.component';
import { UsersIndexComponent } from './users/users-index.component';
import { UsersShowComponent } from './users/users-show.component';
import { UsersCreateComponent } from './users/users-create.component';
import { UsersEditComponent } from './users/users-edit.component';

import { SuppliersComponent } from './suppliers/suppliers.component';
import { SuppliersIndexComponent } from './suppliers/suppliers-index.component';
import { SuppliersShowComponent } from './suppliers/suppliers-show.component';
import { SuppliersCreateComponent } from './suppliers/suppliers-create.component';
import { SuppliersEditComponent } from './suppliers/suppliers-edit.component';

import { AuthAdminGuardService } from '../core/services/auth-admin-guard.service';
import { AuthSudoGuardService } from '../core/services/auth-sudo-guard.service';

const routes: Routes = [
  { path: '', component: TasksAppComponent, children: [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'mytasks', component: TasksMyTasksComponent },
    { path: 'customers', component: CustomersComponent, children: [
      { path: '', component: CustomersIndexComponent },
      { path: 'new', component: CustomersCreateComponent },
      { path: ':id', component: CustomersShowComponent },
      { path: ':id/edit', component: CustomersEditComponent }
    ] },
    { path: 'projects', component: ProjectsComponent, children: [
      { path: '', component: ProjectsIndexComponent },
      { path: 'new', component: ProjectsCreateComponent },
      { path: ':id', component: ProjectsShowComponent },
      { path: ':id/edit', component: ProjectsEditComponent }
    ] },
    { path: 'tasks', component: TasksComponent, children: [
      { path: '', component: TasksIndexComponent },
      { path: 'new', component: TasksCreateComponent },
      { path: ':id', component: TasksShowComponent },
      { path: ':id/edit', component: TasksEditComponent }
    ] },
    { path: 'usergroups', component: UserGroupsComponent, children: [
      { path: '', component: UserGroupsIndexComponent },
      { path: 'new', component: UserGroupsCreateComponent },
      { path: ':id', component: UserGroupsShowComponent },
      { path: ':id/edit', component: UserGroupsEditComponent }
    ] },
    { path: 'users', component: UsersComponent, children: [
      { path: '', component: UsersIndexComponent },
      { path: 'new', component: UsersCreateComponent, canActivate: [AuthAdminGuardService] },
      { path: ':id', component: UsersShowComponent },
      { path: ':id/edit', component: UsersEditComponent, canActivate: [AuthSudoGuardService] }
    ] },
    { path: 'suppliers', component: SuppliersComponent, children: [
      { path: '', component: SuppliersIndexComponent },
      { path: 'new', component: SuppliersCreateComponent },
      { path: ':id', component: SuppliersShowComponent },
      { path: ':id/edit', component: SuppliersEditComponent }
    ] },
  ] }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
  providers: [ ]
})
export class TasksAppRoutesModule {}

import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class UsersService extends ApiService {
  static genderOptions = [{value: 'M', label: 'Masculino'}, {value: 'F', label: 'Femenino'}];
  static statusOptions = [{value: 'active', label: 'Activo'}, {value: 'inactive', label: 'Inactivo'}];
  constructor(
    private http: HttpX,
    private auth: AuthService
  ) {
    super('user', 'users', http, auth);
  }
  create() {
    return {
      email: '',
      status: '',
      person: {
        first_name: '',
        last_name: ''
      }
    };
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductsService } from '../../core/services/products.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '../../core/services/users.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';
import { roundNumber } from '../../shared/helpers';

@Component({
  selector: 'products-show',
  templateUrl: 'products-show.template.html',
})
export class ProductsShowComponent implements OnInit {
  @ViewChild('attCollectionEl') attCollectionEl;
  public product = null;
  private usersOpts = [];
  private currentUserRole = null;
  private orderRoundedValues: boolean = false;
  private priceLockedOptions = ProductsService.priceLockedOptions;

  constructor(
    private productsSrv: ProductsService,
    private usersSrv: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private ui: UiService
  ) {}

  ngOnInit() {
    let currentUser = this.auth.getCurrentUser();
    this.currentUserRole = this.auth.getCurrentRole();
    this.orderRoundedValues = currentUser.person.organization.order_rounded_values;
    this.ui.toolbar.title = 'Detalle del Producto';
    var productId = this.route.snapshot.params['id'];
    this.productsSrv.find(productId).then((product) => {
      this.product = product;
    });
    this.usersSrv.fetch().then((userCollection) => {
      this.usersOpts = userCollection.asOptions('id', 'email', { orderBy: 'email' });
    });
  }

  showFixedNumber(number: number) {
    return roundNumber(this.orderRoundedValues, number);
  }

  goTo(url) {
    this.router.navigate([url]);
  }

  get lockedPrice() {
    return this.product.price_locked ? 'Sí' : 'No';
  }

  get isGeneric() {
    return this.product.generic ? 'Sí' : 'No';
  }

  get isActive() {
    return this.product.status === 'active' ? 'Sí' : 'No';
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';

@Component({
  selector: 'ui-spinner',
  templateUrl: 'ui-spinner.template.html',
  styleUrls: ['ui-spinner.styles.scss'],
})
export class UiSpinnerComponent implements OnInit {
  @Input() public width: string = '100px';
  @Input() public strokeWidth: string = '2';
  @Input() @BooleanFieldValue() public mono: boolean = false;

  constructor() {}
  public ngOnInit() {}
}

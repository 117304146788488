import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthAdminGuardService implements CanActivate {
  private currentUserRole = null;
  constructor(private auth: AuthService,
              private router: Router ) {}
  canActivate() {
    return this.auth.getCurrentRole() === 'admin';
  }
}

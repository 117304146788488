import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';

function elemOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft,
  };
}

@Component({
  selector: 'ui-btn',
  templateUrl: 'ui-btn.template.html',
  styleUrls: ['ui-btn.styles.scss'],
})
export class UiBtnComponent {
  @ViewChild('button') public buttonEl: ElementRef;
  @ViewChild('ripple') public rippleEl: ElementRef;
  @Input() public color: string;
  @Input() public icon: string;
  @Input() public image: any;
  @Input() public hint: string;
  @Input() public type: string;
  @Input() @BooleanFieldValue() public flat: boolean = false;
  @Input() @BooleanFieldValue() public fab: boolean = false;
  @Input() @BooleanFieldValue() public side: boolean = false;
  @Input() @BooleanFieldValue() public disabled: boolean = false;
  @Input() @BooleanFieldValue() public textRaw: boolean = false;
  @Input() @BooleanFieldValue() public notab: boolean = false;
  @Output() public uiClick = new EventEmitter();

  constructor() {}

  public clickHandler($event: MouseEvent) {
    $event.stopPropagation();
    this.uiClick.emit($event);
  }

  public mouseDownHandler($event: MouseEvent) {
    const nativeBtn = this.buttonEl.nativeElement;
    const ripple = this.rippleEl.nativeElement.cloneNode(true);

    const xPos = $event.pageX - elemOffset(nativeBtn).left;
    const yPos = $event.pageY - elemOffset(nativeBtn).top;

    ripple.style.top = yPos - 7 + 'px';
    ripple.style.left = xPos - 7 + 'px';

    nativeBtn.insertBefore(ripple, nativeBtn.firstChild);

    window.setTimeout(() => {
      nativeBtn.removeChild(ripple);
    }, 890);
  }
}

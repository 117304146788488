import { Injectable } from "@angular/core";

import { UiAlertComponent } from "../../ui/ui-alert/ui-alert.component";
import { UiAppComponent } from "../../ui/ui-app/ui-app.component";
import { UiSidebarComponent } from "../../ui/ui-sidebar/ui-sidebar.component";
import { UiSnackbarComponent } from "../../ui/ui-snackbar/ui-snackbar.component";
import { UiToolbarComponent } from "../../ui/ui-toolbar/ui-toolbar.component";

@Injectable()
export class UiService {
  public app: UiAppComponent = null;
  public toolbar: UiToolbarComponent = null;
  public sidebar: UiSidebarComponent = null;
  public alert: UiAlertComponent = null;
  public snackbar: UiSnackbarComponent = null;
}

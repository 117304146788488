<div class="attm-collection">
  <ui-drop-area (uiDrop)="fileLoadHandler($event)" [dragAllowed]="attachFilesAllowed()">
    <p *ngIf="!attachFilesAllowed()">Haz alcanzado el máximo número de adjuntos para este elemento.</p>
    <p class="no-margin" *ngIf="!attachments.length">
      Actualmente no hay archivos adjuntos a este elemento. Puedes agregar hasta 20 archivos adjuntos arrastrándolos
      aquí.
    </p>

    <div [class.hidden]="!attachments.length" class="attm-collection__items">
      <div
        class="attm-collection__item-wrapper"
        *ngFor="let att of attachments | orderBy: 'created_at'; let inx = index"
      >
        <div class="attm-collection__item" [class.is-uploading]="att.uploading || att.deleting">
          <a class="attm-collection__link" [href]="att.url" target="_blank">
            <div class="attm-collection__icon-container">
              <ui-attachment-icon [attachment]="att"></ui-attachment-icon>
            </div>
            <div class="attm-collection__description">
              <p class="attm-collection__name">{{ textClamp(att.name) }}</p>
              <div class="attm-collection__sub-info" *ngIf="!att.uploading && !att.deleting">
                <p>
                  {{ att.registered_by.person.first_name }} {{ att.registered_by.person.last_name }} - {{
                  relativeDate(att.created_at) }}
                </p>
              </div>
              <div class="attm-collection__sub-info" *ngIf="att.uploading || att.deleting">
                <p>{{ att.uploading ? "Cargando..." : "Eliminando..." }}</p>
              </div>
            </div>
          </a>
          <div class="attm-collection__item-uploading">
            <ui-spinner width="60px" strokeWidth="3"></ui-spinner>
          </div>
          <div class="attm-collection__side-btn-container">
            <ui-btn side color="side-error" icon="delete" (uiClick)="confirmAttachmentDeletion(att)"></ui-btn>
          </div>
        </div>
      </div>
      <div class="attm-collection__item-wrapper" *ngIf="attachFilesAllowed()">
        <ui-file-loader #fileLoaderEl multiple (load)="fileLoadHandler($event)" class="attm-collection__new-loader">
          <div class="attm-collection__new-item">
            <p class="attm-collection__new-icon">
              <span class="icon icon-add"></span>
            </p>
            <p class="no-margin">Adjunta un archivo</p>
          </div>
        </ui-file-loader>
      </div>
    </div>
  </ui-drop-area>
  <!-- <ui-modal [(isOpen)]="modalOpen">
    <h2>You dropped something!</h2>
  </ui-modal> -->
</div>

import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class DummiesService extends ApiService {
  constructor(
    private http: HttpX,
    private auth: AuthService
  ) {
    super('dummy', 'dummies', http, auth);
  }
  create() {
    return {
      first_name: '',
      last_name: '',
      bio: ''
    };
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ui-toolbar",
  templateUrl: "ui-toolbar.template.html",
  styleUrls: ["ui-toolbar.styles.scss"],
})
export class UiToolbarComponent {
  @Output() public action = new EventEmitter();
  public title: string = "";

  public handleAction(evt) {
    this.action.emit(evt);
  }
  // setTitle(newTitle: string) {
  //   this.title = newTitle;
  // }
}

<div class="ui-form-element">
  <div class="ui-check" [class.is-focused]="focused">
    <div class="ui-check__hlight"></div>
    <input
      #input
      [id]="id"
      type="checkbox"
      [ngModel]="value"
      (ngModelChange)="ngModelChangeHandler($event)"
      [disabled]="disabled"
      (focus)="focusHandler()"
      (blur)="blurHandler()"
      class="ui-check__input"
    />
    <div class="ui-check__wrap">
      <div class="ui-check__mark"></div>
    </div>
  </div>
  <label *ngIf="label" [attr.for]="id">{{ label }}</label>
  <ng-content class="ui-check__label" select="[label-content]"></ng-content>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'customers',
  templateUrl: 'customers.template.html',
})
export class CustomersComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'projects',
  templateUrl: 'projects.template.html',
})
export class ProjectsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { filterArrayBy } from '../helpers';

// para que funcione con el comparador diferente en vez de igual, mandas un string
// como parámetro al final, así "elArrayAFiltrar | filterBy : filters : '!='"

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
  transform(
    value: Array<Object>,
    filterCriteria: any,
    compareOp: string = '=='
  ): Array<Object> {
    // console.warn('executing filter pipe');
    return filterArrayBy(value, filterCriteria, compareOp);
  }
}

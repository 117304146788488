<div #containerEl class="ui-datepicker ui-form-element">
  <ui-input #uiInputEl
    type="text"
    [label]="label"
    [elemId]="elemId"
    [name]="name"
    class="ui-datepicker__ui-input"
    [(ngModel)]="displayDate"
    [required]="required"
    [readonly]="readonly"
    [disabled]="disabled"
    [placeholder]="placeholder || displayFormat"
    icon="today"
    iconClickable
    manualHighlight
    (iconClick)="iconClickHandler($event)"
    (keydown)="keydownHandler($event)"
    (change)="changeHandler($event)"
    (focus)="focusHandler($event)"
    (blur)="blurHandler($event)"
    [isHighlighted]="inputFocused || calendarOpen"
    width="reset"
    [formControlRef]="control">
    <ui-dropdown class="ui-dropdown" [(isOpen)]="calendarOpen" [style.width]="'330px'" [containerEl]="containerEl">
      <ui-calendar [ngModel]="value"
        (ngModelChange)="calendarChangeHandler($event)">
      </ui-calendar>
    </ui-dropdown>
  </ui-input>
  <div *ngIf="quickActions" class="ui-datepicker__quick-options">
    <div *ngFor="let q of quickOptions" (click)="handleSelectQuickOption(q.value)" tabindex="-1">
      <span [ngClass]="['icon icon-' + q.icon]"></span>
      <span>{{ q.label }}</span>
    </div>
  </div>
</div>

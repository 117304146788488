import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "ui-user-actions",
  templateUrl: "ui-user-actions.template.html",
  styleUrls: ["ui-user-actions.styles.scss"],
})
export class UiUserActionsComponent {
  @ViewChild("menuEl") public menuEl: ElementRef;
  @Input() public userData: any = {};
  @Output() public logoutClick = new EventEmitter();
  private userMenuOpen = false;
  private currentMousedownHandler = null;
  private userModalOpen: boolean = false;

  constructor(private router: Router) {}
  public openUserMenu() {
    this.userMenuOpen = true;
    const _this = this;
    this.currentMousedownHandler = function __handler__(evt) {
      if (!_this.menuEl.nativeElement.contains(evt.target)) {
        _this.userMenuOpen = false;
        document.removeEventListener("click", __handler__, true);
        evt.preventDefault();
        evt.stopPropagation();
      }
    };
    document.addEventListener("click", this.currentMousedownHandler, true);
  }
  public goTo(url) {
    this.userMenuOpen = false;
    document.removeEventListener("click", this.currentMousedownHandler, true);
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
  public logoutClickHandler(evt) {
    this.userMenuOpen = false;
    this.logoutClick.emit(evt);
    document.removeEventListener("click", this.currentMousedownHandler, true);
    evt.preventDefault();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TasksOrderBy } from '../enums/tasks-order-by.enum';

@Pipe({
  name: 'tasksOrderBy',
})
export class TasksOrderByPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case TasksOrderBy.CLIENT:
        return 'Cliente';
      case TasksOrderBy.PROJECT:
        return 'Proyecto';
      case TasksOrderBy.NAME:
        return 'Tarea';
      case TasksOrderBy.DUE_DATE:
        return 'Vencimiento';
      default:
        break;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService, ICollectionResponse } from './api.service';
import { AuthService } from './auth.service';

export const idTypes = [
  {
    value: 'CC',
    label: 'Cédula de ciudadanía',
  },
  {
    value: 'TE',
    label: 'Tarjeta de extranjería',
  },
  {
    value: 'Nit',
    label: 'NIT',
  },
  {
    value: 'PSP',
    label: 'Pasaporte',
  },
  {
    value: 'OTRO',
    label: 'Otro',
  },
];
export interface ISupplier {
  address: string;
  city: string;
  country?: string;
  deactivated_at?: string;
  deactivated_by_id?: string;
  email: string;
  id?: number;
  identification?: string;
  identification_type: typeof idTypes[number]['value'];
  legal_name: string;
  name: string;
  phone: string;
  registered_by_id?: number;
  state?: null;
  summary: string;
}

@Injectable()
export class SuppliersService extends ApiService {
  private baseSupplier = {
    name: '',
    identification_type: '',
    identification: '',
    email: '',
    phone: '',
  };

  constructor(private http: HttpX, private auth: AuthService) {
    super('supplier', 'suppliers', http, auth);
  }

  public idTypes = idTypes;

  create(opts = {}) {
    return {
      ...this.baseSupplier,
      ...opts,
    };
  }

  fetch(extras?: string): Promise<ICollectionResponse<ISupplier>> {
    return super.fetch(extras);
  }
}

import { Component, OnInit } from '@angular/core';
import { UserGroupsService } from '../../core/services/usergroups.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TasksService } from '../../core/services/tasks.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'userGroups-show',
  templateUrl: 'usergroups-show.template.html',
})
export class UserGroupsShowComponent implements OnInit {
  public userGroup = null;
  private filters = {};
  public filtered_by;
  private isAuthorized = false;
  constructor(
    private userGroupsSrv: UserGroupsService,
    private tasksSrv: TasksService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService,
    private auth: AuthService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Detalle de Grupo';
    this.isAuthorized = this.auth.isAuthorized();
    var userGroupId = this.route.snapshot.params['id'];
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
    this.userGroupsSrv
      .find(userGroupId)
      .then((userGroup) => (this.userGroup = userGroup));
  }
  goTo(url) {
    this.router.navigate([url]);
  }
  filterByStatus() {
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
  }
  disableFilterBy() {
    this.filters = {};
    this.filtered_by = 'all';
  }
  editUserGroupRedirect() {
    if (this.isAuthorized) {
      this.goTo('/tasks/usergroups/' + this.userGroup.id + '/edit');
    } else {
      this.ui.snackbar.show('No tienes permisos para editar grupos de personas.');
    }
  }
}

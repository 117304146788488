import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'signin',
  templateUrl: 'signin.template.html',
  styleUrls: ['signin.styles.scss'],
})
export class SigninComponent implements OnInit {
  public user = {
    email: '',
    password: '',
  };

  constructor(private auth: AuthService, private router: Router, private ui: UiService) {}
  ngOnInit() {
    if (this.auth.signedIn()) this.router.navigate(['/tasks/dashboard']);
  }
  signInWithPassword(evt) {
    evt.preventDefault();
    this.auth.signInWithPassword(this.user.email, this.user.password).then((signInOk) => {
      if (signInOk) {
        let person = this.auth.getCurrentUser().person;
        this.router.navigate(['/tasks/dashboard']);
        this.ui.snackbar.show(`Bienvenido, ${person.first_name}!`);
      } else {
        this.ui.snackbar.show(
          `No fue posible iniciar sesión. Por favor contacte al administrador del sistema.`
        );
      }
    });
  }
  signinHandler(evt) {
    this.auth.googleAuthHandler().then((signInOk) => {
      if (signInOk) {
        let person = this.auth.getCurrentUser().person;
        this.router.navigate(['/tasks/dashboard']);
        this.ui.snackbar.show(`Bienvenido, ${person.first_name}!`);
      } else {
        this.ui.snackbar.show(
          `No fue posible iniciar sesión. Por favor contacte al administrador del sistema.`
        );
      }
    });
  }
  signoutHandler() {
    this.auth.signOut().then(() => {
      this.ui.snackbar.show(`La sesión se finalizó correctamente.`);
    });
  }
  signUpButtonHandler() {
    this.router.navigate(['/signup']);
  }
}

<div class="ui-panel no-padding">
  <div *ngIf="loading" class="padding" fxLayout="row" fxLayoutAlign="center cener" colspan="4">
    <mat-spinner></mat-spinner>
  </div>
  <table class="ui-table" *ngIf="!loading">
    <thead>
      <tr>
        <th></th>
        <th>Nombre</th>
        <th>Porcentaje</th>
        <th>Descripción</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of conditions | filterBy : filters ">
        <td><span class="icon icon-conditions"></span></td>
        <td (click)="goTo('/orders/conditions/' + c.id )">{{ c.name }}</td>
        <td>{{ ((c.rate || 0) / 100) | percent }}</td>
        <td>{{ c.summary }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/orders/conditions/new')" hint="Crear condición" icon="add"> </ui-btn>
  </div>
</div>

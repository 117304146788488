export const sidebarActions = [
  {
    label: 'Resumen',
    icon: 'dashboard',
    url: '/tasks/dashboard'
    // hide: () => { return false; }, // puede ser una función o un valor booleano.
    // action: () => { console.warn('algo') }
    // isActive: () => { return true } // por defecto una accion estará activa cuando la url del router sea igual a su url, pero eso se puede cambiar aquí
  },
  {
    label: 'Clientes',
    icon: 'briefcase',
    url: '/tasks/customers'
  },
  {
    label: 'Proyectos',
    icon: 'rocket',
    url: '/tasks/projects'
  },
  {
    label: 'Tareas',
    icon: 'task',
    url: '/tasks/tasks'
  },
  {
    label: 'Grupos',
    icon: 'people',
    url: '/tasks/usergroups'
  },
  {
    label: 'Personas',
    icon: 'person-circle',
    url: '/tasks/users'
  },
  {
    label: 'Mis tareas',
    icon: 'person-task',
    url: '/tasks/mytasks'
  },
  {
    label: 'Proveedores',
    icon: 'suppliers',
    url: '/tasks/suppliers'
  },
  // {
  //   label: 'Salir',
  //   icon: 'exit',
  //   action: () => {
  //     this.auth.signOut().then(() => {
  //       this.router.navigate(['/']);
  //       this.snackbar.show({
  //         message: 'La sesión se finalizó correctamente.'
  //       });
  //     });
  //   }
  // }
];

<div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
  <div fxFlex="grow" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container *ngIf="activeFilters > 0; else noFilters">
              <ui-badge [value]="activeFilters" small> Filtros </ui-badge>
            </ng-container>
            <ng-template #noFilters> Filtros </ng-template>
          </mat-panel-title>
          <mat-panel-description>
            Esto te permitirá filtrar las {{modeTexts.listTitle | lowercase}} según criterios
          </mat-panel-description>
        </mat-expansion-panel-header>
        <form
          [formGroup]="filtersForm"
          (ngSubmit)="filter()"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <div fxLayout="column" fxFlex="grow">
            <div fxLayout="row" fxFlex="grow" fxLayoutGap="10px">
              <ui-input label="{{modeTexts.nameFiltertext}}" fxFlex="grow" formControlName="orderName"> </ui-input>
              <ui-input label="Número de la orden" fxFlex="20%" formControlName="orderSequence"> </ui-input>
              <ui-input label="{{modeTexts.userLabel}}" fxFlex="grow" formControlName="customerName"> </ui-input>
              <ui-select label="Estado" fxFlex="100px" formControlName="orderStatus" [options]="ORDER_STATUSES">
              </ui-select>
            </div>
            <div fxLayout="row" fxFlex="grow" fxLayoutGap="10px">
              <ui-datepicker label="Fecha inicial" fxFlex="grow" formControlName="orderCreatedAtStart"> </ui-datepicker>
              <ui-datepicker label="Fecha final" fxFlex="grow" formControlName="orderCreatedAtEnd"> </ui-datepicker>
            </div>
          </div>
          <ui-btn class="padding" type="submit" color="primary" hint="Filtrar" icon="filter" fab></ui-btn>
        </form>
        <div class="margin-bottom--half">
          <mat-chip-list aria-label="Filtros">
            <mat-chip *ngIf="filters.orderName as name" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Nombre</b>: {{ name }}
                <mat-icon matChipRemove (click)="clearFilter('orderName')"> cancel </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.orderSequence as sequence" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>#Orden</b>: {{ sequence }}
                <mat-icon matChipRemove (click)="clearFilter('orderSequence')"> cancel </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.customerName as customer" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>{{modeTexts.userLabel}}</b>: {{ customer }}
                <mat-icon matChipRemove (click)="clearFilter('customerName')"> cancel </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.orderStatus as status" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Estado</b>: {{ status | orderStatus }}
                <mat-icon matChipRemove (click)="clearFilter('orderStatus')"> cancel </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.orderCreatedAtStart as createdAt" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Fecha inicial</b>: {{ createdAt }}
                <mat-icon matChipRemove (click)="clearFilter('orderCreatedAtStart')"> cancel </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.orderCreatedAtEnd as createdAt" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Fecha final</b>: {{ createdAt }}
                <mat-icon matChipRemove (click)="clearFilter('orderCreatedAtEnd')"> cancel </mat-icon>
              </div>
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="ui-panel no-padding">
      <ng-container *ngIf="!isLoading; else loading">
        <table class="ui-table">
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>{{modeTexts.userLabel}}</th>
              <th>Orden No</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let o of orders"
              class="bordered"
              [class.bordered--success]="(o.status == 'finished')"
              [class.bordered--canceled]="(o.status == 'canceled')"
              [class.bordered--warning]="(o.status != 'finished' && o.status != 'canceled')"
            >
              <td>
                <span *ngIf="mode === 'sell'" class="icon icon-orders"></span>
                <span *ngIf="mode === 'buy'" class="icon icon-shopping-cart"></span>
              </td>
              <td (click)="goTo(modeTexts.baseLink + o.id)">{{ o.name }}</td>
              <td *ngIf="mode === 'sell'" (click)="goTo('/tasks/customers/' + o.customer?.id )">
                {{ o.customer?.name }}
              </td>
              <td *ngIf="mode === 'buy'" (click)="goTo('/tasks/suppliers/' + o.supplier?.id )">
                {{ o.supplier?.name }}
              </td>
              <td (click)="goTo(modeTexts.baseLink + o.id)">{{ o.sequence }}</td>
              <td (click)="goTo(modeTexts.baseLink + o.id)">{{ ordersSrv.orderStatusDesc[o.status] }}</td>
            </tr>
          </tbody>
        </table>
        <mat-paginator
          [pageIndex]="paginator.pageIndex"
          [length]="paginator.length"
          [pageSize]="paginator.pageSize"
          [pageSizeOptions]="paginator.pageSizeOptions"
          (page)="changePage($event)"
        >
        </mat-paginator>
      </ng-container>
      <ng-template #loading>
        <div class="padding" fxLayout="row" fxLayoutAlign="center cener">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__secondary">
    <ui-btn *ngIf="mode === 'sell'" fab color="info" (uiClick)="downloadCSV()" hint="Descargar reporte órdenes.csv">
      <span class="icon icon-file"></span>
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <ui-btn
      fab
      color="accent"
      (uiClick)="goTo(modeTexts.newOrderLink)"
      hint="{{modeTexts.createoOrderHint}}"
      icon="add"
    >
    </ui-btn>
  </div>
</div>

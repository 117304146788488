<div class="snackbar" [ngClass]="{'is-open': isOpen, 'snackbar--spinner': params.spinner}">
  <div class="snackbar__container">
    <div class="snackbar__content">
      <div class="snackbar__spinner">
        <ui-spinner width="23px" strokeWidth="4" mono></ui-spinner>
      </div>
      <div class="snackbar__text">
        <p>
          {{ params.message }}
        </p>
      </div>
      <div class="snackbar__controls">
        <span *ngIf="params.btnLabel">
          <ui-btn color="snack" (uiClick)="executeAction()" flat>
            {{ params.btnLabel }}
          </ui-btn>
        </span>
        <span *ngIf="!params.hideClose">
          <button class="snackbar__icon-btn" (click)="hide()">
            <span class="icon icon-close"></span>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

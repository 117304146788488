import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UiService } from './ui.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private auth: AuthService, private ui: UiService, private router: Router) {}
  canActivate() {
    //return true;
    return this.auth.verifyIfLogged().pipe(
      first((isLogged) => {
        if (!isLogged) this.router.navigate(['/']);
        let currentUser = this.auth.getCurrentUser();
        if (currentUser && currentUser.status !== 'active') {
          this.router.navigate(['/']);
          this.ui.snackbar.show(
            'Tu cuenta no está activa, por favor consultar con el administrador de tu organización'
          );
          return false;
        }
        return isLogged;
      })
    );
  }
}

import {
  Component,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  OnInit,
  forwardRef,
  Injector,
  AfterViewInit,
  Renderer2,
} from '@angular/core';

import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  Validator,
  FormControl,
  NgControl,
} from '@angular/forms';

import { BooleanFieldValue } from '../../shared/annotations';

import { CustomersService } from '../../core/services/customers.service';
import { SuppliersService } from 'src/app/core/services/suppliers.service';

let uniqueId = 0;

interface InitModel {
  id: number;
  name: string;
}

@Component({
  selector: 'ui-autocomplete-async',
  templateUrl: 'ui-autocomplete-async.template.html',
  styleUrls: ['ui-autocomplete-async.styles.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UiAutocompleteAsyncComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UiAutocompleteAsyncComponent),
      multi: true,
    },
  ],
})
export class UiAutocompleteAsyncComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit {
  @ViewChild('labelEl') labelEl: ElementRef;
  @ViewChild('inputEl') inputEl: any;
  @ViewChild('inputModel') inputModel: any;
  @ViewChild('menuEl') menuEl: ElementRef;
  @ViewChild('wrapperEl') wrapperEl: ElementRef;
  @Input() uiModel: string;
  @Input('uiId') id: string = `ui-autocomplete-async-${uniqueId++}`;
  @Input('uiName') name: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() @BooleanFieldValue() required: boolean = false;
  @Input() @BooleanFieldValue() readonly: boolean = false;
  @Input() @BooleanFieldValue() disabled: boolean = false;
  @Input() model: string;
  @Input() initialValue: number;
  @Input() set formControlRef(formControlRef: FormControl) {
    this._control = formControlRef;
  }
  @Output() uiModelChange = new EventEmitter();

  public isFocused: boolean;
  private isLoading: boolean;
  private menuOpen: boolean;
  private typing: any;
  private currentMousedownHandler = null;
  private selectedItemInx = -1;
  private onChange: any;
  private _control: FormControl;
  private propagateTouch: any = () => {};
  public options = [];
  private _initialValue: number = null;

  constructor(
    private renderer: Renderer2,
    private injector: Injector,
    private customersSrv: CustomersService,
    private providersSrv: SuppliersService
  ) {}

  // Ths is to get an instance of form control
  ngAfterViewInit() {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl && !this._control) this._control = ngControl.control as FormControl;
  }

  writeValue(value: string) {
    this.uiModel = value;
    if (!value) {
      this.selectedItemInx = -1;
    }
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(c: FormControl) {
    return this._initialValue || this.selectedItemInx > -1 || !this.required ? null : { noUserSelected: true };
  }

  ngOnInit() {
    if (this.selectedItemInx === -1) {
      this.selectedItemInx = 0;
    }
    this._initialValue = this.initialValue;
  }
  fetchMore(query) {
    switch (this.model) {
      case 'customer':
        return this.customersSrv.fetch(query);
      case 'provider':
        return this.providersSrv.fetch(query);
      default:
        break;
    }
  }

  filterActions() {
    let query = `?page=1&per_page=5`;
    if (this.uiModel) {
      query += `&q[name_cont]=${this.uiModel}`;
    }
    this.isLoading = true;
    this.fetchMore(query).then(({ collection, items }) => {
      this.options = (collection || items)
        .asOptions('id', 'name', {
          orderBy: 'name',
        })
        .map(({ label, value }) => ({
          label,
          action: () => {
            this.uiModelChange.emit(value);
            this.uiModel = label;
            if (typeof this.onChange === 'function') {
              this.onChange(this.uiModel);
            }
          },
        }));
      this.isLoading = false;
    });
  }

  focusHandler($event) {
    this.isFocused = true;
    this.openMenu();
  }

  blurHandler($event) {
    this.isFocused = false;
    this.propagateTouch();
    if (!this.options.length) {
      this.uiModel = '';
      this.filterActions();
    }
  }

  inputTextChangeHandler($event) {
    this._initialValue = null;
    this.isLoading = true;
    if (this.typing) {
      clearTimeout(this.typing);
    }
    this.typing = setTimeout(() => {
      this.selectedItemInx = -1;
      this.uiModelChange.emit(null);
      this.uiModel = $event;
      if (typeof this.onChange === 'function') {
        this.onChange(this.uiModel);
      }
      this.filterActions();
      this.typing = null;
    }, 500);
  }

  keyDownHandler($event) {
    if (!this.menuOpen) this.openMenu();
    if ($event.which === 40 || $event.which === 38) {
      $event.preventDefault();
      // up and down arrows
      if ($event.which === 40) {
        this.selectedItemInx++;
        if (this.selectedItemInx >= this.options.length) {
          this.selectedItemInx = -1;
        }
      } else {
        this.selectedItemInx--;
        if (this.selectedItemInx < -1) this.selectedItemInx = this.options.length - 1;
      }
    } else if ($event.which === 27 || $event.which === 9 || $event.which === 13) {
      // escape, tab or enter key
      $event.preventDefault();

      if ($event.which === 27 || $event.which === 9) {
        this.inputEl.nativeElement.blur();
      }

      if ($event.which === 9 || $event.which === 13) {
        if (this.selectedItemInx !== -1) {
          const currentAction = this.options[this.selectedItemInx];
          if (currentAction.action) {
            currentAction.action();
          }
        } else if (this.uiModel && this.uiModel.trim()) {
          this.defaultSelection();
        }
      }
      this.closeMenu();
    }
  }

  openMenu() {
    if (this.options.length === 0) {
      this.filterActions();
    }
    this.menuOpen = true;
    var _this = this;
    this.currentMousedownHandler = function __handler__(evt) {
      if (!_this.wrapperEl.nativeElement.contains(evt.target)) {
        _this.menuOpen = false;
        document.removeEventListener('mousedown', __handler__, true);
        //evt.preventDefault();
        //evt.stopPropagation();
      }
    };
    document.addEventListener('mousedown', this.currentMousedownHandler, true);
  }

  closeMenu() {
    this.menuOpen = false;
    document.removeEventListener('mousedown', this.currentMousedownHandler, true);
  }

  clickActionHandler(evt, action, index: number) {
    evt.preventDefault();
    if (action.action) {
      this.selectedItemInx = index;
      action.action();
    }
    this.closeMenu();
  }

  defaultSelection() {
    if (this.options.length > 0) {
      this.selectedItemInx = 0;
      this.options[0].action();
    }
  }

  get checkerModel() {
    return this._control || this.inputModel;
  }

  hasErrors() {
    const model = this.checkerModel;
    return model ? model.touched && model.invalid : false;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { TasksService } from '../../core/services/tasks.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';
import { CommentsService } from '../../core/services/comments.service';
import { AttachmentsService } from '../../core/services/attachments.service';
import * as moment from 'moment';

@Component({
  selector: 'tasks-show',
  templateUrl: 'tasks-show.template.html',
  styleUrls: ['task-show.styles.scss'],
})
export class TasksShowComponent implements OnInit {
  @ViewChild('attCollectionEl') attCollectionEl;
  public task = null;
  private uploaderFn = null;
  public modalDialogOpen = false;
  private comment;
  private editComment;
  private currentUser;
  private currentUserRole = null;
  private isAuthorized = false;

  constructor(
    private auth: AuthService,
    private commentsSrv: CommentsService,
    private tasksSrv: TasksService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService,
    private attachmentsSrv: AttachmentsService
  ) {}

  ngOnInit() {
    this.ui.toolbar.title = 'Detalle de Tarea';
    var taskId = this.route.snapshot.params['id'];
    this.currentUser = this.auth.getCurrentUser();
    this.currentUserRole = this.auth.getCurrentRole();
    this.isAuthorized = this.auth.isAuthorized();
    this.tasksSrv.find(taskId).then((task) => {
      this.task = task;
      this.comment = {
        comment: null,
        commentable_type: 'Task',
        commentable_id: this.task.id,
        user_id: this.currentUser.id,
      };
      this.task.comments = this.task.comments.reverse().map((comment) => this.parseCommentDate(comment));
    });
  }
  goTo(url) {
    this.router.navigate([url]);
  }
  finishTask() {
    if (this.isAuthorized || this.task.user.id == this.currentUser.id) {
      this.tasksSrv.updateStatus(this.task, 'done').then(() => {
        this.ui.snackbar.show('Tarea terminada!');
      });
    } else {
      this.ui.snackbar.show('Lo siento. No puedes finalizar una tarea que no está asignada a tí.');
    }
  }
  cancelTask() {
    this.tasksSrv.updateStatus(this.task, 'canceled').then(() => {
      this.closeCancelModal();
      this.ui.snackbar.show('Tarea cancelada!');
    });
  }
  activateTask() {
    this.tasksSrv.updateStatus(this.task, 'active').then(() => {
      this.ui.snackbar.show('Tarea activada!');
    });
  }

  addComment() {
    this.commentsSrv.save(this.comment).then((res) => {
      this.task.comments.unshift(this.parseCommentDate(res));
      this.comment.comment = null;
    });
  }

  showEditComment(comment) {
    this.editComment = this.editComment ? null : comment;
  }

  updateComment() {
    this.commentsSrv.save(this.editComment).then((res) => {
      this.editComment = null;
    });
  }

  deleteComment(comment) {
    this.commentsSrv.delete(comment).then((res) => {
      this.task.comments.splice(this.task.comments.indexOf(comment), 1);
    });
  }

  parseCommentDate(comment) {
    return Object.assign(comment, {
      created_at: moment(comment.created_at).format('llll'),
    });
  }

  showCancelModal() {
    this.modalDialogOpen = true;
  }

  closeCancelModal() {
    this.modalDialogOpen = false;
  }

  attachFile() {
    this.attCollectionEl.openFileLoader();
  }

  attachFilesAllowed() {
    return this.task.attachments.length <= 20;
  }

  onUploadFinish(newAttachments: Array<any>) {
    const newIds = newAttachments.map((file) => file.id);
    this.task.attachments = [...this.task.attachments.filter((file) => !newIds.includes(file.id)), ...newAttachments];
  }

  onDeleteFinish(deletedAttachment: any) {
    this.task.attachments = [...this.task.attachments.filter((file) => deletedAttachment.id !== file.id)];
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validator,
} from '@angular/forms';

import { BooleanFieldValue } from '../../shared/annotations';
let uniqueId = 0;

interface IOption {
  value: any;
  label?: any;
  item: any;
}

@Component({
  selector: 'ui-autocomplete',
  templateUrl: 'ui-autocomplete.template.html',
  styleUrls: ['ui-autocomplete.styles.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UiAutocompleteComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UiAutocompleteComponent),
      multi: true,
    },
  ],
})
export class UiAutocompleteComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit {
  @Input() set formControlRef(formControlRef: FormControl) {
    this._control = formControlRef;
  }

  get checkerModel() {
    return this._control || this.inputModel;
  }
  @ViewChild('labelEl') public labelEl: ElementRef;
  @ViewChild('inputEl') public inputEl: any;
  @ViewChild('inputModel') public inputModel: any;
  @ViewChild('menuEl') public menuEl: ElementRef;
  @ViewChild('wrapperEl') public wrapperEl: ElementRef;
  @Input() public uiModel: string;
  @Output() public uiModelChange = new EventEmitter();
  @Input('uiId') public id: string = `ui-autocomplete-${uniqueId++}`;
  @Input('uiName') public name: string;
  @Input() public type: string;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() @BooleanFieldValue() public required: boolean = false;
  @Input() @BooleanFieldValue() public readonly: boolean = false;
  @Input() @BooleanFieldValue() public disabled: boolean = false;
  @Input() public options: IOption[];
  @Input() public initialId: number; // This is to be more exact about the user that we want to set as an initial value

  public isFocused: boolean;
  public filteredActions = [];
  private menuOpen: boolean;
  private currentMousedownHandler = null;
  private selectedItemInx = -1;
  private onChange: any;
  private _control: FormControl;

  constructor(private renderer: Renderer2, private injector: Injector) {}

  // Ths is to get an instance of form control
  public ngAfterViewInit() {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl && !this._control) {
      this._control = ngControl.control as FormControl;
    }
  }

  public writeValue(value: string) {
    this.uiModel = value;
    if (!value && !this.initialId) {
      this.selectedItemInx = -1;
    }
  }
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public validate(c: FormControl) {
    return this.selectedItemInx > -1 || !this.required ? null : { noUserSelected: true };
  }

  public ngOnInit() {
    this.checkInitialValue();
  }

  public checkInitialValue() {
    if ((this.uiModel || this.initialId) && this.selectedItemInx === -1) {
      const currentSelected = this.options.find(
        (option) => option.value === this.initialId || option.value === this.uiModel
      );
      if (currentSelected) {
        switch (this.type) {
          case 'user':
            this.uiModel = currentSelected.item.person.first_name + ' ' + currentSelected.item.person.last_name;
            this.selectedItemInx = this.options
              .filter((item) => this.parseLabel(item.item.person).toLowerCase().includes(this.uiModel.toLowerCase()))
              .findIndex((item) => item.value === this.initialId);
            break;
          case 'customer':
            this.selectedItemInx = this.options
              .filter((item) => item.label.toLowerCase().includes(this.uiModel.toLowerCase()))
              .findIndex((item) => item.value === this.initialId);
            break;
          case 'supplier':
            this.uiModel = currentSelected.item.name;
            this.selectedItemInx = this.options
              .filter((item) => item.item.name.toLowerCase().includes(this.uiModel.toLowerCase()))
              .findIndex((item) => item.value === this.initialId);
            break;
        }
      }
    }
  }

  public menuActions() {
    return !this.options
      ? []
      : this.options.map((option) => {
          switch (this.type) {
            case 'user':
              return {
                label: this.parseLabel(option.item.person),
                item: option.item,
                action: () => {
                  this.uiModelChange.emit(option.value);
                  this.uiModel = option.item.person.first_name + ' ' + option.item.person.last_name;
                  this.onChange(this.uiModel);
                },
              };
            case 'customer':
              return {
                label: option.label,
                item: null,
                action: () => {
                  this.uiModelChange.emit(option.value);
                  this.uiModel = option.label;
                  this.onChange(this.uiModel);
                },
              };
            case 'supplier':
              return {
                label: option.item.name,
                item: option.item,
                action: () => {
                  this.uiModelChange.emit(option.value);
                  this.uiModel = option.item.name;
                  this.onChange(this.uiModel);
                },
              };
          }
        });
  }

  public parseLabel(person: any) {
    return person.first_name + ' ' + person.last_name;
  }

  public filterActions() {
    this.filteredActions = !this.uiModel
      ? this.menuActions()
      : this.menuActions().filter((item) => item.label.toLowerCase().includes(this.uiModel.toLowerCase()));
  }

  public focusHandler($event) {
    this.isFocused = true;
    this.openMenu();
  }

  public blurHandler($event) {
    this.isFocused = false;
    this.propagateTouch();
    if (!this.filteredActions.length) {
      this.uiModel = '';
      this.filterActions();
    }
  }
  public inputTextChangeHandler($event) {
    this.uiModel = $event;
    this.filterActions();
    this.selectedItemInx = -1;
    this.uiModelChange.emit(null);
    this.onChange(this.uiModel);
  }

  public keyDownHandler($event) {
    if (!this.menuOpen) {
      this.openMenu();
    }
    if ($event.which === 40 || $event.which === 38) {
      $event.preventDefault();
      // up and down arrows
      if ($event.which === 40) {
        this.selectedItemInx++;
        if (this.selectedItemInx >= this.filteredActions.length) {
          this.selectedItemInx = -1;
        }
      } else {
        this.selectedItemInx--;
        if (this.selectedItemInx < -1) {
          this.selectedItemInx = this.filteredActions.length - 1;
        }
      }
    } else if ($event.which === 27 || $event.which === 9 || $event.which === 13) {
      // escape, tab or enter key
      $event.preventDefault();

      if ($event.which === 27 || $event.which === 9) {
        this.inputEl.nativeElement.blur();
      }

      if ($event.which === 9 || $event.which === 13) {
        if (this.selectedItemInx !== -1) {
          const currentAction = this.filteredActions[this.selectedItemInx];
          if (currentAction.action) {
            currentAction.action();
          }
        } else if (this.uiModel && this.uiModel.trim()) {
          this.defaultSelection();
        }
      }
      this.closeMenu();
    } else {
      // any other key
    }
  }

  public openMenu() {
    this.filterActions();
    this.menuOpen = true;
    const _this = this;
    this.currentMousedownHandler = function __handler__(evt) {
      if (!_this.wrapperEl.nativeElement.contains(evt.target)) {
        _this.menuOpen = false;
        document.removeEventListener('mousedown', __handler__, true);
        // evt.preventDefault();
        // evt.stopPropagation();
      }
    };
    document.addEventListener('mousedown', this.currentMousedownHandler, true);
  }

  public closeMenu() {
    this.menuOpen = false;
    document.removeEventListener('mousedown', this.currentMousedownHandler, true);
  }

  public clickActionHandler(evt, action, index: number) {
    evt.preventDefault();
    if (action.action) {
      this.selectedItemInx = index;
      action.action();
    }
    this.closeMenu();
  }

  public defaultSelection() {
    if (this.filteredActions.length > 0) {
      this.selectedItemInx = 0;
      this.filteredActions[0].action();
    }
  }

  public hasErrors() {
    const model = this.checkerModel;
    return model ? model.touched && model.invalid : false;
  }
  private propagateTouch: any = () => {};
}

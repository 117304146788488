export const sidebarActions = [
  {
    label: 'Órdenes de venta',
    icon: 'orders',
    url: '/orders/sell-orders',
  },
  {
    label: 'Órdenes de compra',
    icon: 'shopping-cart',
    url: '/orders/buy-orders',
  },
  {
    label: 'Productos',
    icon: 'products',
    url: '/orders/products',
  },
  {
    label: 'Condiciones',
    icon: 'conditions',
    url: '/orders/conditions',
  },
];

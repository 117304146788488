<div class="sidebar">
  <div class="sidebar__side-content">

  </div>
  <div class="sidebar__main-content" [class.is-open]="isOpen">
    <a href *ngFor="let action of visibleActions()"
      class="sidebar__item"
      (click)="clickItemHandler($event, action)"
      [class.is-active]="actionIsActive(action)">
      <span [ngClass]="action.icon ? 'icon-' + action.icon : ''" class="icon"></span>
      <span class="sidebar__item-label">
        {{ action.label }}
      </span>
      <span class="sidebar__item-hint">
        {{ action.label }}
      </span>
    </a>

    <!-- Se pasa esta funcionalidad al componente ui-app-selector
    <div class="sidebar__sub-content" *ngIf="subActions">
      <a href *ngFor="let action of subActions"
        class="sidebar__item"
        (click)="clickItemHandler($event, action)">
        <span [ngClass]="action.icon ? 'icon-' + action.icon : ''" class="icon"></span>
        <span class="sidebar__item-label">
          {{ action.label }}
        </span>
        <span class="sidebar__item-hint">
          {{ action.label }}
        </span>
      </a>
    </div>
    -->
  </div>
</div>

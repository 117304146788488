<div class="ui-panel  no-padding">
  <table class="ui-table">
    <thead>
      <tr>
        <th></th>
        <th>Nombre</th>
        <th>Correo</th>
        <th>Grupo</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let u of users" (click)="goTo('/tasks/users/' + u.id )">
        <td><img *ngIf="u.person?.avatar_url"
                 [src]="u.person?.avatar_url"></td>
        <td>{{ u.person.nickname ? u.person.nickname : u.person?.first_name+' '+u.person?.last_name }}</td>
        <td>{{ u.email }}</td>
        <td (click)="goTo('/tasks/usergroups/' + u.user_group.id )">{{ u.user_group?.name }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="currentUserRole == 'admin'" class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/tasks/users/new')" hint="Crear persona" icon="add"></ui-btn>
  </div>
</div>


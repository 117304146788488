import { Component, OnInit } from '@angular/core';
import { UserGroupsService } from '../../core/services/usergroups.service';
import { Router } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'userGroups-index',
  templateUrl: 'usergroups-index.template.html',
})
export class UserGroupsIndexComponent implements OnInit {
  public userGroups;
  private isAuthorized = false;
  constructor(
    private userGroupsSrv: UserGroupsService,
    private router: Router,
    private ui: UiService,
    private auth: AuthService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Grupos';
    this.isAuthorized = this.auth.isAuthorized();
    this.userGroupsSrv
      .fetch()
      .then((collection) => (this.userGroups = collection.asArray()));
  }
  goTo(url) {
    this.router.navigate([url]);
  }
  createUserGroupRedirect() {
    if (this.isAuthorized) {
      this.goTo(`/tasks/usergroups/new`);
    } else {
      this.ui.snackbar.show('No tienes permisos para crear grupos de personas.');
    }
  }
}

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';

@Component({
  selector: 'ui-dropdown',
  templateUrl: 'ui-dropdown.template.html',
  styleUrls: ['ui-dropdown.styles.scss'],
})
export class UiDropdownComponent {
  @ViewChild('dropdownEl') public dropdownEl: ElementRef;
  @Input() public isOpen: boolean = false;
  @Input() public containerEl: ElementRef = null;
  @Output() public isOpenChange = new EventEmitter();
  @Input() @BooleanFieldValue() public inline: boolean = false;
  @Input() @BooleanFieldValue() public alignRight: boolean = false;
  private currentClickHandler = null;

  constructor() {}

  public containerHasTarget(container, target) {
    return container.contains(target);
  }

  public removeCurrentClickHandler() {
    if (this.currentClickHandler) {
      document.removeEventListener('click', this.currentClickHandler, true);
      this.currentClickHandler = null;
    }
  }

  public ngOnChanges(changes) {
    if (changes.isOpen && changes.isOpen.previousValue !== changes.isOpen.currentValue) {
      if (changes.isOpen.currentValue) {
        this.removeCurrentClickHandler();
        this.currentClickHandler = function (evt) {
          if (!this.containerHasTarget(this.containerEl || this.dropdownEl.nativeElement, evt.target)) {
            this.isOpen = false;
            this.isOpenChange.emit(false);
            document.removeEventListener('click', this.currentClickHandler, true);
            this.currentClickHandler = null;
            evt.preventDefault();
            evt.stopPropagation();
          }
        }.bind(this);
        document.addEventListener('click', this.currentClickHandler, true);
      } else {
        this.removeCurrentClickHandler();
      }
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-attachment-icon',
  templateUrl: 'ui-attachment-icon.template.html',
  styleUrls: ['ui-attachment-icon.styles.scss'],
})
export class UiAttachmentIconComponent {
  @Input() attachment: any = {};

  private fileConfig = {
    text: {
      color: '#78909C',
      icon: 'align-left',
    },
    word: {
      color: '#3F51B5',
      icon: 'align-left',
    },
    pdf: {
      color: '#EF5350',
      icon: 'adobe-pdf',
    },
    data: {
      color: '#43A047',
      icon: 'grid',
    },
    excel: {
      color: '#43A047',
      icon: 'grid',
    },
    presentation: {
      color: '#EF5350',
      icon: 'pie-chart',
    },
    powerpoint: {
      color: '#EF5350',
      icon: 'pie-chart',
    },
    audio: {
      color: '#00BCD4',
      icon: 'audio',
    },
    video: {
      color: '#039BE5',
      icon: 'play',
    },
    font: {
      color: '#9E9E9E',
      icon: 'font',
    },
    // TODO: Revisar estos íconos:
    image: {
      color: '#FFA726',
      icon: 'brush',
    },
    compression: {
      color: '#AFB42B',
      icon: 'zip',
    },
    illustrator: {
      color: '#FF5722',
      icon: 'illustrator',
    },
    photoshop: {
      color: '#2979FF',
      icon: 'photoshop',
    },
    // indesign: {
    //   color: '#FFA726',
    //   icon: 'brush'
    // },
    sketch: {
      color: '#FFA726',
      icon: 'sketch',
    },
    other: {
      color: '#9E9E9E',
    },
  };

  private extTypes = {
    odt: 'text',
    log: 'text',
    pages: 'text',
    rtf: 'text',
    tex: 'text',
    txt: 'text',
    wpd: 'text',
    wps: 'text',
    wks: 'text',
    srt: 'text',

    doc: 'word',
    docx: 'word',

    pdf: 'pdf',

    csv: 'data',
    dat: 'data',
    xml: 'data',
    sav: 'data',
    ods: 'data',
    xlr: 'data',

    xls: 'excel',
    xlsx: 'excel',

    odp: 'presentation',
    key: 'presentation',

    pps: 'powerpoint',
    ppt: 'powerpoint',
    pptx: 'powerpoint',

    aif: 'audio',
    cda: 'audio',
    m3u: 'audio',
    m4a: 'audio',
    mid: 'audio',
    midi: 'audio',
    mp3: 'audio',
    mpa: 'audio',
    ogg: 'audio',
    wav: 'audio',
    wma: 'audio',

    '3gp': 'video',
    avi: 'video',
    flv: 'video',
    h264: 'video',
    m4v: 'video',
    mkv: 'video',
    mov: 'video',
    mp4: 'video',
    mpg: 'video',
    mpeg: 'video',
    rm: 'video',
    swf: 'video',
    wmv: 'video',

    fnt: 'font',
    fon: 'font',
    otf: 'font',
    ttf: 'font',
    woff: 'font',

    '7z': 'compression',
    deb: 'compression',
    zip: 'compression',
    zipx: 'compression',
    rar: 'compression',
    gz: 'compression',

    ps: 'image',
    ai: 'illustrator',
    psd: 'photoshop',
    sketch: 'sketch', //,
    // id: 'indesign'
  };

  constructor() {}

  getAttachmentIcon(attachment) {
    return this.fileConfig[this.extTypes[attachment.extension] || 'other'].icon;
  }
  getAttachmentColor(attachment) {
    return this.fileConfig[this.extTypes[attachment.extension] || 'other'].color;
  }
  getAttachmentThumbUrl(attachment) {
    return attachment.extras.thumb_url;
  }
  canShowThumbnail(attachment) {
    return attachment.attachment_type === 'image';
  }
}

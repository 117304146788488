import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../core/services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { UserGroupsService } from '../../core/services/usergroups.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'users-edit',
  templateUrl: './users-create-edit.template.html',
})
export class UsersEditComponent implements OnInit {
  public userId: number;
  public user = null;
  private tittle;
  private userGroupsOpts = [];
  private genderOptions = UsersService.genderOptions;
  private statusOptions = UsersService.statusOptions;
  private currentUserRole;
  constructor(
    private usersSrv: UsersService,
    private userGroupsSrv: UserGroupsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService,
    private auth: AuthService
  ) {}
  ngOnInit() {
    this.userId = this.route.snapshot.params['id'];
    this.usersSrv.find(this.userId).then((user) => {
      this.user = user;
      delete this.user.tasks;
      if (this.user && this.user.user_group)
        this.user.user_group = this.user.user_group.id;
    });
    this.tittle = 'Editar persona';
    this.userGroupsSrv.fetch().then(
      (userGroupCollection) =>
        (this.userGroupsOpts = userGroupCollection.asOptions('id', 'name', {
          orderBy: 'name',
        }))
    );
    this.currentUserRole = this.auth.getCurrentRole();
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.saveUser();
  }
  saveUser() {
    this.usersSrv.save(this.user).then((user) => {
      if (this.userId == user.id) this.auth.setCurrentUser = user;
      this.ui.snackbar.show({
        message: '¡Persona modificada!',
      });
      this.router.navigate(['/tasks/users/' + user.id]);
    });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthSudoGuardService implements CanActivate {
  private currentUserRole = null;
  constructor(private auth: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let userId = route.params['id'];
    let currentUser = this.auth.getCurrentUser();
    // console.log('TEST', userId, currentUser)
    // console.log('TEST', currentUser.id == userId, this.auth.getCurrentRole() === 'admin');
    return currentUser.id == userId || this.auth.getCurrentRole() === 'admin';
  }
}

import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class CostsService extends ApiService {
  private baseCost = {
    name: null,
    summary: null,
    code: null,
    value: null,
    registered_at: null,
    supplier: null,
    supplier_id: null,
  };
  constructor(
    private http: HttpX,
    private auth: AuthService
  ) {
    super('cost', 'costs', http, auth);
  }
  create(opts = {}) {
    return {
      ...this.baseCost,
      ...opts
    };
  }
}

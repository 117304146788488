<form #customerForm="ngForm" (ngSubmit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="customer">
    <h1 class="text-center"
      [class.color-disabled]="!customer.name">
      {{ customer.name || 'Agrega un nombre de cliente' }}
    </h1>
    <ui-input label="Nombre" name="customer_name" [(ngModel)]="customer.name"
      placeholder="Cliente de ejemplo" required>
    </ui-input>
    <div class="ui-form-group one-half">
      <ui-select label="Tipo Id" name="customer_identification_type"
        [(ngModel)]="customer.identification_type" placeholder="Cédula, NIT, Pasaporte, etc."
        [options]="customersSrv.idTypes">
      </ui-select>
      <ui-input label="Id" name="customer_identificacion" [(ngModel)]="customer.identification"
        placeholder="4815162342" required>
      </ui-input>
      <ui-input label="Razón social" name="customer_legal_name" [(ngModel)]="customer.legal_name"
        placeholder="Razón social del cliente">
      </ui-input>
      <ui-input label="Correo electrónico" name="customer_email" [(ngModel)]="customer.email"
        placeholder="ejemplo@email.com" required>
      </ui-input>
      <ui-input label="Telefono" name="customer_phone" [(ngModel)]="customer.phone"
        placeholder="(+57) 310 123 1234" required>
      </ui-input>
      <ui-input label="Ciudad" name="customer_city" [(ngModel)]="customer.city"
        placeholder="Bogotá, Lima, Quito" required>
      </ui-input>
      <ui-input label="Dirección" name="customer_address" [(ngModel)]="customer.address"
        placeholder="P. Sherman Calle Wallaby 42 Sydney" required>
      </ui-input>
      <ui-select label="Fuente" name="customer_source"
        [(ngModel)]="customer.source" placeholder="Sitio web, redes sociales, etc."
        [options]="customersSrv.custSources">
      </ui-select>
    </div>
    <ui-input type="textarea" label="Notas" name="customer_summary" [(ngModel)]="customer.summary"
      placeholder="Añade tus notas aquí">
    </ui-input>
    <div *ngIf="customer.person">
      <h2 class="text-center margin-top--double">Contacto</h2>
      <div class="ui-form-group one-half">
        <ui-input label="Nombre" name="customer_people_name" 
          placeholder="Contacto de ejemplo" [(ngModel)]="customer.person.first_name" required></ui-input>
        <ui-input label="Apellido" name="customer_people_last_name"
          placeholder="Apellido del contacto" [(ngModel)]="customer.person.last_name" required></ui-input>
        <ui-input label="Telefono" name="customer_people_phone"
          placeholder="Telefono del contacto" [(ngModel)]="customer.person.phone"></ui-input>
        <ui-input label="Cargo" name="customer_job_title"
          placeholder="Cargo del contacto" [(ngModel)]="customer.person.job_title"></ui-input>
      </div>
    </div>
  </div>

  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="saveCustomer()" hint="Guardar cliente"
        [disabled]="!customerForm.form.valid" icon="save">
      </ui-btn>
    </div>
  </div>
</form>

export interface ordersModeTexts {
  userLabel: string;
  newOrderLink: string;
  baseLink: string;
  modeText: string;
  listTitle: string;
  nameFiltertext: string;
  createoOrderHint: string;
  newOrderTitle: string;
  detailPageTitle: string;
  detailPageEmptyItems: string;
  editOrderPageTitle: string;
}

export const buyOrders: ordersModeTexts = {
  userLabel: 'Proveedor',
  newOrderLink: '/orders/buy-orders/new',
  baseLink: '/orders/buy-orders/',
  modeText: 'Compra',
  listTitle: 'Órdenes de compra',
  nameFiltertext: 'Nombre de la orden de compra',
  createoOrderHint: 'Crear orden de compra',
  newOrderTitle: 'Nueva orden de compra',
  detailPageTitle: 'Detalle de la orden de compra',
  detailPageEmptyItems: 'Actualmente no hay items para esta orden de compra',
  editOrderPageTitle: 'Editar orden de compra',
};

export const sellOrders: ordersModeTexts = {
  userLabel: 'Cliente',
  newOrderLink: '/orders/sell-orders/new',
  baseLink: '/orders/sell-orders/',
  modeText: 'Venta',
  listTitle: 'Órdenes de venta',
  nameFiltertext: 'Nombre de la orden',
  createoOrderHint: 'Crear orden',
  newOrderTitle: 'Nueva orden',
  detailPageTitle: 'Detalle de la orden',
  detailPageEmptyItems: 'Actualmente no hay items para esta orden',
  editOrderPageTitle: 'Editar orden',
};

declare var gapi: any;

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpX } from './http-x.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { API_URL, OAUTH_ID } from '../../shared/constants';
import { logger } from '../../shared/logger.class';
import { UiService } from './ui.service';

@Injectable()
export class AuthService {
  private sessionsUrl = `${API_URL}sessions`;
  private events = new BehaviorSubject({ finished: false, status: 'initializing' });
  private isInitialized = false;
  private loggedWithPsw = false;
  private isSignedIn = false;
  private currentUser = null;
  private googleProfile = null;
  private firstSessionMessageKey = 'showFirstSessionM';
  constructor(private http: HttpX, private zone: NgZone, private router: Router, private ui: UiService) {
    this.initialize();
  }
  onEvent(callback) {
    return this.events.subscribe(callback);
  }
  onInit(callback) {
    // TODO: Unused, check how to rewrite
    var subscription = this.events.subscribe({
      next: (event) => {
        if (event.finished) {
          // subscription.unsubscribe();
          callback();
        }
      },
    });
  }
  signedIn() {
    return this.isSignedIn;
  }
  get getGoogleProfile() {
    return this.googleProfile;
  }
  getCurrentUser() {
    return this.currentUser;
  }
  setCurrentUser(currentUser) {
    this.currentUser = currentUser;
  }
  getCurrentRole() {
    return this.currentUser.roles[0] && this.currentUser.roles[0].name ? this.currentUser.roles[0].name : 'none';
  }
  isAuthorized() {
    return this.currentUser.roles[0] && this.currentUser.roles[0].name ? this.currentUser.roles[0].name : false;
  }
  initialize() {
    logger.log('>> initializing auth service');

    var currentUser: any = localStorage.getItem('currentUser');
    if (currentUser) currentUser = JSON.parse(currentUser);

    if (currentUser && currentUser.token) {
      // if we have a currentUser from localStorage that means last time we logged using email & password
      logger.log('>> user found in local storage! using stored values to authenticate');
      this.currentUser = currentUser;
      this.isSignedIn = true;
      this.isInitialized = true;
      this.loggedWithPsw = true;
      this.http.addHeader('Authorization', `Bearer ${currentUser.token}`);
      logger.success('>> Auth process finished.');
      this.events.next({ finished: true, status: 'internal_user_ok' });
    } else {
      logger.warn('>> no user found in local storage.');
    }

    gapi.load('auth2', () => {
      gapi.auth2
        .init({
          client_id: OAUTH_ID,
          // Scopes to request in addition to 'profile' and 'email'
          // scope: 'additional_scope'
        })
        .then(
          (authInstance) => {
            this.zone.run(() => {
              logger.log('>> Google auth2 initialized!');
              if (authInstance.isSignedIn.get()) {
                if (!this.isInitialized) {
                  // we didn't found anything in localstorage but, oddly, the user is signed in with google
                  // so we continue validating the profile.
                  logger.log('>> Google signed in, but local storage empty!! continue validating with back end!');
                  this.setGoogleProfile(authInstance.currentUser.get());
                } else {
                  logger.log(
                    '>> Google signed in, but credentials already fetched from local storage so, nothing more to do'
                  );
                }
              } else {
                logger.warn('>> no Google user signed in');
                this.isInitialized = true;
                this.events.next({ finished: true, status: 'initialized' });
              }
            });
          },
          (error) => {
            this.zone.run(() => {
              this.events.next({ finished: true, status: 'auth2_error' });
            });
          }
        );
    });
  }
  authInstance() {
    return gapi.auth2.getAuthInstance();
  }
  verifyIfLogged(): Observable<boolean> {
    if (this.isInitialized) return of(this.isSignedIn);
    return new Observable((observer) => {
      var subscription = this.events.subscribe({
        next: (event) => {
          if (event.finished) {
            logger.log('initializing finished!');
            subscription.unsubscribe();
            observer.next(this.isSignedIn);
          }
        },
      });
    });
  }
  resetAuth(removeFromStorage?) {
    this.isSignedIn = false;
    this.currentUser = null;
    if (removeFromStorage) localStorage.removeItem('currentUser');
    this.http.removeHeader('Authorization');
    logger.success('sign out process finished!');
    this.events.next({ finished: true, status: 'logged_out' });
  }
  signOut() {
    logger.log('signing out');
    return new Promise((resolve, reject) => {
      if (this.loggedWithPsw) {
        this.resetAuth(true);
        resolve(null);
      } else {
        this.authInstance()
          .signOut()
          .then(() => {
            this.zone.run(() => {
              this.resetAuth();
              resolve(null);
            });
          });
      }
    });
  }
  googleAuthHandler(isSignUp?: boolean) {
    return new Promise((resolve, reject) => {
      this.authInstance()
        .signIn()
        .then(
          (currentUser) => {
            this.zone.run(() => {
              this.setGoogleProfile(currentUser).then((ret) => resolve(isSignUp ? true : ret));
            });
          },
          (error) => {
            logger.error("Couldn't authenticate with Google", error);
            this.zone.run(() => {
              resolve(false);
            });
          }
        );
    });
  }
  signInWithPassword(email: string, password: string) {
    return this.internalAuth({
      auth_method: 'password',
      email,
      password,
    });
  }
  signUp(doc: any) {
    return this.http.post(`${this.sessionsUrl}/sign_up`, doc);
  }
  signUpToken(email: string) {
    return this.http.post(`${this.sessionsUrl}/sign_up_token`, { email }).toPromise();
  }
  internalAuth(authData) {
    // guard clause: returns false promise if not googleProfile
    if (!authData) return Promise.resolve(false);

    return this.http
      .post(this.sessionsUrl, authData)
      .toPromise()
      .then((response) => {
        return this.authResponseHandler(response, authData.auth_method);
      })
      .catch((error) => {
        logger.error('>> unknown error doing interal authentication. finishing.');
        this.events.next({ finished: true, status: 'internal_auth_error' });
        this.isInitialized = true;
        this.isSignedIn = false;
        return this.isSignedIn;
      });
  }

  authResponseHandler(response: any, authMethod: string) {
    const jsonResponse = response.body;
    if (jsonResponse.valid) {
      if (jsonResponse.new_user) {
        logger.error('>> that Google user is not registered here. no token set. finishing.');
        this.events.next({ finished: true, status: 'internal_new_user' });
      } else {
        this.currentUser = jsonResponse.user;
        this.currentUser.token = jsonResponse.token;
        logger.log('>> user found in the back end! setting http auth token: ', this.currentUser);
        if (authMethod === 'password') {
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          this.loggedWithPsw = true;
        }
        this.isSignedIn = true;
        this.http.addHeader('Authorization', `Bearer ${jsonResponse.token}`);
        logger.success('>> Auth process finished.');
        this.events.next({ finished: true, status: 'internal_user_ok' });
        if (!this.currentUser.last_login) {
          localStorage.setItem(this.firstSessionMessageKey, 'true');
        }
      }
    } else {
      logger.error('>> error doing interal authentication. finishing.');
      this.events.next({ finished: true, status: 'internal_auth_error' });
    }
    this.isInitialized = true;
    return this.isSignedIn;
  }
  setGoogleProfile(currentUser) {
    // var profile = this.authInstance().currentUser.get().getBasicProfile();
    // var id_token = this.authInstance().currentUser.get().getAuthResponse().id_token;
    var profile = currentUser.getBasicProfile();
    var id_token = currentUser.getAuthResponse().id_token;
    this.googleProfile = {
      id: profile.getId(),
      name: profile.getName(),
      given_name: profile.getGivenName(),
      family_name: profile.getFamilyName(),
      image_url: profile.getImageUrl(),
      email: profile.getEmail(),
      id_token: id_token,
    };

    logger.log('>> Google signin OK! Google profile is: ', this.googleProfile);
    return this.internalAuth({ ...this.googleProfile, auth_method: 'google' });
  }
  handleUnauthorizedResponse() {
    this.ui.snackbar.show('Usuario no autorizado. Redireccionando a la pantalla de inicio de sesión.');
    this.signOut().then(() => this.router.navigate(['/']));
  }
  checkToken(token: string) {
    return this.http.get(`${this.sessionsUrl}/check_token/${token}`);
  }
  public get showFirstSessionMessage() {
    return localStorage.getItem(this.firstSessionMessageKey);
  }
  public firstSessionMessageShowed() {
    localStorage.removeItem(this.firstSessionMessageKey);
  }
}

<ng-content></ng-content>
<ui-dropdown [isOpen]="isOpen"
  (isOpenChange)="isOpenChangeHandler($event)"
  [alignRight]="alignRight" inline>
  <ul class="ui-menu">
    <li *ngFor="let a of actions"
      [class.hidden]="shouldHideAction(a)">
      <a href="#" class="ui-menu__link" (click)="clickActionHandler($event, a)">
        <span [class]="'ui-menu__icon' + (a.icon ? ' icon-' + a.icon : '')"></span>
        <span class="ui-menu__label">{{ a.label }}</span>
      </a>
    </li>
  </ul>
</ui-dropdown>

import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class PeopleService extends ApiService {
  private basePerson = {
    first_name: '',
    last_name: '',
    gender: '',
    birth_date: '',
    identification_type: '',
    identification: '',
    phone: '',
    url: '',
    work_phone: '',
    avatar_url: '',
    customer_id: null
  };
  constructor(
    private http: HttpX,
    private auth: AuthService
  ) {
    super('person', 'people', http, auth);
  }
  create(opts = {}) {
    return {
      ...this.basePerson,
      ...opts
    };
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BooleanFieldValue } from '../../shared/annotations';

let uniqueId = 0;

@Component({
  selector: 'ui-input',
  templateUrl: 'ui-input.template.html',
  styleUrls: ['ui-input.styles.scss'],
})
export class UiInputComponent implements ControlValueAccessor, OnInit {
  @Input() set formControlRef(formControlRef: NgControl) {
    this._control = formControlRef;
  }

  get checkerModel() {
    return this._control || this.inputModel;
  }
  get errorMessage() {
    if (this.checkerModel.errors.required) {
      return 'Este campo es requerido';
    }

    if (this.checkerModel.errors.minlength) {
      return 'Debe ingresar por lo menos ' + `${this.checkerModel.errors.minlength.requiredLength} caracteres`;
    }

    if (this.checkerModel.errors.email) {
      return `Debe ingresar un correo electrónico valido`;
    }

    if (this.checkerModel.errors.min) {
      return `El valor mínimo es ${this.checkerModel.errors.min.min}`;
    }

    if (this.checkerModel.errors.max) {
      return `El valor máximo es ${this.checkerModel.errors.max.max}`;
    }

    return null;
  }
  @ViewChild('inputModel') public inputModel: any;
  @ViewChild('inputEl') public inputEl: ElementRef;
  @Input() public isHighlighted: boolean;
  @Output() public focus = new EventEmitter();
  @Output() public blur = new EventEmitter();
  @Output() public click = new EventEmitter();
  @Output() public iconClick = new EventEmitter();
  @Input() public type: string;
  @Input('elemId') public elemId: string = `ui-input-${uniqueId++}`;
  @Input('name') public name: string;
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public visiblePassword: boolean;
  @Input() public width: string;
  @Input() public placeholder: string;
  @Input() @BooleanFieldValue() public iconClickable: boolean = false;
  @Input() @BooleanFieldValue() public required: boolean = false;
  @Input() @BooleanFieldValue() public readonly: boolean = false;
  @Input() @BooleanFieldValue() public disabled: boolean = false;
  @Input() @BooleanFieldValue() public manualHighlight: boolean = false;
  public isActive: boolean;
  private value: any;
  private _control: NgControl;
  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this._control = this.ngControl;
    }
  }

  public ngOnInit() {
    if (this.visiblePassword) {
      this.icon = 'visibility';
      this.iconClickable = true;
    }
  }

  public writeValue(value: any) {
    this.value = value;
  }
  public registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: (value: any) => void) {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public hasErrors() {
    const model = this.checkerModel;
    return model ? model.touched && model.invalid : false;
  }

  public ngModelChangeHandler($event) {
    this.value = this.type === 'number' ? Number($event) : $event;
    this.propagateChange(this.value);
  }
  public labelClickHandler($event) {
    $event.stopPropagation();
  }
  public clickHandler($event) {
    $event.stopPropagation();
    this.click.emit($event);
  }
  public iconClickHandler($event) {
    $event.stopPropagation();
    if (this.visiblePassword) {
      this.togglePasswordVisibility();
    } else {
      this.iconClick.emit($event);
    }
  }
  public focusHandler($event) {
    if (!this.manualHighlight) {
      this.isActive = true;
    }
    this.focus.emit($event);
  }
  public blurHandler($event) {
    if (!this.manualHighlight) {
      this.isActive = false;
    }
    this.blur.emit($event);
    this.propagateTouch();
  }
  public togglePasswordVisibility() {
    if (this.icon === 'visibility-off') {
      this.icon = 'visibility';
      this.type = 'password';
    } else {
      this.icon = 'visibility-off';
      this.type = 'text';
    }
  }
  private propagateChange: any = () => {};
  private propagateTouch: any = () => {};
}

import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { roundNumber } from '../../../shared/helpers';

@Component({
  selector: 'item-dialog',
  templateUrl: 'item-dialog.template.html',
})
export class ItemDialogComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public productsOptions = [];
  public totalVal = 0;
  @Input() orderRoundedValues: boolean;
  @Input() products: any;
  @Input() item: any;
  @Input() mode: 'buy' | 'sell';
  @Output() closeModal = new EventEmitter<any>();
  private unsubscribe$ = new Subject<void>();
  private subTotal = 0;
  private product: any;
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.productsOptions = this.products.asOptions('id', 'name', {
      orderBy: 'name',
    });
    this.initGenericProduct();
    this.initForm();
    this.form.controls.product_id.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((productId) => {
      if (productId != null) {
        this.updateItemPrice(productId);
      }
    });
    this.form.controls.product_cost.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((cost) => {
      this.updateProfitRateCostAndPrice(null, cost, null);
    });
    this.form.controls.profit_rate.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((profitRate) => {
      this.updateProfitRateCostAndPrice(profitRate, null, null);
    });
    this.form.controls.price.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((price) => {
      this.updateProfitRateCostAndPrice(null, null, price);
    });
    this.form.controls.quantity.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateSubtotal();
      this.updateDiscountPercentage(this.form.controls.discount_percent.value);
    });
    this.form.controls.discount.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((discount) => {
      if (discount > 0) {
        discount = discount * -1;
        this.form.controls.discount.setValue(discount, {
          emitValue: false,
        });
      }
      this.updateDiscount(discount);
    });
    this.form.controls.discount_percent.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((discPercentage) => {
      this.updateDiscountPercentage(Math.abs(discPercentage));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeDialog(cancel?: boolean) {
    this.closeModal.emit(cancel ? null : this.productResult);
  }

  private initGenericProduct() {
    if (this.item && this.item.product_id > 0) {
      const { generic } = this.products.find(this.item.product_id);
      Object.assign(this.item, { generic });
    }
  }

  private initForm() {
    this.product = this.item;
    this.form = this.formBuilder.group({
      product_id: [(this.item && this.item.product_id) || null, this.mode === 'buy' ? null : Validators.required],
      product_name: [
        (this.item && this.item.item_name && this.item.name) || null,
        this.mode === 'sell' ? null : Validators.required,
      ],
      summary: [(this.item && this.item.summary) || null],
      product_cost: [(this.item && this.item.base_cost) || null],
      profit_rate: [(this.item && this.item.profit_rate) || null],
      price: [(this.item && this.item.price != null && this.item.price) || null, Validators.required],
      quantity: [
        (this.item && this.item.quantity) || null,
        [Validators.required, Validators.min(this.minVal), Validators.max(this.maxVal)],
      ],
      discount_percent: [
        (this.item && this.item.discount_percent != null && Math.abs(this.item.discount_percent)) || null,
        Validators.min(0),
      ],
      discount: [(this.item && this.item.discount != null && this.item.discount) || null],
    });
    if (this.product) {
      if (this.isGeneric || this.mode === 'buy') {
        this.product.price_locked = false;
      } else {
        const lockedP = this.products.find(this.product.product_id).price_locked;
        this.product.price_locked = lockedP != null ? lockedP : true;
      }
      this.lockedPriceAndGenericHandler();
      this.updateSubtotal();
    }
  }

  private updateItemPrice(productId: number) {
    if (this.item && Number(productId) === this.item.product_id) {
      this.product = this.item;
      Object.assign(this.product, { description: this.item.summary });
    } else {
      this.product = this.products.find(productId);
    }
    this.form.controls.product_name.setValue(this.product.name);
    this.form.controls.summary.setValue(this.product.description);
    this.form.controls.product_cost.setValue(Math.round(this.product.base_cost), {
      emitEvent: false,
    });
    this.form.controls.profit_rate.setValue(this.product.profit_rate, {
      emitEvent: false,
    });
    this.form.controls.price.setValue(Math.round(this.product.base_price), {
      emitEvent: false,
    });
    this.form.controls.quantity.setValidators([Validators.max(this.maxVal), Validators.min(this.minVal)]);
    this.lockedPriceAndGenericHandler();
    this.updateSubtotal();
  }

  private lockedPriceAndGenericHandler() {
    const priceCtrl = this.form.controls.price;
    const costCtrl = this.form.controls.product_cost;
    const profitRateCtrl = this.form.controls.profit_rate;
    if (this.product.price_locked) {
      priceCtrl.disable();
    } else {
      priceCtrl.enable();
    }
    if (this.product.generic) {
      costCtrl.enable();
      profitRateCtrl.enable();
    } else {
      costCtrl.disable();
      profitRateCtrl.disable();
    }
  }

  private updateSubtotal(noSyncTotal?: boolean) {
    const quantity = this.form.controls.quantity.value || 0;
    const price = this.form.controls.price.value || 0;
    this.subTotal = quantity * price;
    if (!noSyncTotal) {
      this.updateTotalPrice();
    }
  }

  private updateTotalPrice() {
    const discount = this.form.controls.discount.value || 0;
    this.totalVal = this.subTotal + discount;
  }

  private updateDiscount(discount: number) {
    const discPercCtrl = this.form.controls.discount_percent;
    discPercCtrl.setValue(parseFloat(Math.abs((discount / this.subTotal) * 100).toFixed(2)), {
      emitEvent: false,
    });
    this.updateTotalPrice();
  }

  private updateDiscountPercentage(percentage: number) {
    const discCtrl = this.form.controls.discount;
    discCtrl.setValue((percentage / 100) * this.subTotal * -1, {
      emitEvent: false,
    });
    this.updateTotalPrice();
  }

  private updateProfitRateCostAndPrice(profitRate: number, cost: number, price: number) {
    const profitRateCtrl = this.form.controls.profit_rate;
    const costCtrl = this.form.controls.product_cost;
    const priceCtrl = this.form.controls.price;

    if (profitRate && costCtrl.value) {
      priceCtrl.setValue(Math.round(costCtrl.value * profitRate), { emitEvent: false });
    }
    if (cost && profitRateCtrl.value) {
      priceCtrl.setValue(Math.round(cost * profitRateCtrl.value), { emitEvent: false });
    }
    if (price && costCtrl.value) {
      profitRateCtrl.setValue((price / costCtrl.value).toFixed(2), {
        emitEvent: false,
      });
    }
    this.updateSubtotal();
    this.updateDiscountPercentage(this.form.controls.discount_percent.value);
  }

  get total() {
    return roundNumber(this.orderRoundedValues, this.totalVal);
  }

  get maxVal() {
    if (this.mode === 'sell') {
      return this.product && this.product.maximum_quantity != null ? +this.product.maximum_quantity : 50;
    }
    return Number.MAX_SAFE_INTEGER;
  }

  get minVal() {
    return this.product && this.product.minimum_quantity != null ? +this.product.minimum_quantity : 1;
  }

  get isGeneric() {
    return this.product && this.product.generic;
  }

  private get productResult() {
    const formValue = this.form.getRawValue();

    formValue.product_name =
      formValue.product_name ||
      this.product.name ||
      this.product.product_name ||
      formValue.product_title ||
      this.product.product_title;

    formValue.item_name = formValue.product_name;
    formValue.product_cost = parseFloat(formValue.product_cost);
    formValue.profit_rate = parseFloat(formValue.profit_rate);
    formValue.maximum_quantity = this.maxVal;
    formValue.minimum_quantity = this.minVal;

    return formValue;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '../enums/order-status.enum';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case OrderStatus.INITIAL:
        return 'Registrada';
      case OrderStatus.CLOSED:
        return 'Cerrada';
      case OrderStatus.APPROVED:
        return 'Aprobada';
      case OrderStatus.FINISHED:
        return 'Finalizada';
      case OrderStatus.CANCELED:
        return 'Cancelada';  
      default:
        break;
    }
  }
}
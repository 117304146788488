<div class="ui-attachment-icon" *ngIf="attachment">
  <img *ngIf="canShowThumbnail(attachment)" [src]="getAttachmentThumbUrl(attachment)" class="ui-attachment-icon__thumb" />
  <div *ngIf="!canShowThumbnail(attachment)" class="ui-attachment-icon__generic" >
    <img [src]="fileBlank" class="ui-attachment-icon__file-blank" />
    <span class="ui-attachment-icon__extension"
      [style.background-color]="getAttachmentColor(attachment)">
      {{ attachment.extension }}
    </span>
    <span [class]="'ui-attachment-icon__ext-icon icon-' + getAttachmentIcon(attachment)">
    </span>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../core/services/customers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'customers-edit',
  templateUrl: 'customers-create-edit.template.html',
})
export class CustomersEditComponent implements OnInit {
  public customer;
  constructor(
    private customersSrv: CustomersService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Editar Cliente';
    var customerId = this.route.snapshot.params['id'];
    this.customersSrv.find(customerId).then((customer) => (this.customer = customer));
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.saveCustomer();
  }
  saveCustomer() {
    this.customersSrv.save(this.customer).then((customer) => {
      this.ui.snackbar.show({
        message: 'Cliente modificado!',
      });
      this.router.navigate(['/tasks/customers/' + customer.id]);
    });
  }
}

<div class="ui-panel" *ngIf="dummy">
  <h1>Create a new Dummy</h1>
  <h2>{{ dummy.first_name + ' ' + dummy.last_name }}</h2>
  <form (submit)="handleSubmit($event)">
    <ui-input type="text" label="First Name" name="dummy_first_name" [(ngModel)]="dummy.first_name"></ui-input>
    <ui-input type="text" label="Last Name" name="dummy_last_name" [(ngModel)]="dummy.last_name"></ui-input>
    <ui-input type="text" label="Bio" name="dummy_bio" [(ngModel)]="dummy.bio"></ui-input>
  </form>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="saveDummy()" hint="Save dummy">
      <span class="icon icon-save"></span>
    </ui-btn>
  </div>
</div>

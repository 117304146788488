import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import saveAs from 'file-saver';

import { HttpX } from './http-x.service';
import Collection from '../../shared/collection.class';
import { logger } from '../../shared/logger.class';
import { API_URL } from '../../shared/constants';
import { objectFirstChild } from '../../shared/helpers';
import { AuthService } from './auth.service';

export interface ICollectionResponse<T = any> {
  collection: Collection<T>;
  pagination: {
    perPage: number;
    total: number;
  };
}

export type ISingleItemResponse<T = any> = Collection<T>;
@Injectable()
export class ApiService {
  private isSaving;
  protected storedCollection;
  private hasFetchedCollection;
  private previousExtras;
  private eventStream;
  asyncSave = false;

  constructor(
    private objectName: string,
    private objectUrl: string,
    private rootHttp: HttpX,
    private authSrv: AuthService
  ) {
    logger.warn('constructing api service for: ' + this.objectName);
    this.reset();
    this.authSrv.onEvent((evt) => {
      if (evt.status === 'logged_out') this.reset();
    });
  }

  private reset() {
    this.isSaving = false;
    this.storedCollection = new Collection();
    // this.hasFetchedCollection = false;
    // this.previousExtras = null;
    this.eventStream = new BehaviorSubject({
      type: 'initialize',
      payload: [],
    });
  }

  private createSendObj(object, extras = {}): Object {
    var sentDataObj = { ...extras };
    sentDataObj[this.objectName] = object;
    return sentDataObj;
  }

  protected raiseEvent(type, payload) {
    this.eventStream.next({ type, payload });
  }

  collection(): Collection {
    // if (this.storedCollection.isEmpty() || (extras && extras !== this.previousExtras)) this.fetch(extras);
    return this.storedCollection;
  }
  events(): Observable<any> {
    return this.eventStream.asObservable();
  }
  onEvent(callback) {
    return this.events().subscribe(callback);
  }
  on(eventActions) {
    return this.events().subscribe((event) => {
      if (eventActions[event.type]) eventActions[event.type](event.payload);
    });
  }
  fetch(extras?: string, config?: { baseUrl?: string }): Promise<any> {
    // if (this.hasFetchedCollection && (extras === this.previousExtras)) {
    //   logger.warn('collection for: ' + this.objectName + ' with extras: ' + (extras ? extras : 'no extras') + ' and previous extras: ' + (this.previousExtras ? this.previousExtras : 'no previous,') + ' already fetched. returning stored colection.');
    //   this.raiseEvent('collection_fetched', this.storedCollection);
    //   return Promise.resolve(this.storedCollection);
    // }
    // if (this.currentFetchPromise && (extras === this.previousExtras)) {
    //   logger.warn('data for: ' + this.objectName + ' being fetched. returning stored promise.');
    //   return this.currentFetchPromise;
    // }
    // this.previousExtras = extras;
    return this.rootHttp
      .get(API_URL + (config?.baseUrl || this.objectUrl) + (extras || ''))
      .then((response) => {
        // logger.warn('RESPONSE', API_URL + this.objectUrl + (extras || ''), response);
        const { payload, pagination } = response;
        this.storedCollection.set(objectFirstChild(payload));
        // this.hasFetchedCollection = true;
        this.raiseEvent('collection_fetched', this.storedCollection);
        return pagination ? { pagination, collection: this.storedCollection } : this.storedCollection;
      })
      .catch(this.handleError.bind(this));

    // .first()
    // .map(response => {
    //   this.storedCollection = objectFirstChild(response.json());
    //   this.eventStream.next({
    //     type: 'collection_fetched',
    //     payload: this.storedCollection
    //   });
    //   return this.storedCollection;
    // })
    // .catch(this.handleError);
  }
  find(objectId) {
    return this.rootHttp
      .get(API_URL + this.objectUrl + '/' + objectId)
      .then((response) => {
        const { payload } = response;
        let item = objectFirstChild(payload);
        if (item) {
          this.storedCollection.addOrReplace(item);
          this.raiseEvent('item_fetched', item);
        } else {
          this.raiseEvent('no_item_found', null);
        }
        logger.warn('found item for: ' + this.objectName, item);
        return item;
      })
      .catch(this.handleError.bind(this));
  }
  downloadData(path, acceptType, fileName) {
    return this.rootHttp
      .download(API_URL + this.objectUrl + '/' + path)
      .toPromise()
      .then((res) => saveAs(new Blob([res], { type: acceptType }), fileName))
      .catch(this.handleError.bind(this));
  }
  downloadDataById(objectId, customPath = '', acceptType, fileName) {
    return this.rootHttp
      .download(API_URL + this.objectUrl + '/' + objectId + customPath)
      .toPromise()
      .then((res) => saveAs(new Blob([res], { type: acceptType }), fileName))
      .catch(this.handleError.bind(this));
  }
  post(objectId, path, body) {
    return this.rootHttp
      .post(API_URL + this.objectUrl + '/' + objectId + path, body)
      .toPromise()
      .catch(this.handleError.bind(this));
  }
  save(object, extras?) {
    if (!this.isSaving || this.asyncSave) {
      this.isSaving = true;
      if (!object.id) {
        // si no tiene objectId se asume que es un elemento nuevo
        return this.rootHttp
          .post(API_URL + this.objectUrl, this.createSendObj(object, extras))
          .toPromise()
          .then(({ body }) => {
            this.isSaving = false;
            let newItem = objectFirstChild(body);
            this.storedCollection.addOrReplace(newItem);
            this.raiseEvent('item_added', newItem);
            logger.warn('added item for: ' + this.objectName, newItem);
            return newItem;
          })
          .catch((err) => {
            this.isSaving = false;
            this.handleError.bind(this);
          });
      } else {
        return this.rootHttp
          .put(API_URL + this.objectUrl + '/' + object.id, this.createSendObj(object, extras))
          .toPromise()
          .then((response) => {
            this.isSaving = false;
            let returnedItem = response;
            let updatedItem = returnedItem ? objectFirstChild(returnedItem) : returnedItem;
            if (returnedItem) this.storedCollection.addOrReplace(updatedItem);
            this.raiseEvent('item_updated', updatedItem);
            logger.warn('updated item for: ' + this.objectName, updatedItem);
            return updatedItem;
          })
          .catch((err) => {
            this.isSaving = false;
            this.handleError.bind(this);
          });
      }
    }
  }
  delete(object) {
    if (object.id) {
      let conditionId = object.condition ? object.condition.id : '';
      return this.rootHttp
        .delete(API_URL + this.objectUrl + '/' + object.id + '/' + conditionId)
        .toPromise()
        .then((response) => {
          this.storedCollection.remove(object.id);
          this.raiseEvent('item_deleted', object);
          logger.warn('deleted item for: ' + this.objectName, object);
          return object;
        })
        .catch(this.handleError.bind(this));
    }
  }
  private handleError(error: any): Promise<any> {
    logger.error('An error occurred', error);
    if (error.status == 401) this.authSrv.handleUnauthorizedResponse();
    return Promise.reject(error.message || error);
  }
}

import { Component, OnInit } from '@angular/core';
import { ProjectsService } from '../../core/services/projects.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { CustomersService } from '../../core/services/customers.service';

@Component({
  selector: 'projects-create',
  templateUrl: 'projects-create-edit.template.html',
})
export class ProjectsCreateComponent implements OnInit {
  public project;
  private customersOpts = [];
  public isLoading: boolean = true;
  private projectCustomerName = null;

  constructor(
    private projectsSrv: ProjectsService,
    private customersSrv: CustomersService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}

  ngOnInit() {
    this.ui.toolbar.title = 'Nuevo Proyecto';
    this.project = this.projectsSrv.create();
    this.fetch();
  }

  fetch() {
    this.customersSrv
      .customersNoPagination()
      .then(
        (customerCollection) =>
          (this.customersOpts = customerCollection.asOptions('id', 'name', {
            orderBy: 'name',
          }))
      )
      .then(() => (this.isLoading = false));
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.saveProject();
  }

  saveProject() {
    this.projectsSrv.save(this.project).then((project) => {
      this.ui.snackbar.show({
        message: 'Proyecto creado!',
      });
      this.router.navigate(['/tasks/projects/' + project.id]);
    });
  }

  projectCustomerChangeHandler(id: number) {
    this.project.customer_id = id;
  }
}

import { isFunction } from 'lodash';
import { arrayToObject, objectFirstChild, objectToArray, orderArrayBy, stringKeyValue } from './helpers';

export default class Collection<T = any> {
  private items: Record<string, T>;
  private keyName = 'id';
  constructor(initialItems?) {
    this.items = initialItems || {};
  }
  public [Symbol.iterator]() {
    const keys = Object.keys(this.items);
    let index = 0;
    return {
      next: () => {
        const value = this.items[keys[index]];
        const done = index >= keys.length;
        index++;
        return { value, done };
      },
    };
  }
  public first() {
    return objectFirstChild(this.items);
  }
  public find(itemId: any) {
    return this.items[itemId];
  }
  public set(newItems: any[]) {
    this.items = arrayToObject(newItems) as any;
    return this;
  }
  public append(newItems: any[]) {
    Object.assign(this.items, arrayToObject(newItems));
    return this;
  }
  public addOrReplace(newItem: Object) {
    const existingItem = this.items[newItem[this.keyName]];
    if (existingItem) {
      Object.assign(existingItem, newItem);
    } else {
      this.items[newItem[this.keyName]] = newItem as any;
    }
    return this;
  }
  public remove(itemId) {
    if (this.items[itemId]) {
      delete this.items[itemId];
    }
  }
  public filter(filterFn: Function) {
    const filteredCollectionItems = Object.keys(this.items).reduce((acc, key) => {
      if (filterFn(this.items[key])) {
        acc[key] = this.items[key];
      }
      return acc;
    }, {});
    return new Collection(filteredCollectionItems);
  }
  public asArray(options?): any[] {
    let retArray = objectToArray(this.items);
    if (options) {
      if (options.filter) {
        retArray = retArray.filter(options.filterBy);
      }
      if (options.orderBy) {
        orderArrayBy(retArray, options.orderBy);
      }
    }
    return retArray;
  }
  public asOptions(valueKey: any, labelKey: any, options?): any[] {
    const retArray = this.asArray(options);
    return retArray.map((item) => {
      return {
        value: isFunction(valueKey) ? valueKey(item) : stringKeyValue(item, valueKey),
        label: isFunction(labelKey) ? labelKey(item) : stringKeyValue(item, labelKey),
      };
    });
  }
  public asObjectOptions(valueKey: any, options?): any[] {
    const retArray = this.asArray(options);
    return retArray.map((item) => {
      return {
        value: isFunction(valueKey) ? valueKey(item) : stringKeyValue(item, valueKey),
        item,
      };
    });
  }
  public isEmpty(): boolean {
    return !Object.keys(this.items).length;
  }
}

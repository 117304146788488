import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';

import { OrdersAppRoutesModule } from './orders-app-routes.module';

import { OrdersAppComponent } from './orders-app.component';

import { ProductsIndexComponent } from './products/products-index.component';
import { ProductsShowComponent } from './products/products-show.component';
import { ProductsCreateOrEditComponent } from './products/products-create-or-edit.component';
import { ConditionsIndexComponent } from './conditions/conditions-index.component';
import { ConditionsShowComponent } from './conditions/conditions-show.component';
import { ConditionsCreateComponent } from './conditions/conditions-create.component';
import { ConditionsEditComponent } from './conditions/conditions-edit.component';
import { PaymentConditionsIndexComponent } from './paymentconditions/paymentconditions-index.component';
import { PaymentConditionsShowComponent } from './paymentconditions/paymentconditions-show.component';
import { PaymentConditionsCreateComponent } from './paymentconditions/paymentconditions-create.component';
import { PaymentConditionsEditComponent } from './paymentconditions/paymentconditions-edit.component';
import { OrdersIndexComponent } from './orders/orders-index.component';
import { OrdersShowComponent } from './orders/orders-show.component';
import { OrdersCreateComponent } from './orders/orders-create.component';
import { OrdersEditComponent } from './orders/orders-edit.component';
import { ItemDialogComponent } from './products/item-dialog/item-dialog.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, UiModule, OrdersAppRoutesModule],
  declarations: [
    OrdersAppComponent,
    ProductsIndexComponent,
    ProductsShowComponent,
    ProductsCreateOrEditComponent,
    ConditionsIndexComponent,
    ConditionsShowComponent,
    ConditionsCreateComponent,
    ConditionsEditComponent,
    PaymentConditionsIndexComponent,
    PaymentConditionsShowComponent,
    PaymentConditionsCreateComponent,
    PaymentConditionsEditComponent,
    OrdersIndexComponent,
    OrdersShowComponent,
    OrdersCreateComponent,
    OrdersEditComponent,
    ItemDialogComponent,
  ],
})
export class OrdersAppModule {}

import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../core/services/users.service';
import { Router } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'users-index',
  templateUrl: './users-index.template.html',
})
export class UsersIndexComponent implements OnInit {
  public users;
  public currentUserRole = null;
  constructor(
    private usersSrv: UsersService,
    private auth: AuthService,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.currentUserRole = this.auth.getCurrentRole();
    this.ui.toolbar.title = 'Usuarios';
    this.usersSrv.fetch().then((collection) => (this.users = collection.asArray()));
  }
  goTo(url) {
    this.router.navigate([url]);
  }
}

export default function BooleanFieldValue() {
  return function booleanFieldValueMetadata(target: any, key: string): void {
    const defaultValue = target[key];
    const localKey = `__md_private_symbol_${key}`;
    target[localKey] = defaultValue;
    Object.defineProperty(target, key, {
      get() { return (this as any)[localKey]; },
      set(value: boolean) {
        (this as any)[localKey] = value != null && `${value}` !== "false";
      },
    });
  };
}

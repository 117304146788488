import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UiInternalService } from './shared/ui-internal.service';

import { UiAppComponent } from './ui-app/ui-app.component';
import { UiInputComponent } from './ui-input/ui-input.component';
import { UiCalendarComponent } from './ui-calendar/ui-calendar.component';
import { UiDatepickerComponent } from './ui-datepicker/ui-datepicker.component';
import { UiDropdownComponent } from './ui-dropdown/ui-dropdown.component';
import { UiEditableCellComponent } from './ui-editable-cell/ui-editable-cell.component';
import { UiSelectComponent } from './ui-select/ui-select.component';
import { UiToolbarComponent } from './ui-toolbar/ui-toolbar.component';
import { UiMenuComponent } from './ui-menu/ui-menu.component';
import { UiMoreMenuComponent } from './ui-more-menu/ui-more-menu.component';
import { UiSidebarComponent } from './ui-sidebar/ui-sidebar.component';
import { UiBtnComponent } from './ui-btn/ui-btn.component';
import { UiModalComponent } from './ui-modal/ui-modal.component';
import { UiSpinnerComponent } from './ui-spinner/ui-spinner.component';
import { UiSnackbarComponent } from './ui-snackbar/ui-snackbar.component';
import { UiCheckComponent } from './ui-check/ui-check.component';
import { UiUserActionsComponent } from './ui-user-actions/ui-user-actions.component';
import { UiAutocompleteComponent } from './ui-autocomplete/ui-autocomplete.component';
import { UiAutocompleteAsyncComponent } from './ui-autocomplete-async/ui-autocomplete-async.component';
import { UiFieldDisplayComponent } from './ui-field-display/ui-field-display.component';
import { UiFileUploaderComponent } from './ui-file-uploader/ui-file-uploader.component';
import { UiFileLoaderComponent } from './ui-file-loader/ui-file-loader.component';
import { UiAttachmentIconComponent } from './ui-attachment-icon/ui-attachment-icon.component';
import { UiDropAreaComponent } from './ui-drop-area/ui-drop-area.component';
import { UiAlertComponent } from './ui-alert/ui-alert.component';
import { UiAppSelectorComponent } from './ui-app-selector/ui-app-selector.component';
import { UiBadgeComponent } from './ui-badge/ui-badge.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [
    UiAppComponent,
    UiInputComponent,
    UiEditableCellComponent,
    UiSelectComponent,
    UiToolbarComponent,
    UiMenuComponent,
    UiMoreMenuComponent,
    UiSidebarComponent,
    UiBtnComponent,
    UiModalComponent,
    UiSpinnerComponent,
    UiSnackbarComponent,
    UiCheckComponent,
    UiUserActionsComponent,
    UiDatepickerComponent,
    UiCalendarComponent,
    UiDropdownComponent,
    UiAutocompleteComponent,
    UiAutocompleteAsyncComponent,
    UiFieldDisplayComponent,
    UiFileUploaderComponent,
    UiFileLoaderComponent,
    UiAttachmentIconComponent,
    UiDropAreaComponent,
    UiAlertComponent,
    UiAppSelectorComponent,
    UiBadgeComponent,
  ],
  exports: [
    UiAppComponent,
    UiInputComponent,
    UiEditableCellComponent,
    UiSelectComponent,
    UiToolbarComponent,
    UiMenuComponent,
    UiMoreMenuComponent,
    UiSidebarComponent,
    UiBtnComponent,
    UiModalComponent,
    UiSpinnerComponent,
    UiSnackbarComponent,
    UiCheckComponent,
    UiUserActionsComponent,
    UiDatepickerComponent,
    UiCalendarComponent,
    UiDropdownComponent,
    UiAutocompleteComponent,
    UiAutocompleteAsyncComponent,
    UiFieldDisplayComponent,
    UiFileUploaderComponent,
    UiFileLoaderComponent,
    UiAttachmentIconComponent,
    UiDropAreaComponent,
    UiAlertComponent,
    UiAppSelectorComponent,
    UiBadgeComponent,
  ],
  providers: [UiInternalService],
})
export class UiModule {}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'usergroups',
  templateUrl: 'usergroups.template.html',
})
export class UserGroupsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}

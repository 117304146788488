<form #projectForm="ngForm" (submit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="project && !isLoading">
    <h1 class="text-center" [class.color-disabled]="!project.name">
      {{ project.name || 'Agrega un nombre de proyecto'}}
    </h1>
    <ui-input label="Nombre" name="project_name" [(ngModel)]="project.name" placeholder="Proyecto de ejemplo" required>
    </ui-input>
    <div class="ui-form-group one-half">
      <ui-datepicker label="Vence el" name="project_due_date" [(ngModel)]="project.due_date">
      </ui-datepicker>
      <ui-autocomplete-async name="project_customer_id" label="Cliente" model="customer"
        [(ngModel)]="projectCustomerName" placeholder="Selecciona un cliente de la lista"
        (uiModelChange)="projectCustomerChangeHandler($event)" required>
      </ui-autocomplete-async>
    </div>
    <ui-input type="textarea" label="Detalles" name="project_summary" [(ngModel)]="project.summary"
      placeholder="Añade los detalles de tu proyecto aquí">
    </ui-input>
  </div>

  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="saveProject()" hint="Guardar proyecto" [disabled]="!projectForm.form.valid"
        icon="save">
      </ui-btn>
    </div>
  </div>
</form>
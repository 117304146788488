<div class="signin">
  <div class="signin__content">
    <h1 class="beta">Conectarse a OnTask</h1>
    <div>
      <p class="delta">
        <ui-btn
          (uiClick)="signinHandler($event)"
          color="plain"
          textRaw
          image="assets/images/google-logo.svg"
        >
          Inicia Sesión con Google
        </ui-btn>
      </p>
      <p>- o -</p>
      <p>Digita tu email y contraseña</p>
      <form (submit)="signInWithPassword($event)">
        <ui-input
          [(ngModel)]="user.email"
          name="user_email"
          placeholder="Email"
          icon="person"
        ></ui-input>
        <ui-input
          [(ngModel)]="user.password"
          type="password"
          name="user_password"
          placeholder="Password"
          icon="key"
        ></ui-input>
        <ui-btn type="submit">Iniciar Sesión</ui-btn>
        <ui-btn type="button" (uiClick)="signUpButtonHandler()" class="margin-left" flat>
          Registrarse
        </ui-btn>
      </form>
      <!-- <ui-btn (uiClick)="signoutHandler($event)" flat>
        SIGN OUT
      </ui-btn> -->
    </div>
  </div>
</div>

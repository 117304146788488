import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';
import { loadFiles } from '../shared/helpers';

@Component({
  selector: 'ui-drop-area',
  templateUrl: 'ui-drop-area.template.html',
  styleUrls: ['ui-drop-area.styles.scss'],
})
export class UiDropAreaComponent {
  @Output() uiDrop = new EventEmitter();
  @Input() @BooleanFieldValue() returnAllEvents: boolean = false;
  @Input() @BooleanFieldValue() dragAllowed: boolean = true;
  public isDragging: boolean = false;

  constructor() {}

  dragenterHandler($event) {
    $event.preventDefault();
  }
  dragoverHandler($event) {
    $event.preventDefault();
    this.isDragging = true;
  }
  dragleaveHandler($event) {
    $event.preventDefault();
    this.isDragging = false;
  }
  dropHandler($event: DragEvent) {
    $event.preventDefault();
    this.isDragging = false;

    if (this.dragAllowed) {
      if (this.returnAllEvents) {
        this.uiDrop.emit($event);
      } else {
        loadFiles($event.dataTransfer.files).then((loadedFiles) =>
          this.uiDrop.emit(loadedFiles)
        );
      }
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';

import { TasksAppRoutesModule } from './tasks-app-routes.module';

import { TasksAppComponent } from './tasks-app.component';

import { DashboardComponent } from './dashboard/dashboard.component';

import { ProjectsComponent } from './projects/projects.component';
import { ProjectsIndexComponent } from './projects/projects-index.component';
import { ProjectsShowComponent } from './projects/projects-show.component';
import { ProjectsCreateComponent } from './projects/projects-create.component';
import { ProjectsEditComponent } from './projects/projects-edit.component';

import { TasksComponent } from './tasks/tasks.component';
import { TaskCollectionComponent } from './tasks/task-collection.component';
import { CostCollectionComponent } from './projects/cost-collection.component';
import { TasksIndexComponent } from './tasks/tasks-index.component';
import { TasksShowComponent } from './tasks/tasks-show.component';
import { TasksCreateComponent } from './tasks/tasks-create.component';
import { TasksEditComponent } from './tasks/tasks-edit.component';
import { TaskCheckComponent } from './tasks/task-check.component';
import { TasksMyTasksComponent } from './tasks/tasks-mytasks.component';

import { CustomersComponent } from './customers/customers.component';
import { CustomersIndexComponent } from './customers/customers-index.component';
import { CustomersShowComponent } from './customers/customers-show.component';
import { CustomersCreateComponent } from './customers/customers-create.component';
import { CustomersEditComponent } from './customers/customers-edit.component';

import { UsersComponent } from './users/users.component';
import { UsersIndexComponent } from './users/users-index.component';
import { UsersShowComponent } from './users/users-show.component';
import { UsersCreateComponent } from './users/users-create.component';
import { UsersEditComponent } from './users/users-edit.component';

import { UserGroupsComponent } from './usergroups/usergroups.component';
import { UserGroupsIndexComponent } from './usergroups/usergroups-index.component';
import { UserGroupsShowComponent } from './usergroups/usergroups-show.component';
import { UserGroupsCreateComponent } from './usergroups/usergroups-create.component';
import { UserGroupsEditComponent } from './usergroups/usergroups-edit.component';

import { SuppliersComponent } from './suppliers/suppliers.component';
import { SuppliersIndexComponent } from './suppliers/suppliers-index.component';
import { SuppliersShowComponent } from './suppliers/suppliers-show.component';
import { SuppliersCreateComponent } from './suppliers/suppliers-create.component';
import { SuppliersEditComponent } from './suppliers/suppliers-edit.component';

import { AttachmentCollectionComponent } from './attachments/attachment-collection.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    UiModule,
    TasksAppRoutesModule
  ],
  declarations: [
    TasksAppComponent,
    DashboardComponent,
    ProjectsComponent,
    ProjectsIndexComponent,
    ProjectsShowComponent,
    ProjectsCreateComponent,
    ProjectsEditComponent,
    TasksComponent,
    TasksIndexComponent,
    TasksShowComponent,
    TasksCreateComponent,
    TasksEditComponent,
    TaskCollectionComponent,
    CostCollectionComponent,
    TaskCheckComponent,
    TasksMyTasksComponent,
    AttachmentCollectionComponent,
    CustomersComponent,
    CustomersIndexComponent,
    CustomersShowComponent,
    CustomersCreateComponent,
    CustomersEditComponent,
    UsersComponent,
    UsersIndexComponent,
    UsersShowComponent,
    UsersCreateComponent,
    UsersEditComponent,
    UserGroupsComponent,
    UserGroupsIndexComponent,
    UserGroupsShowComponent,
    UserGroupsCreateComponent,
    UserGroupsEditComponent,
    SuppliersComponent,
    SuppliersIndexComponent,
    SuppliersShowComponent,
    SuppliersCreateComponent,
    SuppliersEditComponent,
  ],
  exports: [
    TasksAppComponent,
    DashboardComponent,
    ProjectsComponent,
    ProjectsIndexComponent,
    ProjectsShowComponent,
    ProjectsCreateComponent,
    ProjectsEditComponent,
    TasksComponent,
    TasksIndexComponent,
    TasksShowComponent,
    TasksCreateComponent,
    TasksEditComponent,
    TaskCollectionComponent,
    CostCollectionComponent,
    TaskCheckComponent,
    TasksMyTasksComponent,
    AttachmentCollectionComponent,
    CustomersComponent,
    CustomersIndexComponent,
    CustomersShowComponent,
    CustomersCreateComponent,
    CustomersEditComponent,
    UsersComponent,
    UsersIndexComponent,
    UsersShowComponent,
    UsersCreateComponent,
    UsersEditComponent,
    UserGroupsComponent,
    UserGroupsIndexComponent,
    UserGroupsShowComponent,
    UserGroupsCreateComponent,
    UserGroupsEditComponent,
    SuppliersComponent,
    SuppliersIndexComponent,
    SuppliersShowComponent,
    SuppliersCreateComponent,
    SuppliersEditComponent,
  ],
  providers: []
})
export class TasksAppModule { }

import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class CustomersService extends ApiService {
  private baseCustomer = {
    name: '',
    identification_type: '',
    identification: '',
    email: '',
    phone: '',
    person: {
      first_name: '',
      last_name: '',
    },
  };
  idTypes = [
    {
      value: 'CC',
      label: 'Cédula de ciudadanía',
    },
    {
      value: 'TE',
      label: 'Tarjeta de extranjería',
    },
    {
      value: 'Nit',
      label: 'NIT',
    },
    {
      value: 'PSP',
      label: 'Pasaporte',
    },
    {
      value: 'OTRO',
      label: 'Otro',
    },
  ];

  //List of customer sources
  custSources = [
    {
      value: 'Redes sociales',
      label: 'Redes sociales',
    },
    {
      value: 'Cliente antiguo',
      label: 'Cliente antiguo',
    },
    {
      value: 'Sitio web',
      label: 'Sitio web',
    },
    {
      value: 'Referido',
      label: 'Referido',
    },
    {
      value: 'Otro',
      label: 'Otro',
    },
  ];
  constructor(private http: HttpX, private auth: AuthService) {
    super('customer', 'customers', http, auth);
  }
  create(opts = {}) {
    return {
      ...this.baseCustomer,
      ...opts,
    };
  }

  customersNoPagination() {
    return this.fetch(`?pagin=false`);
  }

  fetch(extras?: string): Promise<any> {
    return super.fetch(extras);
  }
}

<div class="ui-panel  no-padding">
  <table class="ui-table">
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Descripción</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let g of userGroups" (click)="goTo('/tasks/usergroups/' + g.id )">
        <td>{{ g.name }}</td>
        <td>{{ g.summary }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="createUserGroupRedirect()"
      hint="Crear grupo" icon="add">
    </ui-btn>
  </div>
</div>

<div *ngIf="userGroup">
  <div class="ui-panel">
    <h1 class="text-center">{{ userGroup.name }}</h1>
    <ui-field-display label="Descripción" [value]="userGroup.summary"></ui-field-display>
  </div>
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-person"></span>
    Personas
  </h3>
  <p class="ui-panel__empty-state" *ngIf="!userGroup.users.length">
    Este grupo no tiene personas asociadas.
  </p>
  <div class="ui-panel no-border no-padding" *ngIf="userGroup.users.length">
    <table class="ui-table">
      <thead>
        <tr>
          <th></th>
          <th>Nombre</th>
          <th>Correo</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let u of userGroup.users" (click)="goTo('/tasks/users/' + u.id )">
          <td><img *ngIf="u.person?.avatar_url" [src]="u.person?.avatar_url"></td>
          <td>{{ u.person.nickname ? u.person.nickname : u.person?.first_name+' '+u.person?.last_name }}</td>
          <td>{{ u.email }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-task"></span>
    Tareas
  </h3>
  <p class="ui-panel__empty-state" *ngIf="!userGroup.tasks.length">
    Este grupo no tiene tareas asociadas.
  </p>
  <div class="ui-panel no-border no-padding" *ngIf="userGroup.tasks.length">
    <task-collection hideColumns="group" [tasks]="userGroup.tasks | filterBy : filters" orderOnUpdate orderByDueDate>
    </task-collection>
  </div>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__secondary">
    <ui-btn *ngIf="filtered_by == 'active'" fab color="success" (uiClick)="disableFilterBy()"
      hint="Ver todas las tareas" icon="list-check">
    </ui-btn>
    <ui-btn *ngIf="filtered_by == 'all'" fab color="warning" (uiClick)="filterByStatus()"
      hint="Ver solo tareas pendientes" icon="list">
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="editUserGroupRedirect()" hint="Editar grupo" icon="edit">
    </ui-btn>
  </div>
</div>
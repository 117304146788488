import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { isFunction, isUndefined } from "lodash";
import { UiInternalService } from "../shared/ui-internal.service";

@Component({
  selector: "ui-sidebar",
  templateUrl: "ui-sidebar.template.html",
  styleUrls: ["ui-sidebar.styles.scss"],
})
export class UiSidebarComponent {
  public isOpen: boolean = false;
  // @Input() theme: string = "light";
  // @Input() title: string = "My app";
  // @Output() isOpenChange = new EventEmitter();

  // close() {
  //   this.isOpenChange.emit(false);
  // }

  private router: Router = null;
  private actions: any[] = [];
  private subActions: any[] = [];

  constructor(private ui: UiInternalService) {}
  public toggle(newStatus?: boolean) {
    if (newStatus) {
      this.isOpen = newStatus;
    } else {
      this.isOpen = !this.isOpen;
    }
    this.ui.sidebar.isOpen = this.isOpen;
  }
  public setRouter(newRouter: Router) {
    this.router = newRouter;
  }
  public setSubActions(newSubActions: any[]) {
    this.subActions = newSubActions;
  }
  public setActions(newActions: any[]) {
    this.actions = newActions;
  }
  public goTo(url: string) {
    this.router.navigate([url]);
  }
  public clickItemHandler(evt, action) {
    evt.preventDefault();
    if (action.action) { action.action(); }
    if (action.url && this.router) { this.router.navigate([action.url]); }
  }
  public visibleActions() {
    if (!this.actions.length) { return this.actions; }
    return this.actions.filter((action) => {
      return isFunction(action.hide) ? !action.hide() : !action.hide;
    });
  }
  public actionIsActive(action) {
    if (!action || !this.router) { return false; }
    if (!isUndefined(action.isActive)) {
      return isFunction(action.isActive) ? action.isActive() : action.isActive;
    }
    return action.url === "/" + this.router.url.split("/")[1];
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'task-check',
  templateUrl: 'task-check.template.html',
  styleUrls: ['task-check.styles.scss'],
})
export class TaskCheckComponent {
  @Input() status: string;
  @Output() statusChange = new EventEmitter();

  constructor() {}

  toggleStatus() {
    this.statusChange.emit(this.status == 'done' ? 'active' : 'done');
  }
}

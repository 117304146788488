<div class="ui-app-selector" *ngIf="userData">
  <div class="ui-app-header" (click)="openAppMenu($event)">
    <button class="ui-app-selector__btn">
      <span class="icon icon-apps"></span>
    </button>
  </div>

  <div #menuEl class="ui-app-menu" [class.is-open]="appMenuOpen">
    <div class="ui-app-menu__padding">
      <div class="ui-app-menu__content">
        <a href="/#/tasks/dashboard" class="ui-app-menu__item" (click)="clickActionHandler($event, a)">
          <span class="icon icon-task"></span>
          <span class="ui-app-menu__item-label"> Tareas </span>
        </a>
        <a href="/#/orders/orders" class="ui-app-menu__item" (click)="clickActionHandler($event, a)">
          <span class="icon icon-orders"></span>
          <span class="ui-app-menu__item-label"> Órdenes </span>
        </a>
      </div>
    </div>
  </div>
</div>

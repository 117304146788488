import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class ConditionsService extends ApiService {
  private baseCondition = {
    name: '',
    status: '',
    registered_by_id: null,
    summary: '',
  };
  constructor(private http: HttpX, private auth: AuthService) {
    super('condition', 'conditions', http, auth);
  }
  create(opts = {}) {
    return {
      ...this.baseCondition,
      ...opts,
    };
  }
}

import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class ProjectsService extends ApiService {
  private baseProject = {
    name: '',
    code: '',
    status: '',
    due_date: null,
    customer_id: null,
    registered_by_id: null,
    finished_by_id: null,
    summary: '',
  };
  constructor(private http: HttpX, private auth: AuthService) {
    super('project', 'projects', http, auth);
  }
  updateStatus(project, status) {
    project.status = status;
    if (status === 'finished') {
      project.finished_by_id = this.auth.getCurrentUser().id;
    } else {
      project.finished_by_id = null;
    }
    return this.save(project);
  }
  create(opts = {}) {
    return {
      ...this.baseProject,
      ...opts,
    };
  }
  addOrder(projectId, orderId) {
    return this.post(`${projectId}/`, 'order', { orderId });
  }
  projectsNoPagination() {
    return this.fetch(`?pagin=false`);
  }
}

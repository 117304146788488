import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../core/services/products.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'products-create',
  templateUrl: 'products-create-edit.template.html',
})
export class ProductsCreateOrEditComponent implements OnInit {
  public product;
  private edit: boolean = false;
  private des: boolean = false;
  private priceLockedOptions = ProductsService.priceLockedOptions;
  private currentUserRole = null;

  constructor(
    private auth: AuthService,
    private productsSrv: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}

  ngOnInit() {
    this.currentUserRole = this.auth.getCurrentRole();
    let productId = this.route.snapshot.params['id'];
    if (productId) {
      this.edit = true;
      this.ui.toolbar.title = 'Editar Producto';
      this.productsSrv.find(productId).then(
        (product) =>
          (this.product = {
            ...product,
            status: product.status === 'active',
            base_price: parseFloat(product.base_price),
            base_cost: parseFloat(product.base_cost),
            profit_rate: parseFloat(product.profit_rate),
            maximum_quantity: parseFloat(product.maximum_quantity),
            minimum_quantity: parseFloat(product.minimum_quantity),
          })
      );
    } else {
      this.ui.toolbar.title = 'Nuevo Producto';
      this.product = this.productsSrv.create();
    }
  }

  isAuthorized() {
    return this.currentUserRole == 'admin' || this.currentUserRole == 'comercial';
  }

  productBaseCostChangeHandler() {
    if (this.des && this.product.base_cost) {
      this.product.base_price = Math.round(this.product.base_cost * this.product.profit_rate);
    } else {
      if (this.product.profit_rate) {
        this.product.base_price = Math.round(this.product.base_cost * this.product.profit_rate);
        this.des = false;
      }
    }
  }

  productProfitRateChangeHandler() {
    if (this.des && this.product.base_price) {
      this.product.base_price = Math.round(this.product.base_cost * this.product.profit_rate);
    } else {
      if (this.product.profit_rate) {
        this.product.base_price = Math.round(this.product.base_cost * this.product.profit_rate);
        this.des = false;
      }
    }
  }

  productBasePriceChangeHandler() {
    if (this.des && this.product.profit_rate) {
      this.product.base_cost = this.product.base_price / this.product.profit_rate;
    } else {
      if (this.product.base_cost) {
        this.product.profit_rate = (this.product.base_price / this.product.base_cost).toFixed(2);
        this.des = false;
      }
    }
  }

  productBaseCostFocusHandler() {
    this.des = true;
  }

  productProfitRateFocusHandler() {
    this.des = true;
  }

  productBasePriceFocusHandler() {
    this.des = true;
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.saveProduct();
  }

  saveProduct() {
    if (!this.verfifyQuantity()) {
      this.productsSrv.save(this.product).then((product) => {
        this.ui.snackbar.show({
          message: this.edit ? 'Producto modificado!' : 'Producto creado!',
        });
        this.router.navigate(['/orders/products/' + product.id]);
      });
    }
  }

  verfifyQuantity() {
    let verification =
      this.product.maximum_quantity != 0 &&
      this.product.minimum_quantity != 0 &&
      this.product.maximum_quantity < this.product.minimum_quantity;
    if (verification) {
      this.ui.snackbar.show('Cantidad máxima debe ser mayor a la mínima');
    }
    return verification;
  }
}

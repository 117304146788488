<div *ngIf="product">
  <div class="ui-panel">
    <h1 class="text-center">{{ product.name }}</h1>
    <ui-field-display label="Descripción" [value]="product.description"></ui-field-display>
    <div class="ui-field-group one-half">
      <ui-field-display label="Registrado por" *ngIf="product.registered_by"
        [value]="product.registered_by?.person.first_name + ' ' + product.registered_by?.person.last_name"
        (click)="goTo('/tasks/users/' + product.registered_by?.id )" clickable>
      </ui-field-display>
      <ui-field-display label="Costo" [value]="this.showFixedNumber(product.base_cost) | number"></ui-field-display>
      <ui-field-display label="Porcentaje de ganancia" [value]="product.profit_rate | percent"></ui-field-display>
      <ui-field-display label="Precio" [value]="this.showFixedNumber(product.base_price) | number"></ui-field-display>
      <ui-field-display label="Cantidad máxima" [value]="product.maximum_quantity | number"></ui-field-display>
      <ui-field-display label="Cantidad mínima" [value]="product.minimum_quantity | number"></ui-field-display>
      <ui-field-display label="Activo" [value]="isActive"></ui-field-display>
      <ui-field-display label="Precio bloqueado" [value]="lockedPrice"></ui-field-display>
      <ui-field-display label="Genérico" [value]="isGeneric"></ui-field-display>
    </div>
  </div>
</div>

<div class="ui-speed-dial" *ngIf="product">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/orders/products/' + (product?.id || 0) + '/edit')"
      hint="Editar producto" icon="edit">
    </ui-btn>
  </div>
</div>

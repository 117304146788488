import { Component, OnInit } from '@angular/core';
import { TasksService } from '../../core/services/tasks.service';
import { Router } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { HttpX } from '../../core/services/http-x.service';
import { logger } from '../../shared/logger.class';
import { API_URL } from '../../shared/constants';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.template.html',
})
export class DashboardComponent implements OnInit {
  private tasks;
  private order;
  private orderCriteria;
  private orderCritOpts = [];
  private orderOpts = [];
  private filters = {};
  private filtered_by;
  public dashboardgroup;
  public dashboardpeople;

  constructor(private tasksSrv: TasksService, private router: Router, private ui: UiService, private http: HttpX) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Resumen';

    this.tasksSrv.fetch().then((tasks) => (this.tasks = tasks.collection.asArray()));
    this.order = 'project.customer.name';
    this.orderCriteria = 'asc';
    this.orderCritOpts = [
      { value: 'asc', label: 'Ascendente' },
      { value: 'desc', label: 'Descendente' },
    ];
    this.orderOpts = [
      { value: 'project.customer.name', label: 'Cliente' },
      { value: 'project.name', label: 'Proyecto' },
      { value: 'name', label: 'Tarea' },
      { value: 'due_date', label: 'Vencimiento' },
      { value: 'status', label: 'Estado' },
    ];
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
    this.http.get(API_URL + 'dashboard/people').then((response) => {
      //console.log(response.payload);
      this.dashboardpeople = response.payload;
    });
    this.http.get(API_URL + 'dashboard/group').then((response) => {
      //console.log(response.payload);
      this.dashboardgroup = response.payload;
    });
    //this.http.get('http://localhost:3000/api/dashboard/group').toPromise().then(response => {
    //collection => this.dashboardgroup = collection.asArray()
    //logger.warn('dashboard: ' + this.dashboardgroup + ' being fetched. returning stored promise.');
    //});
  }
  goTo(url) {
    this.router.navigate([url]);
  }
  filterByStatus() {
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
  }
  disableFilterBy() {
    this.filters = {};
    this.filtered_by = 'all';
  }
}

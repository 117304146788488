import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class CommentsService extends ApiService {
  private baseCondition = {
    comment: '',
    commentable_type: null,
    commentable_id: null,
    user_id: null
  };
  constructor(
    private http: HttpX,
    private auth: AuthService
  ) {
    super('comments', 'comments', http, auth);
  }
  create(opts = {}) {
    return {
      ...this.baseCondition,
      ...opts
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TaskStatus } from '../enums/task-status.enum';

@Pipe({
  name: 'taskStatus',
})
export class TaskStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case TaskStatus.ACTIVE:
        return 'Activa';
      case TaskStatus.FINISHED:
        return 'Finalizada';
      default:
        break;
    }
  }
}

<ui-modal [(isOpen)]="isOpen" modalWidth="medium">
  <h2 *ngIf="params.title">{{ params.title }}</h2>
  <p *ngIf="params.message">{{ params.message }}</p>
  <div *ngIf="!params.hideButtons" class="text-end margin-top">
    <ui-btn *ngIf="!params.hideCancel" (uiClick)="cancelHandler()" flat notab>
      {{ params.cancelText || "Cancelar" }}
    </ui-btn>
    <ui-btn *ngIf="params.showNo" (uiClick)="noHandler()">
      {{ params.noText || "No" }}
    </ui-btn>
    <ui-btn *ngIf="!params.hideOk" (uiClick)="okHandler()">
      {{ params.okText || "Ok" }}
    </ui-btn>
  </div>
</ui-modal>

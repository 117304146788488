import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class ProductsService extends ApiService {
  static priceLockedOptions = [
    { value: 'true', label: 'Precio bloqueado' },
    { value: 'false', label: 'Precio desbloqueado' },
  ];
  private baseProduct = {
    name: '',
    code: '',
    registered_by_id: null,
    description: '',
    status: true,
    generic: false,
    price_locked: false,
  };
  constructor(private http: HttpX, private auth: AuthService) {
    super('product', 'products', http, auth);
  }

  create(opts = {}) {
    return {
      ...this.baseProduct,
      ...opts,
    };
  }

  productsNoPagination() {
    return this.fetch(`?q[status_eq]=active&pagin=false`);
  }
}

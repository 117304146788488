<div class="ui-flex-table">
  <div>
    <div class="ui-flex-table__header-row">
      <span [style.order]="orderObj.status || 99" class="cell--tiny" *ngIf="!hideObj.status"></span>
      <span [style.order]="orderObj.user || 99" class="cell--tiny" *ngIf="!hideObj.user"></span>
      <span [style.order]="orderObj.name || 99" class="cell--large" *ngIf="!hideObj.name">Tarea</span>
      <span [style.order]="orderObj.project || 99" class="cell--medium" *ngIf="!hideObj.project">Proyecto</span>
      <span [style.order]="orderObj.customer || 99" class="cell--medium" *ngIf="!hideObj.customer">Cliente</span>
      <span [style.order]="orderObj.group || 99" class="cell--medium" *ngIf="!hideObj.group">Grupo</span>
      <span [style.order]="orderObj.assigned_at || 99" class="cell--med-small" *ngIf="!hideObj.assigned_at"
        >Asignado el</span
      >
      <span [style.order]="orderObj.due_date || 99" class="cell--med-small" *ngIf="!hideObj.due_date">Vence el</span>
      <span [style.order]="orderObj.time || 99" class="cell--small text-end" *ngIf="!hideObj.time">Tmp.</span>
      <span [style.order]="orderObj.actions || 99" class="cell--actions" *ngIf="!hideObj.actions"></span>
    </div>
  </div>
  <div>
    <div
      *ngFor="let t of tasks"
      class="ui-flex-table__row bordered"
      [class.bordered--canceled]="(tasksSrv.taskIsDue(t) == 'canceled')"
      [class.bordered--success]="(tasksSrv.taskIsDue(t) == 'done')"
      [class.bordered--warning]="(tasksSrv.taskIsDue(t) == 'pending')"
      [class.bordered--error]="(tasksSrv.taskIsDue(t) == 'due')"
    >
      <span [style.order]="orderObj.status || 99" class="cell--tiny text-center" *ngIf="!hideObj.status">
        <task-check [status]="t.status" *ngIf="t.status !=='canceled'" (statusChange)="updateTaskStatus(t, $event)">
        </task-check>
      </span>
      <span [style.order]="orderObj.user || 99" class="cell--clickable cell--tiny text-center" *ngIf="!hideObj.user">
        <img
          *ngIf="t.user?.person?.avatar_url"
          [src]="t.user?.person?.avatar_url"
          [title]="t.user.person.nickname ? t.user.person.nickname : t.user?.person?.first_name + ' ' + t.user?.person?.last_name"
          (click)="goTo('/users/' + t.user?.id )"
        />
      </span>
      <span
        [style.order]="orderObj.name || 99"
        class="cell--clickable cell--large"
        *ngIf="!hideObj.name"
        (click)="goTo('/tasks/' + t.id )"
        [title]="t.name"
      >
        {{ t.name }}
      </span>
      <span
        [style.order]="orderObj.project || 99"
        class="cell--clickable cell--medium"
        *ngIf="!hideObj.project"
        (click)="goTo('/projects/' + t.project?.id )"
        [title]="t.project?.name"
      >
        {{ t.project?.name }}
      </span>
      <span
        [style.order]="orderObj.customer || 99"
        class="cell--clickable cell--medium"
        *ngIf="!hideObj.customer"
        (click)="goTo('/customers/' + t.project?.customer?.id )"
        [title]="t.project?.customer?.name"
      >
        {{ t.project?.customer?.name }}
      </span>
      <span
        [style.order]="orderObj.group || 99"
        class="cell--clickable cell--medium"
        *ngIf="!hideObj.group"
        (click)="goTo('/usergroups/' + t.user_group?.id )"
      >
        {{ t.user_group?.name }}
      </span>
      <span [style.order]="orderObj.assigned_at || 99" class="cell--med-small" *ngIf="!hideObj.assigned_at">
        {{ t.assigned_at | date: 'dd/MM/yyyy' }}
      </span>
      <span [style.order]="orderObj.due_date || 99" class="cell--med-small" *ngIf="!hideObj.due_date">
        {{ t.due_date | date: 'dd/MM/yyyy' }}
      </span>
      <span [style.order]="orderObj.time || 99" class="cell--small text-end" *ngIf="!hideObj.time">
        {{ t.user ? (tasksSrv.elapsedTime(t) + " d") : "---" }}
      </span>
      <span [style.order]="orderObj.actions || 99" class="cell--actions" *ngIf="!hideObj.actions">
        <ui-more-menu [actions]="actionMenu" [params]="t" alignRight icon="more-vert"> </ui-more-menu>
      </span>
    </div>
  </div>
</div>

<div [class]="'ui-form-element ' + (width || '')"
  [class.is-active]="isActive || isHighlighted"
  [class.disabled]="disabled"
  [class.has-errors]="hasErrors()"
  (click)="clickHandler($event)">
  <label #labelEl [for]="elemId"
    (click)="labelClickHandler($event)">
    {{ label }}{{ required ? '*' : '' }}
  </label>
  <!-- [value]="value"
  (input)="inputHandler($event)" -->
  <div class="ui-input">
    <input #inputEl
      #inputModel="ngModel"
      *ngIf="type !== 'textarea'"
      [id]="elemId"
      [attr.name]="name || elemId"
      [type]="type || 'text'"
      [ngModel]="value"
      (ngModelChange)="ngModelChangeHandler($event)"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
      [required]="required"
      [readonly]="readonly"
      [disabled]="disabled"
      [placeholder]="placeholder || ''"
      class="ui-input__element"
    />
    <textarea
      *ngIf="type === 'textarea'"
      #inputModel="ngModel"
      [id]="elemId"
      [attr.name]="name || elemId"
      [ngModel]="value"
      (ngModelChange)="ngModelChangeHandler($event)"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
      [required]="required"
      [readonly]="readonly"
      [disabled]="disabled"
      [placeholder]="placeholder || ''"
      class="ui-input__element"
      >
    </textarea>
    <span *ngIf="icon" (click)="iconClickable && iconClickHandler($event)"
      [class]="'ui-form-element__icon ui-input__icon-right icon-' + icon + (iconClickable ? ' is-clickable' : '')">
    </span>
    <div class="ui-form-element__underline">
      <div class="ui-form-element__highlight"></div>
    </div>
  </div>
  <ng-content></ng-content>
  <div *ngIf="hasErrors()" class="ui-form-element__errors">
    {{ errorMessage }}
  </div>
</div>

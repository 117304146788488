import { Component, OnInit } from '@angular/core';
import { ConditionsService } from '../../core/services/conditions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'conditions-edit',
  templateUrl: 'conditions-create-edit.template.html',
})
export class ConditionsEditComponent implements OnInit {
  public condition;
  constructor(
    private conditionsSrv: ConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Editar Condición';
    var conditionId = this.route.snapshot.params['id'];
    this.conditionsSrv
      .find(conditionId)
      .then((condition) => (this.condition = condition));
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.saveCondition();
  }
  saveCondition() {
    this.conditionsSrv.save(this.condition).then((condition) => {
      this.ui.snackbar.show({
        message: 'Condición modificada!',
      });
      this.router.navigate(['/orders/conditions/' + condition.id]);
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { orderArrayBy } from '../helpers';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(value: Array<Object>, criteria: string): Array<Object> {
    const nullItems = [];
    const array = [];
    value.forEach((item) => {
      if (item[criteria] === null || item[criteria] === undefined) {
        nullItems.push(item);
      } else {
        array.push(item);
      }
    });
    return [...orderArrayBy(array, criteria), ...nullItems];
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus } from '../enums/project-status.enum';

@Pipe({
  name: 'projectStatus'
})
export class ProjectStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case ProjectStatus.ACTIVE:
        return 'Activo';
      case ProjectStatus.FINISHED:
        return 'Finalizado';
      default:
        break;
    }
  }
}
<div class="ui-panel  no-padding">
  <div class="ui-panel__padding">
    <h2>This is the dummies component!</h2>
  </div>
  <table class="ui-table">
    <thead>
      <tr>
        <th>First name</th>
        <th>Last name</th>
        <th>Bio</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let d of dummies" (click)="goTo('/dummies/' + d.id )">
        <td>{{ d.first_name }}</td>
        <td>{{ d.last_name }}</td>
        <td>{{ d.bio }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/dummies/new')" hint="Create a new dummy">
      <span class="icon icon-add"></span>
    </ui-btn>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectsService } from '../../core/services/projects.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '../../core/services/users.service';
import { TasksService } from '../../core/services/tasks.service';
import { OrdersService } from '../../core/services/orders.service';
import { CostsService } from '../../core/services/costs.service';
import { SuppliersService } from '../../core/services/suppliers.service';
import { PaymentConditionsService } from '../../core/services/payment-conditions.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'projects-show',
  templateUrl: 'projects-show.template.html',
  styleUrls: ['projects-show.styles.scss'],
})
export class ProjectsShowComponent implements OnInit {
  @ViewChild('attCollectionEl') attCollectionEl;
  public project;
  private orderProject;
  private costProject;
  private totalCosts = 0;
  private modalOpen = false;
  private modalDialogOpen = false;
  private addOrderProjectModalOpen = false;
  private addCostProjectModalOpen = false;
  private updateCostProjectModalOpen = false;
  private customerOrdersList = false;
  private nuTask = null;
  private usersOpts = [];
  private suppliersOpts = [];
  private customerOrders = [];
  private paymentConditionsOpts = [];
  private isAuthorized = false;
  private currentUserRole = null;
  private filters = {};
  private filtered_by;
  private conditionDialogOpen = null;
  private downloadPDFText = '';
  private taskUser: {
    text: string;
    initialId: number;
  };

  constructor(
    private projectsSrv: ProjectsService,
    private usersSrv: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private tasksSrv: TasksService,
    private ordersSrv: OrdersService,
    private costsSrv: CostsService,
    private suppliersSrv: SuppliersService,
    private paymentConditionsSrv: PaymentConditionsService,
    private ui: UiService
  ) {
    this.taskUser = {
      text: null,
      initialId: null,
    };
  }

  ngOnInit() {
    this.ui.toolbar.title = 'Detalle de Proyecto';

    this.filters = { status: 'active' };
    this.filtered_by = 'active';
    this.downloadPDFText = 'Generar PDF proyecto';

    this.currentUserRole = this.auth.getCurrentRole();
    this.isAuthorized = this.auth.isAuthorized();
    this.orderProject = this.ordersSrv.create();
    this.costProject = this.costsSrv.create();

    this.fetch();
  }

  fetch() {
    const projectId = this.route.snapshot.params['id'];
    this.paymentConditionsSrv
      .fetch()
      .then(
        (paymentConditionsCollection) =>
          (this.paymentConditionsOpts = paymentConditionsCollection.asOptions('id', 'name', { orderBy: 'name' }))
      );
    this.projectsSrv.find(projectId).then((project) => {
      this.project = project;
      this.totalCosts =
        this.project.costs.length > 0
          ? this.project.costs.reduce((total, cost) => ({
              value: parseFloat(total.value) + parseFloat(cost.value),
            })).value
          : 0;
      this.nuTask = this.tasksSrv.create({ project_id: this.project.id });
      this.ordersSrv.ordersByCustomer(this.project.customer_id).then((orders) => {
        this.customerOrders = orders.asArray().filter((order) => {
          return !project.orders.map((order) => order.id).includes(order.id);
        });
      });
    });
    this.usersSrv
      .fetch()
      .then(
        (userCollection) =>
          (this.usersOpts = userCollection
            .filter((user) => user.status === 'active')
            .asObjectOptions('id', { orderBy: 'email' }))
      );
    this.suppliersSrv
      .fetch()
      .then(
        (suppliersCollection) =>
          (this.suppliersOpts = suppliersCollection.collection
            .filter((supplier) => supplier.deactivated_at == null)
            .asObjectOptions('id', { orderBy: 'email' }))
      );
  }

  goTo(url) {
    this.router.navigate([url]);
  }

  finishProject() {
    this.projectsSrv.updateStatus(this.project, 'finished').then(() => {
      this.closeDialogModal();
      this.ui.snackbar.show('¡Proyecto finalizado!');
    });
  }

  showTaskModal() {
    if (this.isAuthorized) {
      this.modalOpen = true;
    } else {
      this.ui.snackbar.show('No tienes permisos para adicionar tareas.');
    }
  }

  closeTaskModal(form?: NgForm) {
    this.modalOpen = false;
    if (form) {
      form.resetForm();
    }
  }

  addProjectTask(form: NgForm) {
    this.ui.snackbar.show('Creando nueva tarea...');
    this.tasksSrv.save(this.nuTask).then((createdTask) => {
      this.ui.snackbar.hide();
      this.project.tasks.push(createdTask);
      this.project.tasks = [...this.project.tasks];
      this.nuTask = this.tasksSrv.create({ project_id: this.project.id });
      this.taskUser.text = null;
      form.resetForm();
      this.closeTaskModal();
    });
  }

  addOrder(orderId, callback = null) {
    this.projectsSrv.addOrder(this.project.id, orderId).then((res) => {
      if (res && res.body) {
        this.project = (res.body as any).project;
        this.customerOrders.splice(this.customerOrders.map((order) => order.id).indexOf(orderId), 1);
        if (callback) callback();
      }
    });
  }

  saveProjectOrder() {
    Object.assign(this.orderProject, { customer_id: this.project.customer_id });
    this.ordersSrv.save(this.orderProject).then((order) => {
      this.addOrder(order.id, () => {
        this.addOrderProjectModalOpen = false;
        this.ui.snackbar.show({
          message: 'Orden añadida!',
        });
      });
    });
  }

  showCostModal() {
    if (this.isAuthorized) {
      this.addCostProjectModalOpen = true;
    } else {
      this.ui.snackbar.show('No tienes permisos para adicionar costos al proyecto.');
    }
  }

  saveProjectCost(costProjectForm: NgForm) {
    if (this.addCostProjectModalOpen) Object.assign(this.costProject, { project_id: this.project.id });
    this.costsSrv.save(this.costProject).then((cost) => {
      if (this.addCostProjectModalOpen) {
        this.project.costs.push(cost);
      } else {
        this.project.costs = this.project.costs.map((elem) => (elem.id === cost.id ? cost : elem));
      }
      this.closeCostModal(costProjectForm);
      this.totalCosts = this.project.costs.reduce((total, cost) => ({
        value: parseFloat(total.value) + parseFloat(cost.value),
      })).value;
    });
  }

  showUpdateCostModal(cost) {
    this.costProject = Object.assign(
      {},
      cost,
      cost.supplier ? { supplier_id: cost.supplier.id, supplier: cost.supplier.name } : {}
    );
    this.updateCostProjectModalOpen = true;
  }

  showDeleteCostDialog(cost) {
    this.conditionDialogOpen = cost;
  }

  deleteCost() {
    this.ui.snackbar.show('Eliminando costo...');
    this.costsSrv.delete(this.conditionDialogOpen).then((response) => {
      this.project.costs = this.project.costs.filter((cost) => cost.id !== this.conditionDialogOpen.id);
      this.totalCosts =
        this.project.costs.length > 0
          ? this.project.costs.reduce((total, cost) => ({
              value: parseFloat(total.value) + parseFloat(cost.value),
            })).value
          : 0;
      this.conditionDialogOpen = null;
      this.ui.snackbar.hide();
    });
  }

  closeCostModal(form?: NgForm) {
    this.addCostProjectModalOpen = false;
    this.updateCostProjectModalOpen = false;
    if (form) form.resetForm();
    this.costProject = this.costsSrv.create();
  }

  showDialogModal() {
    this.modalDialogOpen = true;
  }

  closeDialogModal() {
    this.modalDialogOpen = false;
  }

  attachFile() {
    this.attCollectionEl.openFileLoader();
  }

  attachFilesAllowed() {
    return this.project.attachments.length <= 20;
  }

  filterByStatus() {
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
  }

  disableFilterBy() {
    this.filters = {};
    this.filtered_by = 'all';
  }

  taskUserChangeHandler(id: number) {
    this.nuTask.user_id = id;
  }

  costSupplierChangeHandler(id: number) {
    this.costProject.supplier_id = id;
  }

  generatePDF() {
    this.downloadPDFText = 'Generando...';
    this.projectsSrv
      .downloadDataById(this.project.id, `/download`, 'application/pdf', `${this.project.name}.pdf`)
      .then((res) => {
        this.downloadPDFText = 'Generar PDF proyecto';
        this.ui.snackbar.show('PDF del proyecto generado correctamente!');
      })
      .catch((err) => {
        this.downloadPDFText = 'Generar PDF proyecto';
        this.ui.snackbar.show('Error al generar el PDF del proyecto');
      });
  }

  onUploadFinish(newAttachments: Array<any>) {
    const newIds = newAttachments.map((file) => file.id);
    this.project.attachments = [
      ...this.project.attachments.filter((file) => !newIds.includes(file.id)),
      ...newAttachments,
    ];
  }

  onDeleteFinish(deletedAttachment: any) {
    this.project.attachments = [...this.project.attachments.filter((file) => deletedAttachment.id !== file.id)];
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { AuthAdminGuardService } from '../core/services/auth-admin-guard.service';
import { OrdersAppComponent } from './orders-app.component';

import { ProductsIndexComponent } from './products/products-index.component';
import { ProductsShowComponent } from './products/products-show.component';
import { ProductsCreateOrEditComponent } from './products/products-create-or-edit.component';
import { ConditionsIndexComponent } from './conditions/conditions-index.component';
import { ConditionsShowComponent } from './conditions/conditions-show.component';
import { ConditionsCreateComponent } from './conditions/conditions-create.component';
import { ConditionsEditComponent } from './conditions/conditions-edit.component';
import { PaymentConditionsIndexComponent } from './paymentconditions/paymentconditions-index.component';
import { PaymentConditionsShowComponent } from './paymentconditions/paymentconditions-show.component';
import { PaymentConditionsCreateComponent } from './paymentconditions/paymentconditions-create.component';
import { PaymentConditionsEditComponent } from './paymentconditions/paymentconditions-edit.component';
import { OrdersIndexComponent } from './orders/orders-index.component';
import { OrdersShowComponent } from './orders/orders-show.component';
import { OrdersCreateComponent } from './orders/orders-create.component';
import { OrdersEditComponent } from './orders/orders-edit.component';

const routes: Routes = [
  {
    path: '',
    component: OrdersAppComponent,
    children: [
      { path: '', redirectTo: 'products', pathMatch: 'full' },
      {
        path: 'products',
        children: [
          { path: '', component: ProductsIndexComponent },
          { path: 'new', component: ProductsCreateOrEditComponent },
          { path: ':id', component: ProductsShowComponent },
          { path: ':id/edit', component: ProductsCreateOrEditComponent },
        ],
      },
      {
        path: 'conditions',
        children: [
          { path: '', component: ConditionsIndexComponent },
          { path: 'new', component: ConditionsCreateComponent },
          { path: ':id', component: ConditionsShowComponent },
          { path: ':id/edit', component: ConditionsEditComponent },
        ],
      },
      {
        path: 'paymentconditions',
        children: [
          { path: '', component: PaymentConditionsIndexComponent },
          { path: 'new', component: PaymentConditionsCreateComponent },
          { path: ':id', component: PaymentConditionsShowComponent },
          { path: ':id/edit', component: PaymentConditionsEditComponent },
        ],
      },
      {
        path: 'sell-orders',
        data: { mode: 'sell' },
        children: [
          { path: '', component: OrdersIndexComponent },
          { path: 'new', component: OrdersCreateComponent },
          { path: ':id', component: OrdersShowComponent },
          { path: ':id/edit', component: OrdersEditComponent },
        ],
      },
      {
        path: 'buy-orders',
        data: { mode: 'buy' },
        children: [
          { path: '', component: OrdersIndexComponent },
          { path: 'new', component: OrdersCreateComponent },
          { path: ':id', component: OrdersShowComponent },
          { path: ':id/edit', component: OrdersEditComponent },
        ],
      },
      { path: 'orders', redirectTo: 'sell-orders' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class OrdersAppRoutesModule {}

import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

// comment this two lines if you prefer USA locale:
import 'moment/locale/es';
moment.locale('es');

const uniqueId = 0;

@Component({
  selector: 'ui-calendar',
  templateUrl: 'ui-calendar.template.html',
  styleUrls: ['ui-calendar.styles.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UiCalendarComponent, multi: true }],
})
export class UiCalendarComponent implements OnInit, ControlValueAccessor {
  public month = {};
  private daysOfWeek = moment.weekdays(true).map((item) => item[0].toUpperCase());
  private showMoment = null;
  private selectedMoment = null;
  private selectedDayObj = null;

  private value: any;

  private quickOptions = [
    {
      label: 'Hoy',
      value: null,
      icon: 'today',
    },
    {
      label: 'Mañana',
      value: null,
      icon: 'sun',
    },
    {
      label: 'Próxima semana',
      value: null,
      icon: 'next-week',
    },
  ];

  constructor() {}

  public writeValue(value: any) {
    if (value != this.value) {
      this.refreshCalendar(value);
    }
  }
  public registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: (value: any) => void) {
    this.propagateTouch = fn;
  }

  public ngOnInit() {
    this.refreshCalendar();
  }
  public refreshCalendar(newValue?) {
    this.value = newValue;
    const newSelectedMoment = this.value ? moment(this.value) : moment();
    this.showMoment = newSelectedMoment;
    this.selectedMoment = this.value ? newSelectedMoment : null;
    this.buildMonthObj();
  }
  public absMonths(moment) {
    return moment.year() * 12 + (moment.month() + 1);
  }
  public buildMonthObj() {
    let month, week, day, currentAbsMonth, iterDay, endDay;
    const showMoment = this.showMoment;
    month = {
      yearLabel: showMoment.format('YYYY'),
      label: showMoment.format('MMMM'),
      weeks: [],
    };
    currentAbsMonth = this.absMonths(showMoment);
    iterDay = showMoment.clone().startOf('month').startOf('week');
    endDay = showMoment.clone().endOf('month').endOf('week');
    while (iterDay.diff(endDay, 'd') < 0) {
      week = [];
      for (let i = 0; i < 7; i++) {
        day = {
          label: iterDay.date(),
          diffCurrentMonth: this.absMonths(iterDay) - currentAbsMonth,
          isToday: iterDay.isSame(moment(), 'd'),
          isSelected: iterDay.isSame(this.selectedMoment, 'd'),
          momentObj: iterDay.clone(),
        };
        if (day.isSelected) {
          this.selectedDayObj = day;
        }

        if (day.isToday) {
          this.quickOptions[0].value = day;
        }

        if (iterDay.isSame(moment().add(1, 'days'), 'd')) {
          this.quickOptions[1].value = day;
        }

        if (iterDay.isSame(moment().add(7, 'days').startOf('isoWeek'), 'd')) {
          this.quickOptions[2].value = day;
        }
        week.push(day);
        iterDay.add(1, 'd');
      }
      month.weeks.push(week);
    }
    this.month = month;
  }
  public changeMonth(val) {
    this.showMoment.add(val, 'M');
    this.buildMonthObj();
  }
  public changeYear(val) {
    this.showMoment.add(val, 'Y');
    this.buildMonthObj();
  }
  public selectDay(dayObj) {
    if (!dayObj.isSelected) {
      this.selectedMoment = dayObj.momentObj;
      this.value = this.selectedMoment.format('YYYY-MM-DD');

      this.propagateChange(this.value);

      if (dayObj.diffCurrentMonth === 0) {
        dayObj.isSelected = true;
        if (this.selectedDayObj) {
          this.selectedDayObj.isSelected = false;
        }
        this.selectedDayObj = dayObj;
      } else {
        this.changeMonth(dayObj.diffCurrentMonth);
      }
    }
  }
  public handleSelectQuickOption(dayObj) {
    if (!dayObj.isSelected) {
      this.selectedMoment = dayObj.momentObj;
      this.value = this.selectedMoment.format('YYYY-MM-DD');

      this.refreshCalendar(this.value);
      this.propagateChange(this.value);
    }
  }
  private propagateChange: any = () => {};
  private propagateTouch: any = () => {};
}

import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../core/services/users.service';
import { TasksService } from '../../core/services/tasks.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'users-show',
  templateUrl: './users-show.template.html',
})
export class UsersShowComponent implements OnInit {
  public user = null;
  private filters = {};
  public filtered_by;
  private currentUserRole = null;
  constructor(
    private usersSrv: UsersService,
    private tasksSrv: TasksService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.currentUserRole = this.auth.getCurrentRole();
    this.ui.toolbar.title = 'Detalle de Usuario';
    var userId = this.route.snapshot.params['id'];
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
    this.usersSrv.find(userId).then((user) => (this.user = user));
  }
  goTo(url) {
    this.router.navigate([url]);
  }
  filterByStatus() {
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
  }
  disableFilterBy() {
    this.filters = {};
    this.filtered_by = 'all';
  }
}

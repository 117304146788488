import { Component } from "@angular/core";
import { UiInternalService } from "../shared/ui-internal.service";

@Component({
  selector: "ui-app",
  templateUrl: "ui-app.template.html",
  styleUrls: ["ui-app.styles.scss"],
})
export class UiAppComponent {
  public theme: string = "default";
  constructor(public ui: UiInternalService) {}
  public setTheme(newTheme: string) {
    this.theme = newTheme;
  }
}

<div *ngIf="customer">
  <div class="ui-panel" *ngIf="customer">
    <!--<div class="ui-panel__avatar">
      <img *ngIf="customer.avatar_url" [src]="customer.avatar_url">
    </div>-->
    <h1 class="text-center">{{ customer.name }}</h1>
    <div class="ui-field-group one-half">
      <ui-field-display label="Id" [hideIf]="!customer.identification"
        [value]="'(' + customer.identification_type + ') ' + customer.identification">
      </ui-field-display>
      <ui-field-display label="Razón social" [value]="customer.legal_name"></ui-field-display>
      <ui-field-display label="Correo" [value]="customer.email"></ui-field-display>
      <ui-field-display label="Teléfono" [value]="customer.phone"></ui-field-display>
      <ui-field-display label="Ciudad" [value]="customer.city"></ui-field-display>
      <ui-field-display label="Dirección" [value]="customer.address"></ui-field-display>
      <ui-field-display label="Fuente" [hideIf]="!customer.source" [value]="customer.source"></ui-field-display>
    </div>
    <ui-field-display label="Notas" [value]="customer.summary"></ui-field-display>
  </div>
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-person"></span>
    Contactos
  </h3>
  <p class="ui-panel__empty-state" *ngIf="!customer.people.length">
    Actualmente no hay contactos para este cliente.
  </p>
  <div class="ui-panel no-padding no-border" *ngIf="customer.people.length">
    <table class="ui-table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Telefono</th>
          <th>Cargo</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let person of customer.people">
          <td>{{ person.first_name }} {{ person.last_name }}</td>
          <td>{{ person.phone }}</td>
          <td>{{ person.job_title }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-rocket"></span>
    Proyectos
  </h3>
  <p class="ui-panel__empty-state" *ngIf="!customer.projects.length">
    Actualmente no hay proyectos para este cliente.
  </p>
  <div class="ui-panel no-padding no-border" *ngIf="customer.projects.length">
    <table class="ui-table">
      <thead>
        <tr>
          <th></th>
          <th>Proyecto</th>
          <th>Vence el</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of customer.projects | filterBy : filters "
          class="bordered"
          [class.bordered--success]="(p.status == 'finished')"
          [class.bordered--warning]="(p.status != 'finished')"
          >
          <td><span class="icon icon-rocket"></span></td>
          <td (click)="goTo('/tasks/projects/' + p.id )">{{ p.name }}</td>
          <td>{{ p.due_date | date: 'dd/MM/yyyy' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ui-modal [isOpen]="modalProjectOpen" *ngIf="nuProject">
  <h2>Adicionar proyecto</h2>
  <form #projectForm="ngForm" (ngSubmit)="addCustomerProject($event)">
    <ui-input label="Nombre" name="project_name" [(ngModel)]="nuProject.name" required></ui-input>
    <ui-datepicker label="Vence el" name="project_due_date" [(ngModel)]="nuProject.due_date"></ui-datepicker>
    <ui-input type="textarea" label="Detalles" name="project_summary" [(ngModel)]="nuProject.summary"></ui-input>
    <div class="margin-top--double text-end">
      <ui-btn (uiClick)="closeProjectModal()" flat notab>
        Cancelar
      </ui-btn>
      <ui-btn type="submit" icon="rocket"
        [disabled]="!projectForm.form.valid">
        Adicionar proyecto
      </ui-btn>
    </div>
  </form>
</ui-modal>

<ui-modal [isOpen]="modalPersonOpen" *ngIf="nuPerson">
  <h2>Adicionar contacto</h2>
  <form #personForm="ngForm" (ngSubmit)="addCustomerPerson($event)">
    <ui-input label="Nombre" name="customer_people_name" [(ngModel)]="nuPerson.first_name" required></ui-input>
    <ui-input label="Apellido" name="customer_people_last_name" [(ngModel)]="nuPerson.last_name" required></ui-input>
    <ui-input label="Telefono" name="customer_people_phone" [(ngModel)]="nuPerson.phone"></ui-input>
    <ui-input label="Cargo" name="customer_job_title" [(ngModel)]="nuPerson.job_title"></ui-input>
    <div class="margin-top--double text-end">
      <ui-btn (uiClick)="closePersonModal()" flat notab>
        Cancelar
      </ui-btn>
      <ui-btn type="submit" icon="person"
        [disabled]="!personForm.form.valid">
        Adicionar contacto
      </ui-btn>
    </div>
  </form>
</ui-modal>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__secondary">
    <!-- See all projects -->
    <ui-btn *ngIf="filteredBy == 'active'" fab color="success" (uiClick)="disableFilterBy()"
      hint="Ver todos los proyectos" icon="list-check">
    </ui-btn>
    <!-- See pending projects only -->
    <ui-btn *ngIf="filteredBy == 'all'" fab color="warning" (uiClick)="filterByStatus()"
      hint="Ver solo proyectos activos" icon="list">
    </ui-btn>
    <!-- Add contact -->
    <ui-btn fab color="info" (uiClick)="showPersonModal()"
      hint="Adicionar contacto" icon="person">
    </ui-btn>
    <!-- Add project -->
    <ui-btn fab color="error" (uiClick)="showProjectModal()"
      hint="Adicionar proyecto" icon="rocket">
    </ui-btn>
    <!-- Edit customer -->
    <ui-btn *ngIf="isAuthorized" fab color="accent" (uiClick)="goTo('/tasks/customers/' + (customer?.id || 0) + '/edit')"
      hint="Editar cliente" icon="edit">
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <!-- Open menu -->
    <ui-btn fab color="accent" hint="Opciones" icon="menu">
    </ui-btn>
  </div>
</div>

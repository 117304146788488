<ui-modal isOpen="true">
  <h2>{{ item ? 'Editar' : 'Adicionar' }} item</h2>
  <form [formGroup]="form" (ngSubmit)="closeDialog()" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
    <ui-select
      *ngIf="mode === 'sell'"
      name="product_id"
      label="Producto"
      formControlName="product_id"
      [options]="productsOptions"
    >
    </ui-select>
    <ui-input
      *ngIf="isGeneric || mode === 'buy'"
      [type]="mode === 'buy' ? 'textarea' : 'text'"
      label="Nombre producto"
      name="product_name"
      formControlName="product_name"
    >
    </ui-input>
    <ui-input *ngIf="mode === 'sell'" type="textarea" label="Descripción" name="summary" formControlName="summary">
    </ui-input>
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px" *ngIf="mode === 'sell'">
      <ui-input type="text" label="Costo" name="product_cost" formControlName="product_cost" fxFlex="50%"> </ui-input>
      <ui-input
        type="text"
        label="Porcentaje de ganancia"
        name="profit_rate"
        formControlName="profit_rate"
        fxFlex="50%"
      >
      </ui-input>
    </div>
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
      <ui-input type="number" label="Precio" name="price" formControlName="price" fxFlex="50%"> </ui-input>
      <ui-input type="number" label="Cantidad" name="quantity" formControlName="quantity" fxFlex="50%" required>
      </ui-input>
    </div>
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
      <ui-input
        type="number"
        label="Descuento %"
        name="discount_percent"
        formControlName="discount_percent"
        fxFlex="50%"
      >
      </ui-input>
      <ui-input type="number" label="Descuento" name="discount" formControlName="discount" fxFlex="50%"> </ui-input>
    </div>
    <h4>Total: {{ total | number }}</h4>
    <div class="margin-top--double text-end margin-bottom--simple">
      <ui-btn (uiClick)="closeDialog(true)" type="button" flat notab> Cancelar </ui-btn>
      <ui-btn type="submit" [disabled]="form.invalid"> {{ item ? 'Editar' : 'Adicionar' }} item </ui-btn>
    </div>
  </form>
</ui-modal>

<div *ngIf="project">
  <div
    class="ui-panel"
    [class.ui-panel--warning]="(project.status != 'finished')"
    [class.ui-panel--success]="(project.status == 'finished')"
  >
    <h1 class="text-center">{{ project.name }}</h1>
    <div class="ui-field-group one-half">
      <ui-field-display
        label="Cliente"
        [value]="project.customer?.name"
        [hideIf]="!project.customer"
        (click)="goTo('/tasks/customers/' + project.customer.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display label="Vence el" [value]="project.due_date | date: 'dd/MM/yyyy'"></ui-field-display>
      <ui-field-display
        label="Registrado por"
        *ngIf="project.registered_by"
        [value]="project.registered_by?.person.first_name + ' ' + project.registered_by?.person.last_name"
        (click)="goTo('/tasks/users/' + project.registered_by?.id )"
        clickable
      >
      </ui-field-display>
    </div>
    <ui-field-display label="Descripción" [value]="project.summary"></ui-field-display>
  </div>
  <!-- Customer orders block -->
  <div *ngIf="customerOrdersList">
    <h3 class="ui-panel__pre-title">
      <span class="icon icon-orders"></span>
      Órdenes del cliente
    </h3>
    <p class="ui-panel__empty-state" *ngIf="customerOrders.length == 0">
      Actualmente no hay órdenes disponibles para relacionar con este proyecto.
    </p>
    <div class="ui-panel no-padding no-border" *ngIf="customerOrders.length > 0">
      <table class="ui-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let o of customerOrders ">
            <td (click)="goTo('/orders/orders/' + o.id )">{{ o.name }}</td>
            <td>{{ o.summary }}</td>
            <ui-btn class="float-end" (uiClick)="addOrder(o.id)" flat notab> Relacionar </ui-btn>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-end margin-top">
      <ui-btn class="float-end" (uiClick)="addOrderProjectModalOpen = true"> Adicionar orden </ui-btn>
    </div>
  </div>
  <!-- Orders detail block -->
  <div *ngIf="project.orders.length > 0">
    <h3 class="ui-panel__pre-title">
      <span class="icon icon-file"></span>
      Órdenes
    </h3>
    <div class="ui-panel no-padding no-border">
      <table class="ui-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let o of project.orders ">
            <td (click)="goTo('/orders/orders/' + o.id )">{{ o.name }}</td>
            <td>{{ o.summary }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Tasks detail block -->
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-task"></span>
    Tareas
  </h3>
  <p class="ui-panel__empty-state" *ngIf="!project.tasks.length">Actualmente no hay tareas para este proyecto.</p>
  <div class="ui-panel no-padding no-border" *ngIf="project.tasks.length">
    <task-collection
      hideColumns="project, customer"
      [tasks]="project.tasks | filterBy: filters"
      orderOnUpdate
      orderByDueDate
    >
    </task-collection>
  </div>
  <div class="text-end margin-top">
    <ui-btn class="float-end" *ngIf="(project.status !== 'finished')" (uiClick)="showTaskModal()">
      Adicionar tarea
    </ui-btn>
  </div>

  <!-- Costs detail block -->
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-costs"></span>
    Costos
  </h3>
  <p class="ui-panel__empty-state" *ngIf="project.costs.length == 0">Actualmente no hay costos para este proyecto.</p>
  <div class="ui-panel no-padding no-border" *ngIf="project.costs.length > 0">
    <cost-collection
      [costs]="project.costs"
      [isAuthorized]="isAuthorized"
      (editCost)="showUpdateCostModal($event)"
      (deleteCost)="showDeleteCostDialog($event)"
    ></cost-collection>
  </div>
  <div *ngIf="project.costs.length > 0" class="ui-panel no-padding no-border total-table">
    <table class="ui-table">
      <tbody>
        <tr>
          <td class="title">Total:</td>
          <td align="right">
            <span class="total-value">{{ this.totalCosts | number }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-end margin-top">
    <ui-btn class="float-end" *ngIf="(project.status !== 'finished')" (uiClick)="showCostModal()">
      Adicionar Costo
    </ui-btn>
  </div>

  <h3 class="ui-panel__pre-title">
    <span class="icon icon-paperclip"></span>
    Archivos adjuntos
  </h3>
  <div class="ui-panel no-border">
    <attachment-collection
      #attCollectionEl
      [attachments]="project.attachments"
      [attachableId]="project.id"
      attachableType="Project"
      (afterUpload)="onUploadFinish($event)"
      (afterDelete)="onDeleteFinish($event)"
    >
    </attachment-collection>
  </div>

  <ui-modal [isOpen]="modalOpen">
    <h2>Adicionar tarea</h2>
    <form #taskForm="ngForm" (ngSubmit)="addProjectTask(taskForm, $event)">
      <ui-input type="text" label="Necesitamos" name="task_name" [(ngModel)]="nuTask.name" required></ui-input>
      <ui-datepicker
        label="Vence el"
        name="task_due_date"
        [(ngModel)]="nuTask.due_date"
        [formControlRef]="taskForm.controls['task_due_date']"
        quickActions
        required
      ></ui-datepicker>
      <ui-input
        type="textarea"
        label="Detalles"
        name="task_description"
        [(ngModel)]="nuTask.description"
        placeholder="Coloca los detalles de tu tarea aquí"
      >
      </ui-input>
      <ui-autocomplete
        name="task_user"
        label="Asignado a"
        [(ngModel)]="taskUser.text"
        type="user"
        [options]="usersOpts"
        placeholder="Selecciona un responsable para tu tarea"
        (uiModelChange)="taskUserChangeHandler($event)"
        [initialId]="taskUser.initialId"
        required
      >
      </ui-autocomplete>
      <div class="margin-top--double text-end">
        <ui-btn (uiClick)="closeTaskModal(taskForm)" flat notab> Cancelar </ui-btn>
        <ui-btn type="submit" icon="task" [disabled]="!taskForm.form.valid"> Adicionar tarea </ui-btn>
      </div>
    </form>
  </ui-modal>

  <ui-modal [isOpen]="addOrderProjectModalOpen">
    <h2>Adicionar Orden</h2>
    <form #orderProjectForm="ngForm" (submit)="saveProjectOrder($event)">
      <ui-input
        label="Nombre"
        name="order_name"
        [(ngModel)]="orderProject.name"
        placeholder="Orden de ejemplo"
        required
      >
      </ui-input>
      <ui-input
        type="textarea"
        label="Descripción"
        name="order_summary"
        [(ngModel)]="orderProject.summary"
        placeholder="Añade la descripción de tu orden aquí"
      >
      </ui-input>
      <div class="ui-form-group">
        <ui-select
          name="order_payment_condition_id"
          label="Condición de pago"
          [(ngModel)]="orderProject.payment_condition_id"
          [options]="paymentConditionsOpts"
          placeholder="Selecciona la condición de pago"
        >
        </ui-select>
      </div>
      <div class="margin-top--double text-end">
        <ui-btn (uiClick)="addOrderProjectModalOpen = false" flat notab> Cancelar </ui-btn>
        <ui-btn type="submit" icon="orders" [disabled]="!orderProjectForm.form.valid"> Adicionar orden </ui-btn>
      </div>
    </form>
  </ui-modal>

  <ui-modal [isOpen]="addCostProjectModalOpen || updateCostProjectModalOpen">
    <h2>{{ this.addCostProjectModalOpen ? 'Adicionar Costo' : 'Editar Costo' }}</h2>
    <form #costProjectForm="ngForm" (submit)="saveProjectCost(costProjectForm, $event)">
      <ui-input
        label="Nombre"
        name="cost_name"
        [(ngModel)]="costProject.name"
        [formControlRef]="costProjectForm.controls['cost_name']"
        placeholder="Costo de ejemplo"
        required
      >
      </ui-input>
      <ui-datepicker
        label="Registrado el"
        name="cost_registered_at"
        [(ngModel)]="costProject.registered_at"
        [formControlRef]="costProjectForm.controls['cost_registered_at']"
        required
      ></ui-datepicker>
      <ui-input
        type="textarea"
        label="Descripción"
        name="cost_summary"
        [(ngModel)]="costProject.summary"
        placeholder="Añade la descripción de tu costo aquí"
      >
      </ui-input>
      <ui-autocomplete
        [disabled]="suppliersOpts.length === 0"
        label="Proveedor"
        name="cost_supplier"
        type="supplier"
        [(ngModel)]="costProject.supplier"
        [options]="suppliersOpts"
        placeholder="Selecciona un proveedor"
        (uiModelChange)="costSupplierChangeHandler($event)"
        [initialId]="costProject.supplier ? costProject.supplier.id : null"
        [formControlRef]="costProjectForm.controls['cost_supplier']"
      ></ui-autocomplete>
      <ui-input
        type="number"
        label="Valor"
        name="cost_value"
        [(ngModel)]="costProject.value"
        placeholder="10.000"
        [formControlRef]="costProjectForm.controls['cost_value']"
        required
      ></ui-input>
      <div class="margin-top--double text-end">
        <ui-btn (uiClick)="closeCostModal(costProjectForm)" flat notab> Cancelar </ui-btn>
        <ui-btn type="submit" icon="costs" [disabled]="!costProjectForm.form.valid">
          {{ this.addCostProjectModalOpen ? 'Adicionar Costo' : 'Guardar Costo' }}
        </ui-btn>
      </div>
    </form>
  </ui-modal>

  <ui-modal [isOpen]="modalDialogOpen">
    <h2>Hey! ¿Confirmas que vas a cerrar el proyecto?</h2>
    <div class="margin-top text-end">
      <ui-btn (uiClick)="closeDialogModal()" flat notab> Cancelar </ui-btn>
      <ui-btn (uiClick)="finishProject()" icon="check"> Cerrar proyecto </ui-btn>
    </div>
  </ui-modal>

  <ui-modal [isOpen]="conditionDialogOpen != null">
    <h2>¿Confirmas que deseas eliminar este item?</h2>
    <div class="margin-top text-end">
      <ui-btn (uiClick)="this.conditionDialogOpen = null" flat notab> Cancelar </ui-btn>
      <ui-btn (uiClick)="deleteCost()" icon="delete" color="error"> Eliminar </ui-btn>
    </div>
  </ui-modal>
</div>

<div class="ui-speed-dial" *ngIf="project">
  <div class="ui-speed-dial__secondary">
    <!-- Generate PDF -->
    <ui-btn *ngIf="isAuthorized" fab color="info" (uiClick)="generatePDF()" hint="{{this.downloadPDFText}}">
      <span class="icon icon-file"></span>
    </ui-btn>
    <!-- Finish project -->
    <ui-btn
      *ngIf="project.status !== 'finished' && currentUserRole == 'admin'"
      fab
      color="success"
      (uiClick)="showDialogModal()"
      hint="Finalizar proyecto"
      icon="check"
    >
    </ui-btn>
    <!-- Attach file -->
    <ui-btn
      *ngIf="isAuthorized && attachFilesAllowed()"
      fab
      color="info"
      (uiClick)="attachFile()"
      hint="Adjuntar un archivo"
      icon="paperclip"
    >
    </ui-btn>
    <!-- See all tasks -->
    <ui-btn
      *ngIf="filtered_by == 'active'"
      fab
      color="success"
      (uiClick)="disableFilterBy()"
      hint="Ver todas las tareas"
      icon="list-check"
    >
    </ui-btn>
    <!-- See only pending tasks -->
    <ui-btn
      *ngIf="filtered_by == 'all'"
      fab
      color="warning"
      (uiClick)="filterByStatus()"
      hint="Ver solo tareas pendientes"
      icon="list"
    >
    </ui-btn>
    <!-- Add task -->
    <ui-btn
      *ngIf="isAuthorized && project.status !== 'finished'"
      fab
      color="accent"
      (uiClick)="showTaskModal()"
      hint="Adicionar tarea"
      icon="task"
    >
    </ui-btn>
    <!-- Add order -->
    <ui-btn
      *ngIf="isAuthorized && project.status !== 'finished'"
      fab
      color="accent"
      (uiClick)="this.customerOrdersList = !this.customerOrdersList"
      hint="Adicionar Orden"
      icon="orders"
    >
    </ui-btn>
    <!-- Edit project -->
    <ui-btn
      *ngIf="isAuthorized"
      fab
      color="accent"
      (uiClick)="goTo('/tasks/projects/' + (project?.id || 0) + '/edit')"
      hint="Editar proyecto"
      icon="edit"
    >
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <!-- Open menu -->
    <ui-btn fab color="accent" hint="Opciones" icon="menu"> </ui-btn>
  </div>
</div>

<div class="ui-flex-table" *ngIf="costs.length > 0">
  <div>
    <div class="ui-flex-table__header-row">
      <span align="left" class="cell--med-small">Fecha de registro</span>
      <span align="left" class="cell--large">Nombre</span>
      <span align="left" class="cell--medium">Proveedor</span>
      <span align="right" class="cell--medium">Valor</span>
      <span class="cell--actions" *ngIf="isAuthorized"></span>
    </div>
  </div>
  <div>
    <div *ngFor="let cost of costs" class="ui-flex-table__row bordered">
      <span align="left" class="cell--med-small">
        {{ cost.registered_at }}
      </span>
      <span align="left" class="cell--large">
        {{ cost.name }}
      </span>
      <span align="left" class="cell--medium">
        {{ cost.supplier ? cost.supplier.name : null }}
      </span>
      <span align="right" class="cell--medium">
        {{ cost.value | number }}
      </span>
      <span class="cell--actions" *ngIf="isAuthorized">
        <ui-more-menu *ngIf="isAuthorized" [actions]="actionMenu" [params]="cost" alignRight icon="more-vert"></ui-more-menu>
      </span>
    </div>
  </div>
</div>
  
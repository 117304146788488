import { Component, OnInit, HostListener } from '@angular/core';
import { style, state, animate, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { UiService } from '../../core/services/ui.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'sign-up',
  templateUrl: 'sign-up.template.html',
  styleUrls: ['sign-up.styles.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate(600, style({ opacity: 1 }))])]),
    trigger('slideRight', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-100%)' }),
        animate(200, style({ transform: 'translateX(0%)', opacity: 1 })),
      ]),
    ]),
    trigger('slideLeft', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate(200, style({ opacity: 1, transform: 'translateX(0%)' })),
      ]),
    ]),
    trigger('slideUp', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(50%)' }),
        animate(500, style({ opacity: 1, transform: 'translateY(0%)' })),
      ]),
    ]),
  ],
})
export class SignUpComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isLowerThanSm = window.innerWidth < 768;
  }
  public isLowerThanSm: boolean;
  private firstUserForm: FormGroup;
  private newUserForm: FormGroup;
  public isTokenSent: boolean;
  public token: string;
  private tokenRqError: string = 'Ocurrió un error enviando el correo de confirmación, intenta de nuevo';
  private generalError: string = 'No fue posible registrarse. Por favor, intente de nuevo';
  public currentStep: number = 0;
  public authType: string = 'password';
  public isInvalidToken: boolean;
  public isLoading: boolean;
  public blank: boolean = true;
  constructor(
    private auth: AuthService,
    private router: Router,
    private ui: UiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (!this.token) {
      this.blank = false;
    } else {
      this.tokenValidation();
    }
    this.isLowerThanSm = window.innerWidth <= 766;
    this.firstUserForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
    this.newUserForm = this.formBuilder.group({
      user: this.formBuilder.group({
        first_name: [null, Validators.required],
        last_name: [null, Validators.required],
        password: [null, [Validators.required, Validators.minLength(8)]],
      }),
      business: this.formBuilder.group({
        business_name: [null, [Validators.required]],
        group_name: [null, [Validators.required]],
      }),
      termsConditions: [false, Validators.requiredTrue],
    });
  }

  tokenValidation() {
    this.auth
      .checkToken(this.token)
      .then(() => (this.blank = false))
      .catch((err) => {
        this.token = null;
        this.blank = false;
        if (err.status === 401) {
          this.isInvalidToken = true;
        } else {
          this.ui.snackbar.show('No se pudo validar el link. Por favor, intente de nuevo');
        }
      });
  }

  tokenRequesthandler() {
    this.isLoading = true;
    this.auth
      .signUpToken(this.firstUserForm.value.email)
      .then((res) => {
        this.isLoading = false;
        if (res.status == 204) {
          this.ui.snackbar.show({
            message: 'Correo de confirmación enviado correctamente',
          });
          this.isTokenSent = true;
        } else {
          this.ui.snackbar.show({
            message: this.tokenRqError,
          });
        }
      })
      .catch((err) => {
        this.isLoading = false;
        const message = err.status === 409 ? 'El correo registrado ya se encuentra en uso' : this.tokenRqError;
        this.ui.snackbar.show({ message });
      });
  }

  signUpHandler() {
    this.isLoading = true;
    const doc = { ...this.newUserForm.value, token: this.token };
    doc.extra = {
      type: this.authType,
    };
    if (this.authType === 'google') {
      doc.extra.google_id_token = this.auth.getGoogleProfile.id_token;
      doc.extra.email = this.auth.getGoogleProfile.email;
    }
    delete doc.termsConditions;
    this.auth
      .signUp(doc)
      .toPromise()
      .then((res) => {
        this.auth.authResponseHandler(res, this.authType);
        this.router.navigateByUrl('/');
      })
      .catch((err) => {
        this.isLoading = false;
        if (err.status === 401) {
          this.isInvalidToken = true;
        } else {
          this.ui.snackbar.show('Ocurrió un error, intenta de nuevo');
        }
      });
  }

  signUpGoogleHandler() {
    this.isLoading = true;
    this.auth
      .googleAuthHandler(true)
      .then((res: boolean) => {
        this.isLoading = false;
        if (res) {
          if (this.auth.getCurrentUser()) {
            this.router.navigateByUrl('/');
          } else {
            this.toggleAuthType('google');
          }
        } else {
          this.ui.snackbar.show(this.generalError);
        }
      })
      .catch(() => {
        this.isLoading = false;
        this.ui.snackbar.show(this.generalError);
      });
  }

  toggleStep() {
    if (this.currentStep === 0) {
      this.currentStep++;
    } else {
      this.currentStep--;
    }
  }

  toggleAuthType(authType: string) {
    if (authType === 'google') {
      (<FormGroup>(<any>this.newUserForm.controls).user).removeControl('password');
    }
    this.authType = authType;
  }

  resetFlow() {
    this.router.navigate(['.'], { relativeTo: this.route });
    this.token = null;
    this.authType = 'password';
    this.isInvalidToken = false;
  }

  get isFirstStepValid() {
    return (<FormGroup>(<any>this.newUserForm.controls).user).valid;
  }
  // signoutHandler() {
  //   this.auth.signOut().then(() => {
  //     this.ui.snackbar.show(`La sesión se finalizó correctamente.`);
  //   });
  // }
}

import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../core/services/customers.service';
import { ProjectsService } from '../../core/services/projects.service';
import { PeopleService } from '../../core/services/people.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'customers-show',
  templateUrl: 'customers-show.template.html',
})
export class CustomersShowComponent implements OnInit {
  public customer = null;
  private modalProjectOpen = false;
  private modalPersonOpen = false;
  public nuProject = null;
  public nuPerson = null;
  private filters = {};
  public filteredBy;
  public isAuthorized = false;
  constructor(
    private auth: AuthService,
    private customersSrv: CustomersService,
    private projectsSrv: ProjectsService,
    private peopleSrv: PeopleService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Detalle de Cliente';
    this.isAuthorized = this.auth.isAuthorized();
    var customerId = this.route.snapshot.params['id'];
    this.filters = { status: 'active' };
    this.filteredBy = 'active';
    this.customersSrv.find(customerId).then((customer) => {
      this.customer = customer;
      this.nuPerson = this.peopleSrv.create({ customer_id: this.customer.id });
      this.nuProject = this.projectsSrv.create({ customer_id: this.customer.id });
    });
  }
  goTo(url) {
    this.router.navigate([url]);
  }
  showProjectModal() {
    if (this.isAuthorized) {
      this.modalProjectOpen = true;
    } else {
      this.ui.snackbar.show(
        'No tienes permisos para crear un projecto relacionado con un cliente.'
      );
    }
  }
  closeProjectModal() {
    this.modalProjectOpen = false;
    this.nuProject = this.projectsSrv.create({ customer_id: this.customer.id });
  }
  showPersonModal() {
    if (this.isAuthorized) {
      this.modalPersonOpen = true;
    } else {
      this.ui.snackbar.show('No tienes permisos para adicionar personas de contacto.');
    }
  }
  closePersonModal() {
    this.modalPersonOpen = false;
    this.nuPerson = this.peopleSrv.create({ customer_id: this.customer.id });
  }
  addCustomerProject() {
    this.ui.snackbar.show('Creando nuevo proyecto...');
    this.projectsSrv.save(this.nuProject).then((createdProject) => {
      this.ui.snackbar.hide();
      this.customer.projects.push(createdProject);
      this.refreshFiltering();
      this.closeProjectModal();
      // this.nuProject = this.projectsSrv.create({customer_id: this.customer.id});
    });
  }
  addCustomerPerson() {
    this.ui.snackbar.show('Creando nueva persona de contacto...');
    this.peopleSrv.save(this.nuPerson).then((createdPerson) => {
      this.ui.snackbar.hide();
      this.customer.people.push(createdPerson);
      this.closePersonModal();
      // this.nuPerson = this.peopleSrv.create({customer_id: this.customer.id});
    });
  }
  refreshFiltering() {
    // TODO: Esto es un workaround. Cuando se agrega un proyecto nuevo no se refresca la lista.
    if (this.filteredBy === 'all') {
      this.disableFilterBy();
    } else {
      this.filterByStatus();
    }
  }
  filterByStatus() {
    this.filters = { status: 'active' };
    this.filteredBy = 'active';
  }
  disableFilterBy() {
    this.filters = {};
    this.filteredBy = 'all';
  }
}

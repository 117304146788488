import { Component } from "@angular/core";
import { isString } from "../shared/helpers";

@Component({
  selector: "ui-snackbar",
  templateUrl: "ui-snackbar.template.html",
  styleUrls: ["ui-snackbar.styles.scss"],
})
export class UiSnackbarComponent {
  public isOpen: boolean = false;
  public params: any = {};
  private currentTimeout: any = null;
  private DEFAULT_TIMEOUT: number = 4000;
  private TRANSITION_DURATION: number = 280;

  public show(params: any) {
    if (this.isOpen) {
      this.hide();
      setTimeout(() => {
        this.internalShow(params);
      }, this.TRANSITION_DURATION);
    } else {
      this.internalShow(params);
    }
  }
  public internalShow(params: any) {
    clearTimeout(this.currentTimeout);
    this.params = isString(params)
      ? {
          message: params,
        }
      : {
          message: params.message || "",
          btnLabel: params.btnLabel,
          spinner: params.spinner,
          hideClose: params.hideClose,
        };
    this.isOpen = true;
    if (!this.params.persistent) {
      this.currentTimeout = setTimeout(() => {
        this.hide();
      }, this.params.duration || this.DEFAULT_TIMEOUT);
    }
  }
  public hide() {
    clearTimeout(this.currentTimeout);
    this.isOpen = false;
  }
  public executeAction() {
    if (this.params.btnAction) { this.params.btnAction(); }
  }
}

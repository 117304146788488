import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { HttpX } from './http-x.service';
import { ApiService, ICollectionResponse } from './api.service';
import { AuthService } from './auth.service';
import Collection from 'src/app/shared/collection.class';

export interface ITask {
  assigned_at: string;
  code: string;
  due_date: string;
  finished_at: string;
  id: number;
  name: string;
  project: any;
  project_id: number;
  registered_by: any;
  finished_by_id: number;
  status: string;
  user: any;
  user_group: any;
  user_id: number;
  description: string;
}

@Injectable()
export class TasksService extends ApiService {
  private baseTask = {
    name: '',
    user: null,
    due_date: null,
    project: null,
  };
  constructor(private http: HttpX, private auth: AuthService) {
    super('task', 'tasks', http, auth);
  }
  create(opts = {}) {
    return {
      ...this.baseTask,
      ...opts,
    };
  }
  updateStatus(task: ITask, status: string) {
    task.status = status;
    if (status === 'done') {
      task.finished_by_id = this.auth.getCurrentUser().id;
    } else {
      task.finished_by_id = null;
    }
    return this.save(task);
  }
  updateDueDate(task: ITask, days: number) {
    Object.assign(task, { due_date: moment().add(days, 'days').format('YYYY-MM-DD') });
    return this.save(task);
  }
  elapsedTime(task: ITask) {
    if (task.status === 'canceled') return 0;
    return task.user
      ? moment(task.finished_at ? moment(task.finished_at).add(1, 'days') : moment()).diff(task.assigned_at, 'days')
      : 0;
  }
  taskIsDue(task: ITask) {
    if (task.status == 'canceled') return 'canceled';
    if (task.status == 'done') return 'done';
    if (moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).isAfter(task.due_date)) return 'due';
    return 'pending';
  }
  fetch(extras?: any): Promise<ICollectionResponse<ITask>> {
    return super.fetch(extras);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';

import { TasksService } from '../../core/services/tasks.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'tasks-index',
  templateUrl: 'tasks-mytasks.template.html',
})
export class TasksMyTasksComponent implements OnInit {
  private tasks;
  private task = null;
  public filtered_by;
  private baseUrl;
  private myname;
  private DEFAULT_PAGE_SIZE = 25;
  private DEFAULT_PAGE_INDEX = 0;
  public paginator = {
    pageIndex: this.DEFAULT_PAGE_INDEX,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  };
  public isLoading: boolean = true;
  private noSyncForm: boolean = false;

  constructor(
    private tasksSrv: TasksService,
    private auth: AuthService,
    private router: Router,
    private ui: UiService,
    private activatedRoute: ActivatedRoute
  ) {}

  goTo(url) {
    this.router.navigate([url]);
  }

  ngOnInit() {
    let currentUser = this.auth.getCurrentUser();

    this.ui.toolbar.title = 'Mis tareas';
    this.filtered_by = 'active';
    this.myname = currentUser.person.first_name;
    this.baseUrl = `?q[user_id_eq]=${currentUser.id}`;

    this.activatedRoute.queryParams.subscribe((params) => {
      if (!this.noSyncForm && Object.keys(params).length > 0) {
        this.syncFilterWithUrl(params);
      } else {
        this.noSyncForm = false;
      }
      this.isLoading = Object.keys(params).length === 0 ? undefined : this.isLoading;
      if (this.isLoading === undefined) {
        this.updateRouterQueryParams();
      }
      this.fetch();
    });
  }

  fetch() {
    this.isLoading = true;
    this.tasksSrv
      .fetch(this.indexQueryParams)
      .then((res) => {
        this.paginator.length = res.pagination.total;
        this.tasks = res.collection.asArray();
        this.isLoading = false;
      })
      .catch((e) => {
        console.error(e);
        this.isLoading = false;
      });
  }

  changePage(paginator: any) {
    this.paginator.pageIndex = paginator.pageIndex;
    this.paginator.pageSize = paginator.pageSize;
    this.updateRouterQueryParams();
  }

  private syncFilterWithUrl(queryParams: Params) {
    // console.warn('syncFilterWithUrl', queryParams);
    this.paginator.pageIndex = queryParams.page > -1 ? queryParams.page - 1 : this.DEFAULT_PAGE_INDEX;
    this.paginator.pageSize = this.isPageSizeValid(queryParams.per_page)
      ? Number(queryParams.per_page)
      : this.DEFAULT_PAGE_SIZE;
  }

  private isPageSizeValid(pageSize: string) {
    return this.paginator.pageSizeOptions.indexOf(Number(pageSize)) !== -1;
  }

  private updateRouterQueryParams() {
    const queryParams = this.formatToQueryParams;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  private get indexQueryParams() {
    const pageSize = this.paginator.pageSize;
    const pageIndex = this.paginator.pageIndex + 1;

    let url = `${this.baseUrl}&page=${pageIndex}&per_page=${pageSize}`;

    if (this.filtered_by === 'active') {
      url += '&q[status_eq]=active';
    }

    return url;
  }

  private get formatToQueryParams() {
    const params = {
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
    } as Params;
    return params;
  }

  filterByStatus() {
    this.filtered_by = 'active';
    this.fetch();
  }

  disableFilterBy() {
    this.filtered_by = 'all';
    this.fetch();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {} from 'rxjs';

@Injectable()
export class HttpX {
  private authToken = null;
  private headers = new HttpHeaders();
  constructor(private http: HttpClient) {}
  public setAuthToken(newToken) {
    this.authToken = newToken;
  }
  public addHeader(key, value) {
    this.headers = this.headers.append(key, value);
  }
  public removeHeader(key) {
    this.headers.delete(key);
  }
  public get(url) {
    const response = this.http.get(url, {
      headers: this.headers,
      responseType: 'json',
      observe: 'response',
    });
    return response.toPromise().then((res) => {
      const payload = res.body as any;
      const headers = res.headers;
      let pagination = null;
      if (headers.get('Per-Page')) {
        pagination = {
          perPage: parseInt(headers.get('Per-Page')),
          total: parseInt(headers.get('Total')),
        };
      }
      return { payload, pagination };
    });
  }
  public post(url, data) {
    return this.http.post(url, data, { headers: this.headers, observe: 'response' });
  }
  public put(url, data) {
    return this.http.put(url, data, { headers: this.headers });
  }
  public delete(url) {
    return this.http.delete(url, { headers: this.headers });
  }
  public download(url) {
    return this.http.get(url, { headers: this.headers, responseType: 'blob' });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { OrdersService } from '../../core/services/orders.service';
import { UiService } from '../../core/services/ui.service';
import { CustomersService } from '../../core/services/customers.service';
import { PaymentConditionsService } from '../../core/services/payment-conditions.service';
import { buyOrders, sellOrders } from './modeHelpers';
import { PurchaseOrdersService } from 'src/app/core/services/purchaseOrders.service';

@Component({
  selector: 'orders-create',
  templateUrl: 'orders-create-edit.template.html',
})
export class OrdersCreateComponent implements OnInit {
  public order;
  private customersOpts = [];
  private paymentConditionsOpts = [];
  public isLoading: boolean = true;
  public mode;

  constructor(
    private ordersSrv: OrdersService,
    private purchaseOrdersSrv: PurchaseOrdersService,
    private customersSrv: CustomersService,
    private paymentConditionsSrv: PaymentConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}

  ngOnInit() {
    this.order = this.ordersSrv.create();
    this.fetch();

    this.route.data.subscribe(({ mode }) => {
      this.mode = mode;
      this.ui.toolbar.title = this.modeTexts.newOrderTitle;
    });
  }

  fetch() {
    this.paymentConditionsSrv.fetch().then((paymentConditionsCollection) => {
      this.paymentConditionsOpts = paymentConditionsCollection.asOptions('id', 'name', { orderBy: 'name' });
      this.isLoading = false;
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.saveOrder();
  }

  saveOrder() {
    let promise = this.mode === 'buy' ? this.purchaseOrdersSrv.save(this.order) : this.ordersSrv.save(this.order);
    promise
      .then((order) => {
        this.ui.snackbar.show({
          message: 'Orden creada!',
        });
        const url = this.mode === 'buy' ? '/orders/buy-orders/' : 'orders/sell-orders/';
        this.router.navigate([url + order.id]);
      })
      .catch((error) => {
        console.error(error);
        this.ui.snackbar.show({
          message: 'Ha ocurrido un error!',
        });
      });
  }

  orderCustomerChangeHandler(id: number) {
    if (this.mode === 'buy') {
      this.order.supplier_id = id;
    } else {
      this.order.customer_id = id;
    }
  }

  public get modeTexts() {
    return this.mode === 'sell' ? sellOrders : buyOrders;
  }
}

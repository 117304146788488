<div *ngIf="order">
  <div class="ui-panel" [class.ui-panel--canceled]="order.status == 'canceled'">
    <h1 class="text-center">{{ order.name }}</h1>
    <ui-field-display label="Descripción" [value]="order.summary"></ui-field-display>
    <div class="ui-field-group one-half">
      <ui-field-display
        label="{{modeTexts.userLabel}}"
        [value]="orderUser.name"
        [hideIf]="!orderUser"
        (click)="goTo(userDetailsUrl)"
        clickable
      >
      </ui-field-display>
      <ui-field-display label="Orden No" [value]="order.sequence"></ui-field-display>
      <ui-field-display label="Estado" [value]="ordersSrv.orderStatusDesc[order.status]"></ui-field-display>
      <ui-field-display
        label="Registrado por"
        *ngIf="order.registered_by"
        [value]="order.registered_by?.person.first_name + ' ' + order.registered_by?.person.last_name"
        (click)="goTo('/tasks/users/' + order.registered_by?.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display label="Registrada en" [value]="order.created_at | date: 'dd/MM/yyyy'"></ui-field-display>
      <ui-field-display
        label="Cerrada por"
        *ngIf="order.closed_by"
        [value]="order.closed_by?.person.first_name + ' ' + order.closed_by?.person.last_name"
        (click)="goTo('/tasks/users/' + order.closed_by?.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display label="Cerrada en" [value]="order.closed_at | date: 'dd/MM/yyyy'"></ui-field-display>
      <ui-field-display
        label="Aprobada por"
        *ngIf="order.approved_by"
        [value]="order.approved_by?.person.first_name + ' ' + order.approved_by?.person.last_name"
        (click)="goTo('/tasks/users/' + order.approved_by?.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display label="Aprobado en" [value]="order.approved_at | date: 'dd/MM/yyyy'"></ui-field-display>
      <ui-field-display
        label="Finalizada por"
        *ngIf="order.finished_by"
        [value]="order.finished_by?.person.first_name + ' ' + order.finished_by?.person.last_name"
        (click)="goTo('/tasks/users/' + order.finished_by?.id )"
        clickable
      >
      </ui-field-display>
      <ui-field-display label="Finalizada en" [value]="order.finished_at | date: 'dd/MM/yyyy'"></ui-field-display>
      <ui-field-display
        label="Condiciones de pago"
        [value]="order.payment_condition?.name"
        [hideIf]="!order.payment_condition"
      ></ui-field-display>
      <ui-field-display
        width="full"
        label="Nota de la condicion de pago"
        [value]="order.payment_condition_text"
        [hideIf]="!order.payment_condition"
      ></ui-field-display>
    </div>
  </div>
  <!-- ITEMS TABLE -->
  <h3 class="ui-panel__pre-title">
    <span *ngIf="this.mode === 'sell'" class="icon icon-products"></span>
    <mat-icon *ngIf="this.mode === 'buy'" class="icon">shopping_basket</mat-icon>
    Items
  </h3>
  <div class="ui-panel no-padding no-border" *ngIf="orderItems.length; else noItemsOrder">
    <table class="ui-table">
      <thead>
        <tr>
          <th>Producto</th>
          <th *ngIf="mode !== 'buy'">Descripción</th>
          <th class="table-title">Cantidad</th>
          <th *ngIf="boQuoteDisplay == true" class="table-title">Precio</th>
          <th *ngIf="boQuoteDisplay == true" class="table-title">Descuento</th>
          <th *ngIf="boQuoteDisplay == true" class="table-title">Total</th>
          <th *ngIf="boQuoteDisplay == true && order.status === 'initial'" class="table-title td-padding">Opciones</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let i of orderItems">
          <tr (mouseenter)="mouseEnter(getItemConditions(i))" (mouseleave)="mouseLeave('i')">
            <td *ngIf="mode === 'sell'" class="title" (click)="goTo(productLink + i.product_id )">
              {{ i.product_name }}
            </td>
            <td [class.description]="boQuoteDisplay == true">{{ mode === 'buy' ? i.item_name : i.summary }}</td>
            <td align="right">{{ i.quantity | number }}</td>
            <td *ngIf="boQuoteDisplay == true" align="right">{{ i.price | number }}</td>
            <td *ngIf="boQuoteDisplay == true" align="right">{{ i.discount | number }}</td>
            <td *ngIf="boQuoteDisplay == true" align="right">{{ this.showFixedNumber(i.total) | number }}</td>
            <td *ngIf="boQuoteDisplay == true && order.status === 'initial'" align="right">
              <a
                class="condition-btn"
                (click)="addOrEditItemConditionHandler($event, 'Adicionar condición', 'Adicionar condición', i, null)"
                title="Adicionar condición"
              >
                <span class="icon icon-add"></span>
              </a>
              <a
                class="condition-btn edit-btn"
                (click)="showOrderItemModal('Editar item', 'Guardar', i)"
                title="Editar item"
              >
                <span class="icon icon-edit"></span>
              </a>
              <a class="condition-btn delete-btn" (click)="deleteOrderItemHandler($event, i)" title="Eliminar item">
                <span class="icon icon-delete"></span>
              </a>
            </td>
          </tr>
          <ng-container *ngFor="let c of getItemConditions(i)">
            <tr
              *ngIf="boQuoteDisplay == true"
              [class.hover-condition]="this.hoverConditions.includes(c)"
              class="condition-tr"
            >
              <td [attr.colspan]="this.mode === 'sell' ? 5 : 4">{{ c.condition_name }} ({{ c.rate }})</td>
              <td align="right">{{ this.showFixedNumber(c.value) | number}}</td>
              <td *ngIf="order.status === 'initial'" align="right">
                <a
                  class="condition-btn edit-btn"
                  (click)="addOrEditItemConditionHandler($event, 'Editar condición', 'Guardar', i, c)"
                  title="Editar condición"
                >
                  <span class="icon icon-edit"></span>
                </a>
                <a
                  class="condition-btn delete-btn"
                  (click)="deleteItemConditionHandler($event, c, i)"
                  title="Eliminar condición"
                >
                  <span class="icon icon-delete"></span>
                </a>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
  <!-- ITEMS PRICE TABLE -->
  <div *ngIf="boQuoteDisplay" class="ui-panel no-padding no-border total-table">
    <table class="ui-table">
      <tbody>
        <tr>
          <td class="title">Valor neto:</td>
          <td align="right" [class.td-padding]="boQuoteDisplay == true && order.status === 'initial'">
            {{this.showFixedNumber(this.netWorth) | number}}
          </td>
        </tr>
        <tr>
          <td class="title">Descuentos:</td>
          <td align="right" [class.td-padding]="boQuoteDisplay == true && order.status === 'initial'">
            {{this.showFixedNumber(this.totalDiscounts) | number}}
          </td>
        </tr>
        <tr>
          <td class="title">Total sin impuestos:</td>
          <td align="right" [class.td-padding]="boQuoteDisplay == true && order.status === 'initial'">
            {{this.showFixedNumber(this.netWorth + this.totalDiscounts) | number}}
          </td>
        </tr>
        <tr>
          <td class="title">Impuestos:</td>
          <td align="right" [class.td-padding]="boQuoteDisplay == true && order.status === 'initial'">
            {{this.showFixedNumber(this.totalTaxes) | number}}
          </td>
        </tr>
        <tr class="condition-tr">
          <td class="title">Total:</td>
          <td align="right" [class.td-padding]="boQuoteDisplay == true && order.status === 'initial'">
            {{this.showFixedNumber(this.total + this.totalTaxes) | number}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- NO ITEMS IN ORDER -->
  <ng-template #noItemsOrder>
    <p class="ui-panel__empty-state">{{modeTexts.detailPageEmptyItems}}</p>
  </ng-template>
  <div class="text-end margin-top">
    <ui-btn
      *ngIf="(order.status === 'initial')"
      (uiClick)="showOrderItemModal('Adicionar item', 'Adicionar item', null)"
    >
      Adicionar item
    </ui-btn>
  </div>
  <!-- PROYECTOS -->
  <ng-container *ngIf="this.mode === 'sell'">
    <h3 class="ui-panel__pre-title">
      <span class="icon icon-rocket"></span>
      Proyectos
    </h3>
    <p class="ui-panel__empty-state" *ngIf="!order.projects.length">Actualmente no hay proyectos para esta orden.</p>
    <div class="ui-panel no-padding no-border" *ngIf="order.projects.length">
      <table class="ui-table">
        <thead>
          <tr>
            <th></th>
            <th>Proyecto</th>
            <th>Vence el</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let p of order.projects"
            class="bordered"
            [class.bordered--success]="(p.status == 'finished')"
            [class.bordered--warning]="(p.status != 'finished')"
          >
            <td>
              <span class="icon icon-rocket"></span>
            </td>
            <td (click)="goTo('/tasks/projects/' + p.id )">{{ p.name }}</td>
            <td>{{ p.due_date | date: 'dd/MM/yyyy' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <!-- ORDENES DE VENTA -->
  <ng-container *ngIf="this.mode === 'buy'">
    <h3 class="ui-panel__pre-title">
      <span class="icon icon-orders"></span>
      Órdenes de venta
    </h3>
    <!-- TODO: ACOMODAR PARA CUANDO NO HAYA ORDENES DE VENTA -->
    <p class="ui-panel__empty-state" *ngIf="!sellOrders.length">
      Actualmente no hay órdenes de venta relacionadas con esta orden de compra.
    </p>

    <div class="ui-panel no-padding no-border" *ngIf="sellOrders.length">
      <table class="ui-table">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Cliente</th>
            <th>Orden No</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let o of sellOrders"
            class="bordered"
            [class.bordered--success]="(o.status == 'finished')"
            [class.bordered--canceled]="(o.status == 'canceled')"
            [class.bordered--warning]="(o.status != 'finished' && o.status != 'canceled')"
          >
            <td>
              <span class="icon icon-shopping-cart"></span>
            </td>
            <td (click)="goTo('/orders/sell-orders/' + o.id)">{{ o.name }}</td>
            <td (click)="goTo('/tasks/customers/' + o.customer?.id )">{{ o.customer?.name }}</td>
            <td (click)="goTo('/orders/sell-orders/' + o.id)">{{ o.sequence }}</td>
            <td (click)="goTo('/orders/sell-orders/' + o.id)">{{ ordersSrv.orderStatusDesc[o.status] }}</td>
          </tr>
        </tbody>
      </table>
      <mat-paginator
        [pageIndex]="sellOrdersPaginator.pageIndex"
        [length]="sellOrdersPaginator.length"
        [pageSize]="sellOrdersPaginator.pageSize"
        [pageSizeOptions]="sellOrdersPaginator.pageSizeOptions"
        (page)="changeSellOrdersPage($event)"
      >
      </mat-paginator>
    </div>
  </ng-container>
  <item-dialog
    *ngIf="modalOpen"
    [item]="selectedItem"
    [products]="productsCollection"
    [orderRoundedValues]="orderRoundedValues"
    [mode]="mode"
    (closeModal)="addOrEditOrderItem($event)"
  >
  </item-dialog>

  <!-- <ui-modal [isOpen]="modalOpen">
    <h2>{{this.itemModalTitle}}</h2>
    <form #orderitemForm="ngForm" (ngSubmit)="addOrEditOrderItem($event)">
      <ui-select name="product_id" label="Producto" [ngModel]="nuOrderItem.product_id" [options]="productsOpts"
        (ngModelChange)="itemSelectChangeHandler($event)">
      </ui-select>
      <ui-input type="textarea" label="Descripción" name="summary" [(ngModel)]="nuOrderItem.summary"></ui-input>
      <ui-input type="number" label="Cantidad" name="quantity" [(ngModel)]="nuOrderItem.quantity"
        (ngModelChange)="orderItemQuantitytHandler($event)" required></ui-input>
      <ui-input type="number" label="Precio" name="price" [(ngModel)]="nuOrderItem.price" [readonly]="boPriceLocked"></ui-input>
      <div class="ui-form-group one-half">
        <ui-input type="number" label="Descuento %" name="discount_percent" [(ngModel)]="nuOrderItem.discount_percent"
          (ngModelChange)="orderItemPercentHandler($event)"></ui-input>
        <ui-input type="number" label="Descuento" name="discount" [(ngModel)]="nuOrderItem.discount"
          (ngModelChange)="orderItemDiscountHandler($event)"></ui-input>
      </div>
      <h3>Total: {{ this.showFixedNumber(nuOrderItem.total) | number }}</h3>
      <div class="margin-top--double text-end">
        <ui-btn (uiClick)="this.modalOpen = false" flat notab>
          Cancelar
        </ui-btn>
        <ui-btn type="submit" [disabled]="!orderitemForm.form.valid">{{this.submitModal}}</ui-btn>
      </div>
    </form>
  </ui-modal> -->

  <ui-modal [isOpen]="modalSendPDFOpen">
    <h2>{{this.sendPDFText}}</h2>
    <form #sendPDFForm="ngForm" (ngSubmit)="sendPDF($event)">
      <ui-input
        type="text"
        label="Correo electrónico cliente"
        name="client"
        [(ngModel)]="sendPDFObject.client"
        required
      ></ui-input>
      <!-- <ui-input type="text" label="Emisor" name="sender" [(ngModel)]="sendPDFObject.sender" required></ui-input> -->
      <ui-input type="text" label="CC (ejemplo@test.com, ejemplo2@test.com)" name="cc" [(ngModel)]="sendPDFObject.cc">
      </ui-input>
      <div class="margin-top--double text-end">
        <ui-btn (uiClick)="this.modalSendPDFOpen = false" flat notab> Cancelar </ui-btn>
        <ui-btn type="submit" [disabled]="!sendPDFForm.form.valid">{{this.modalSendPDFText}}</ui-btn>
      </div>
    </form>
  </ui-modal>

  <!-- ADICIONAR PROYECTO -->
  <ui-modal [isOpen]="modalProjectOpen" [class.projects-list-modal]="modalProjectOption" *ngIf="nuProject">
    <h2>{{this.modalProjectOption ? 'Adicionar proyecto' : 'Nuevo Projecto'}}</h2>
    <form #projectForm="ngForm" class="projects-list-form" (ngSubmit)="addOrderProject($event)">
      <div class="projects-list" *ngIf="modalProjectOption">
        <table class="ui-table">
          <thead>
            <tr>
              <th></th>
              <th>Proyecto</th>
              <th>Vence el</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of customerProjects">
              <td>
                <span class="icon icon-rocket"></span>
              </td>
              <td (click)="goTo('/tasks/projects/' + p.id )">{{ p.name }}</td>
              <td>{{ p.due_date | date: 'dd/MM/yyyy' }}</td>
              <td>
                <ui-btn (uiClick)="addOrderProject($event, p)" flat notab> Seleccionar </ui-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!modalProjectOption">
        <ui-input label="Nombre" name="project_name" [(ngModel)]="nuProject.name" required></ui-input>
        <ui-datepicker label="Vence el" name="project_due_date" [(ngModel)]="nuProject.due_date"></ui-datepicker>
        <ui-input type="textarea" label="Detalles" name="project_summary" [(ngModel)]="nuProject.summary"></ui-input>
      </div>
      <div class="margin-top--double text-end">
        <ui-btn (uiClick)="closeProjectModal()" flat notab> Cancelar </ui-btn>
        <ui-btn (uiClick)="this.modalProjectOption = !this.modalProjectOption" flat notab>
          {{this.modalProjectOption ? 'Nuevo' : 'Atrás'}}
        </ui-btn>
        <ui-btn type="submit" icon="rocket" [disabled]="!projectForm.form.valid" *ngIf="!modalProjectOption">
          Nuevo
        </ui-btn>
      </div>
    </form>
  </ui-modal>

  <!-- ADICIONAR ORDEN DE VENTA -->
  <ui-modal [isOpen]="modalSellOrdersOpen" *ngIf="canShowAddSellOrders">
    <h2>Adicionar orden de venta</h2>
    <form #projectForm="ngForm" class="buy-order-list-form">
      <div class="buy-order-list-filter">
        <ui-input
          class="buy-order-list-filter-input"
          label="Numero de orden"
          [(ngModel)]="addSellOrderFilter"
          name="name-filter"
        ></ui-input>
        <ui-btn (uiClick)="searchSellOrders()" flat notab> Buscar </ui-btn>
      </div>
      <div class="projects-list">
        <ng-container *ngIf="!addSellOrdersList.length">
          <div colspan="4">No se han encontrado órdenes</div>
        </ng-container>
        <ng-container *ngIf="addSellOrdersList.length">
          <table class="ui-table">
            <thead>
              <tr>
                <th></th>
                <th>ORDEN NO</th>
                <th>NOMBRE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of addSellOrdersList">
                <td>
                  <span class="icon icon-orders"></span>
                </td>
                <td>{{ order.sequence }}</td>
                <td>{{ order.name }}</td>
                <td>
                  <ui-btn [disabled]="canSelectSellOrder(order)" (uiClick)="addSellOrder(order)" flat notab>
                    Seleccionar
                  </ui-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>

      <div class="margin-top--double text-end">
        <ui-btn (uiClick)="closeAddSellOrderModal()" flat notab> Cancelar </ui-btn>
      </div>
    </form>
  </ui-modal>

  <ui-modal [isOpen]="modalConditionOpen" *ngIf="nuOrderItemCondition">
    <h2>{{this.modalConditionTitle}}</h2>
    <form #conditionForm="ngForm" (ngSubmit)="addOrEditItemCondition($event)">
      <ui-select
        name="condition_id"
        [ngModel]="nuOrderItemCondition.condition_id"
        label="Condición"
        [options]="conditionsOpts"
        (ngModelChange)="conditionSelectChangeHandler($event)"
      >
      </ui-select>
      <ui-input type="number" label="Porcentaje" name="rate" [(ngModel)]="nuOrderItemCondition.rate" [readonly]="true">
      </ui-input>
      <div class="margin-top--double text-end">
        <ui-btn (uiClick)="modalConditionOpen = false" flat notab> Cancelar </ui-btn>
        <ui-btn type="submit" [disabled]="!conditionForm.form.valid"> {{this.modalConditionSubmit}} </ui-btn>
      </div>
    </form>
  </ui-modal>

  <ui-modal [isOpen]="confirmDialogOpen">
    <h2>¿Confirmas que deseas eliminar el item?</h2>
    <div class="margin-top text-end">
      <ui-btn (uiClick)="this.confirmDialogOpen = false" flat notab> Cancelar </ui-btn>
      <ui-btn (uiClick)="deleteOrderItem()" icon="delete" color="error"> Eliminar </ui-btn>
    </div>
  </ui-modal>

  <ui-modal [isOpen]="conditionDialogOpen">
    <h2>¿Confirmas que deseas eliminar la condición de este item?</h2>
    <div class="margin-top text-end">
      <ui-btn (uiClick)="this.conditionDialogOpen = false" flat notab> Cancelar </ui-btn>
      <ui-btn (uiClick)="deleteOrderCondition()" icon="delete" color="error"> Eliminar </ui-btn>
    </div>
  </ui-modal>

  <ui-modal [isOpen]="modalDialogOpen">
    <h2>¿Confirmas que deseas abrir la orden para modificaciones?</h2>
    <div class="margin-top text-end">
      <ui-btn (uiClick)="this.modalDialogOpen = false" flat notab> Cancelar </ui-btn>
      <ui-btn (uiClick)="openOrder()" icon="check"> ¡Abrir orden! </ui-btn>
    </div>
  </ui-modal>
</div>

<ui-modal [isOpen]="finishOrderDialogOpen">
  <h2>Hey! ¿Confirmas que vas a finalizar la orden?</h2>
  <div class="margin-top text-end">
    <ui-btn (uiClick)="this.finishOrderDialogOpen = false" flat notab> Cancelar </ui-btn>
    <ui-btn (uiClick)="updateOrderStatus('finished')" icon="check"> Finalizar orden </ui-btn>
  </div>
</ui-modal>

<ui-modal [isOpen]="cancelOrderDialogOpen">
  <h2>¿Confirmas que deseas cancelar la orden?</h2>
  <div class="margin-top text-end">
    <ui-btn (uiClick)="this.cancelOrderDialogOpen = false" flat notab> Cancelar </ui-btn>
    <ui-btn (uiClick)="updateOrderStatus('canceled')" icon="check"> ¡Confirmo! </ui-btn>
  </div>
</ui-modal>

<div class="ui-speed-dial" *ngIf="order">
  <div class="ui-speed-dial__secondary">
    <!-- Generar PDF -->
    <ui-btn
      *ngIf="canDownloadPDF && order.status !== 'canceled'"
      fab
      color="info"
      (uiClick)="generatePDF()"
      hint="{{this.downloadPDFText}}"
    >
      <span class="icon icon-file"></span>
    </ui-btn>
    <!-- Enviar PDF correo -->
    <ui-btn
      *ngIf="mode === 'sell' && isAuthorized() && order.status != 'finished' && order.status !== 'canceled'"
      fab
      color="info"
      (uiClick)="openSendPDFModal()"
      hint="{{this.sendPDFText}}"
    >
      <span class="icon icon-mail"></span>
    </ui-btn>
    <!-- Adicionar proyecto -->
    <ui-btn
      *ngIf="mode === 'sell' && order.status != 'finished' && order.status != 'canceled'"
      fab
      color="info"
      (uiClick)="showProjectModal()"
      hint="Adicionar proyecto"
      icon="rocket"
    >
    </ui-btn>
    <!-- Adicionar orden de compra -->
    <ui-btn
      *ngIf="canShowAddSellOrders"
      fab
      color="info"
      (uiClick)="showSellOrdersModal()"
      hint="Adicionar orden de compra"
      icon="orders"
    >
    </ui-btn>
    <!-- Ver como orden -->
    <ui-btn
      *ngIf="mode === 'sell' && boQuoteDisplay == true && isAuthorized()"
      fab
      color="warning"
      (uiClick)="quoteDisplay(false)"
      hint="Ver como orden"
    >
      <span class="icon icon-more-horiz"></span>
    </ui-btn>
    <!-- Ver valores -->
    <ui-btn
      *ngIf="mode === 'sell' && boQuoteDisplay == false && isAuthorized()"
      fab
      color="warning"
      (uiClick)="quoteDisplay(true)"
      hint="Ver valores"
    >
      <span class="icon icon-more-horiz"></span>
    </ui-btn>
    <!-- Cancelar -->
    <ui-btn
      *ngIf="(isAuthorized() || mode === 'buy') && order.status != 'finished' && order.status != 'canceled'"
      fab
      color="error"
      (uiClick)="showCancelModal()"
      [hint]="mode === 'buy' ? 'Cancelar' : 'Cancelar orden'"
      icon="close"
    >
    </ui-btn>
    <!-- Finalizar -->
    <ui-btn
      *ngIf="isAuthorized() && order.status !== 'finished' && order.status !== 'canceled'"
      fab
      color="success"
      (uiClick)="showFinishOrderModal()"
      [hint]="mode === 'buy' ? 'Finalizar' : 'Finalizar orden'"
      icon="check"
    >
    </ui-btn>
    <!-- Aprobar -->
    <ui-btn
      *ngIf="mode == 'sell' && currentUserRole == 'admin' && (order.status == 'initial' || order.status == 'closed')"
      fab
      color="success"
      (uiClick)="approveOrder()"
      hint="Aprobar"
    >
      <span class="icon icon-thumb-up"></span>
    </ui-btn>
    <!-- Cerrar -->
    <ui-btn
      *ngIf="mode == 'sell' && isAuthorized() && order.status == 'initial'"
      fab
      color="success"
      (uiClick)="updateOrderStatus('closed')"
      [hint]="mode === 'buy' ? 'Cerrar' : 'Cerrar orden'"
      icon="locked"
    >
    </ui-btn>
    <!-- Abrir admin -->
    <ui-btn
      *ngIf="canOpenOrder(order)"
      fab
      color="warning"
      (uiClick)="showOpenOrderModal()"
      [hint]="mode === 'buy' ? 'Abrir' : 'Abrir orden'"
      icon="locked"
    >
    </ui-btn>
    <!-- Abrir -->
    <ui-btn
      *ngIf="isAuthorized() && order.status == 'closed'"
      fab
      color="warning"
      (uiClick)="showOpenOrderModal()"
      [hint]="mode === 'buy' ? 'Abrir' : 'Abrir orden'"
      icon="locked"
    >
    </ui-btn>
    <!-- Editar -->
    <ui-btn
      *ngIf="isAuthorized() && order.status == 'initial'"
      fab
      color="accent"
      (uiClick)="goTo(orderEditLink + (order?.id || 0) + '/edit')"
      [hint]="mode === 'buy' ? 'Editar' : 'Editar orden'"
      icon="edit"
    >
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <!-- Abrir menú -->
    <ui-btn fab color="accent" hint="Opciones" icon="menu"> </ui-btn>
  </div>
</div>

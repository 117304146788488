import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class PurchaseOrderItemConditionsService extends ApiService {
  constructor(private http: HttpX, private auth: AuthService) {
    super('purchase_order_item', 'purchase_order_item/conditions', http, auth);
  }
  create() {
    return {};
  }
}

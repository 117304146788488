<ui-toolbar #toolbar (action)="ui.sidebar.toggle()">
  <div class="ui-toolbar-content"></div>
  <div class="ui-toolbar-actions">
    <ui-user-actions [userData]="userData()" (logoutClick)="logOut()"></ui-user-actions>
    <ui-app-selector [userData]="userData()"></ui-app-selector>
  </div>
</ui-toolbar>
<ui-sidebar #sidebar></ui-sidebar>

<div class="ui-main-container">
  <router-outlet></router-outlet>
</div>

<ui-modal class="first-time-message" [isOpen]="showFirstTimeMessage">
  <div class="title">
    <h2 class="alpha">¡Bienvenid@ a OnTask!</h2>
    <span class="text-center">
      ¡Hola, {{ userData().first_name | titlecase}}! Para nosotros es un honor darte la
      bienvenida a <b>OnTask</b>. <br />Nuestro compromiso es entregar un servicio muy
      intuitivo y que agregue un alto valor a tu empresa.
    </span>
  </div>
  <div class="actions">
    <ui-btn (uiClick)="closeWelcomeDialog()">Continuar</ui-btn>
    <ui-btn (uiClick)="openHelpBlog()" class="margin-left" flat>¿Cómo comenzar?</ui-btn>
  </div>
</ui-modal>

<ui-alert #alert></ui-alert>

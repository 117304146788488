import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService, ICollectionResponse } from './api.service';
import { AuthService } from './auth.service';
import { API_URL } from '../../shared/constants';
import { ISupplier } from './suppliers.service';
import { IOrder } from './orders.service';

type PurchaseOrderStatus = 'approved' | 'finished' | 'closed' | 'initial';

export interface IPurchaseOrder {
  id?: number;
  name: string;
  summary: string;
  status: PurchaseOrderStatus;
  approved_at?: string;
  finished_at?: string;
  canceled_at?: string;
  registered_by_id?: number;
  approved_by_id?: number;
  supplier_id?: number;
  payment_condition_id?: number;
  sequence: number;
  organization_id: number;
  supplier: ISupplier;
}

@Injectable()
export class PurchaseOrdersService extends ApiService {
  private baseOrder = {
    name: '',
    status: '',
    registered_by_id: null,
    summary: '',
  };
  constructor(private http: HttpX, private auth: AuthService) {
    super('purchase_order', 'purchase_orders', http, auth);
  }

  create(opts = {}) {
    return {
      ...this.baseOrder,
      ...opts,
    };
  }

  updateStatus(order: IPurchaseOrder, status: PurchaseOrderStatus) {
    order.status = status;
    if (status === 'approved') {
      order.approved_by_id = this.auth.getCurrentUser().id;
    }
    return this.save(order);
  }

  ordersByProvider(providerId) {
    return this.fetch(`?supplier_name_cont=${providerId}`);
  }

  validate_approval(orderId) {
    return this.http.get(API_URL + 'orders/' + orderId + '/validate_approval');
  }

  addBuyOrder(purchase_order_id, order_id) {
    return this.http.post(API_URL + 'purchase_order_sales/', {
      purchase_order_sale: {
        purchase_order_id,
        order_id,
      },
    });
  }

  async saleOrders(orderId: string, extras: string): Promise<ICollectionResponse<IOrder>> {
    return super.fetch(extras, { baseUrl: `purchase_orders/${orderId}/orders` });
  }

  orderStatusDesc = {
    initial: 'Registrada',
    closed: 'Cerrada',
    approved: 'Aprobada',
    finished: 'Finalizada',
    canceled: 'Cancelada',
  };

  async fetch(extras?: string): Promise<ICollectionResponse<IPurchaseOrder>> {
    return super.fetch(extras);
  }
}

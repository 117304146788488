<div class="sign-up" *ngIf="!blank">
  <div class="sign-up__content">
    <div class="top-link" *ngIf="!isTokenSent && !isInvalidToken && !isLoading" @fadeIn>
      <span>¿Tienes una cuenta? <a routerLink="/">Iniciar sesión</a>.</span>
    </div>
    <div
      class="form-helper-wrapper"
      [ngStyle]="(isTokenSent || isTokenInvalid) && {'margin-bottom': 0}"
    >
      <div
        *ngIf="!token && authType === 'password' && !isInvalidToken && !isLoading"
        class="form-wrapper"
        @slideUp
      >
        <h2 class="alpha text-center margin-bottom--double">
          Bienvenido a <b>OnTask</b>
        </h2>
        <div *ngIf="!isTokenSent" class="form-content">
          <h2 class="gamma">Crea tu cuenta</h2>
          <ui-btn
            (uiClick)="signUpGoogleHandler()"
            color="plain"
            textRaw
            image="assets/images/google-logo.svg"
          >
            Registrarme con Google
          </ui-btn>
          <div class="or-divider">
            <div></div>
            <span>O</span>
            <div></div>
          </div>
          <form [formGroup]="firstUserForm" class="ui-flex-form column-group">
            <ui-input
              label="Dirección de correo"
              formControlName="email"
              name="name"
              type="email"
            ></ui-input>
            <ui-btn
              (uiClick)="tokenRequesthandler($event)"
              [disabled]="!firstUserForm.valid"
              textRaw
              >Registrarme</ui-btn
            >
          </form>
        </div>
        <span class="text-center" *ngIf="isTokenSent">
          {{ 'Revisa tu bandeja de entrada para continuar con el registro.' }}
        </span>
      </div>
      <div
        *ngIf="(token || authType === 'google') && !isInvalidToken && !isLoading"
        class="form-wrapper second-form"
        [formGroup]="newUserForm"
        @fadeIn
      >
        <span
          class="icon icon-arrow-back"
          [class.disabled]="currentStep === 0"
          (click)="currentStep === 1 && toggleStep()"
        >
        </span>
        <div *ngIf="currentStep === 0" class="form-content" @slideRight>
          <h2 class="alpha margin-bottom--double">Sobre ti</h2>
          <form class="ui-flex-form column-group" formGroupName="user" autocomplete="off">
            <div [className]="isLowerThanSm ? 'column-group' : 'row-group'">
              <ui-input
                label="Nombre"
                formControlName="first_name"
                name="first_name"
                required
              ></ui-input>
              <ui-input
                label="Apellido"
                formControlName="last_name"
                name="last_name"
                required
              ></ui-input>
            </div>
            <ui-input
              *ngIf="authType === 'password'"
              label="Contraseña"
              formControlName="password"
              name="password"
              type="password"
              visiblePassword="true"
            >
            </ui-input>
          </form>
        </div>
        <div *ngIf="currentStep === 1" class="form-content" @slideLeft>
          <h2 class="alpha margin-bottom--double">Sobre tu empresa</h2>
          <form class="ui-flex-form column-group" formGroupName="business">
            <ui-input
              label="Nombre de tu empresa"
              formControlName="business_name"
              name="business_name"
              required
            ></ui-input>
            <ui-input
              label="Nombre de tu área o departamento"
              formControlName="group_name"
              name="group_name"
              required
            ></ui-input>
            <ui-check
              [formControl]="newUserForm.controls.termsConditions"
              name="termsConditions"
            >
              <label label-content>
                Acepto los
                <a href="https://ontask.co/terminos-de-uso.html" target="_blank"
                  >términos de uso</a
                >.
                <!--y la <a routerLink="/privacy-policy">política de privacidad</a>.-->
              </label>
            </ui-check>
            <ui-btn
              (uiClick)="signUpHandler($event)"
              [disabled]="!newUserForm.valid"
              textRaw
              >Comenzar</ui-btn
            >
          </form>
        </div>
        <span
          class="icon icon-arrow-forward"
          [class.disabled]="currentStep === 1 || !isFirstStepValid"
          (click)="currentStep === 0 && isFirstStepValid && toggleStep()"
        >
        </span>
      </div>
      <div *ngIf="isInvalidToken" class="text-justify">
        <ng-template [ngIf]="authType === 'password'" [ngIfElse]="googleAuth">
          Este enlace de confirmación de e-mail expiró o es inválido. Por favor,
          <a (click)="resetFlow()"> regístrate nuevamente</a>.
        </ng-template>
        <ng-template #googleAuth>
          Su autenticación con Google expiró o es inválida. Por favor,
          <a (click)="resetFlow()"> autentícate nuevamente</a>.
        </ng-template>
      </div>
      <ui-spinner *ngIf="isLoading"></ui-spinner>
    </div>
  </div>
  <div class="sign-up__image"></div>
</div>

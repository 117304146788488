import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncPipe } from './trunc.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';

import { MatPaginatorIntlEsService } from './services/mat-paginator-intl-es.service';
import {
  OrderByPipe,
  FilterByPipe,
  OrderStatusPipe,
  ProductStatusPipe,
  ProjectStatusPipe,
  TasksOrderByPipe,
  TaskStatusPipe,
} from './pipes/';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
  ],
  declarations: [
    OrderByPipe,
    FilterByPipe,
    TruncPipe,
    OrderStatusPipe,
    ProductStatusPipe,
    ProjectStatusPipe,
    TasksOrderByPipe,
    TaskStatusPipe,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    OrderByPipe,
    FilterByPipe,
    TruncPipe,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatExpansionModule,
    OrderStatusPipe,
    ProductStatusPipe,
    ProjectStatusPipe,
    TasksOrderByPipe,
    TaskStatusPipe,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlEsService,
    },
  ],
})
export class SharedModule {}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanFieldValue } from '../../shared/annotations';

let uniqueId = 0;

interface IOption {
  value: any;
  label: string;
}

@Component({
  selector: 'ui-select',
  templateUrl: 'ui-select.template.html',
  styleUrls: ['ui-select.styles.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UiSelectComponent, multi: true }],
})
export class UiSelectComponent implements ControlValueAccessor {
  @ViewChild('selectModel', { static: true }) public selectModel: any;
  @Output() public focus = new EventEmitter();
  @Output() public blur = new EventEmitter();
  @Input('elemId') public elemId: string = `ui-select-${uniqueId++}`;
  @Input('name') public name: string;
  @Input() public label: string;
  @Input() public width: string;
  @Input() public placeholder: string;
  @Input() public options: IOption[] = [];
  @Input() @BooleanFieldValue() public required: boolean = false;
  @Input() @BooleanFieldValue() public disabled: boolean = false;
  @Input() @BooleanFieldValue() public manualHighlight: boolean = false;
  public isActive: boolean;

  public value: any;

  constructor() {}

  public writeValue(value: any) {
    this.value = value;
  }
  public registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: (value: any) => void) {
    this.propagateTouch = fn;
  }

  public hasErrors() {
    return this.selectModel.touched && this.selectModel.invalid;
  }

  public ngModelChangeHandler($event) {
    this.value = $event;
    this.propagateChange(this.value);
  }
  public focusHandler($event) {
    if (!this.manualHighlight) {
      this.isActive = true;
    }
    this.focus.emit($event);
  }
  public blurHandler($event) {
    if (!this.manualHighlight) {
      this.isActive = false;
    }
    this.blur.emit($event);
  }
  private propagateChange: any = () => {};
  private propagateTouch: any = () => {};
}

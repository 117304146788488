import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OrderByPipe } from '../../shared/pipes/';

import { SuppliersService } from '../../core/services/suppliers.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'suppliers-index',
  templateUrl: 'suppliers-index.template.html',
})
export class SuppliersIndexComponent implements OnInit {
  public suppliers;
  private isAuthorized = false;
  private orderBy = new OrderByPipe();

  constructor(
    private suppliersSrv: SuppliersService,
    private router: Router,
    private ui: UiService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.ui.toolbar.title = 'Proveedores';
    this.isAuthorized = this.auth.isAuthorized();
    this.suppliersSrv.fetch().then((collection) => {
      this.suppliers = this.orderBy.transform(collection.collection.asArray(), 'name');
    });
  }

  goTo(url) {
    this.router.navigate([url]);
  }

  createSupplierRedirect() {
    if (this.isAuthorized) {
      this.goTo(`/tasks/suppliers/new`);
    } else {
      this.ui.snackbar.show('No tienes permisos para crear proveedores.');
    }
  }
}

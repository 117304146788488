<form #taskForm="ngForm" (submit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="!isLoading">
    <h1 class="text-center" [class.color-disabled]="!task.name">
      {{ task.name || '¿Qué necesita tu proyecto?'}}
    </h1>
    <ui-input type="text" label="Necesitamos" name="task_name" [(ngModel)]="task.name"
      placeholder="Agrega el nombre de tu tarea aquí" required>
    </ui-input>
    <ui-input type="textarea" label="Detalles" name="task_description" [(ngModel)]="task.description"
      placeholder="Coloca los detalles de tu tarea aquí">
    </ui-input>
    <ui-datepicker label="Vence el" name="task_due_date" [(ngModel)]="task.due_date" required quickActions>
    </ui-datepicker>
    <div class="ui-form-group one-half">
      <ui-autocomplete name="task_user" label="Asignado a" [(ngModel)]="taskUser.text" type="user" [options]="usersOpts"
        placeholder="Selecciona un responsable para tu tarea" (uiModelChange)="taskUserChangeHandler($event)"
        [initialId]="taskUser.initialId" required>
      </ui-autocomplete>
      <ui-select name="task_project_id" label="Proyecto" [(ngModel)]="task.project_id" [options]="projectsOpts"
        placeholder="¿Con qué proyecto está relacionada tu tarea?">
      </ui-select>
    </div>
  </div>
  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="saveTask()" hint="Grabar tarea" [disabled]="!taskForm.form.valid"
        icon="save">
      </ui-btn>
    </div>
  </div>
</form>
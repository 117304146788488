<form #orderForm="ngForm" (submit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="order && !isLoading">
    <h1 class="text-center" [class.color-disabled]="!order.name">
      {{ order.name || 'Agrega un nombre de la orden de ' + modeTexts.modeText.toLowerCase()}}
    </h1>
    <ui-input label="Nombre" name="order_name" [(ngModel)]="order.name" placeholder="Orden de ejemplo" required>
    </ui-input>
    <ui-input
      type="textarea"
      label="Descripción"
      name="order_summary"
      [(ngModel)]="order.summary"
      placeholder="Añade la descripción de tu orden aquí"
    >
    </ui-input>
    <div class="ui-form-group">
      <ui-autocomplete-async
        name="order_customer"
        label="{{modeTexts.userLabel}}"
        initialValue="{{order.supplier_id || order.customer_id}}"
        [(ngModel)]="orderCustomerName"
        model="{{mode === 'buy' ? 'provider' : 'customer'}}"
        placeholder="Selecciona un {{modeTexts.userLabel | lowercase}} de la lista"
        (uiModelChange)="orderCustomerChangeHandler($event)"
        required
      >
      </ui-autocomplete-async>
    </div>
    <div class="ui-form-group">
      <ui-select
        name="order_payment_condition_id"
        label="Condición de pago"
        [(ngModel)]="order.payment_condition_id"
        [options]="paymentConditionsOpts"
        placeholder="Selecciona la condición de pago"
      >
      </ui-select>
      <ui-input
        *ngIf="mode === 'buy'"
        type="textarea"
        name="order_payment_condition_text"
        label="Notas de condición de pago"
        [(ngModel)]="order.payment_condition_text"
        placeholder="Añade las notas sobre tu condición de pago aquí"
      >
      </ui-input>
    </div>
  </div>
  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn
        fab
        color="accent"
        (uiClick)="saveOrder()"
        [hint]="mode === 'buy' ? 'Guardar' : 'Guardar orden'"
        [disabled]="!orderForm.form.valid"
        icon="save"
      >
      </ui-btn>
    </div>
  </div>
</form>

<form #supplierForm="ngForm" (ngSubmit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="supplier">
    <h1 class="text-center"
      [class.color-disabled]="!supplier.name">
      {{ supplier.name || 'Agrega el nombre del proveedor' }}
    </h1>
    <ui-input label="Nombre" name="supplier_name" [(ngModel)]="supplier.name"
      placeholder="Proveedor de ejemplo" required>
    </ui-input>
    <div class="ui-form-group one-half">
      <ui-select label="Tipo Id" name="supplier_identification_type"
        [(ngModel)]="supplier.identification_type" placeholder="Cédula, NIT, Pasaporte, etc."
        [options]="suppliersSrv.idTypes">
      </ui-select>
      <ui-input label="Id" name="supplier_identificacion" [(ngModel)]="supplier.identification"
        placeholder="4815162342" required>
      </ui-input>
      <ui-input label="Razón social" name="supplier_legal_name" [(ngModel)]="supplier.legal_name"
        placeholder="Razón social del proveedor">
      </ui-input>
      <ui-input label="Correo electrónico" name="supplier_email" [(ngModel)]="supplier.email"
        placeholder="ejemplo@email.com" required>
      </ui-input>
      <ui-input label="Telefono" name="supplier_phone" [(ngModel)]="supplier.phone"
        placeholder="(+57) 310 123 1234" required>
      </ui-input>
      <ui-input label="Ciudad" name="supplier_city" [(ngModel)]="supplier.city"
        placeholder="Bogotá, Lima, Quito" required>
      </ui-input>
      <ui-input label="Dirección" name="supplier_address" [(ngModel)]="supplier.address"
        placeholder="P. Sherman Calle Wallaby 42 Sydney" required>
      </ui-input>
    </div>
    <ui-input type="textarea" label="Notas" name="supplier_summary" [(ngModel)]="supplier.summary"
      placeholder="Adiciona tus notas del proveedor aquí">
    </ui-input>
  </div>

  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="saveSupplier()" hint="Guardar proveedor"
        [disabled]="!supplierForm.form.valid" icon="save">
      </ui-btn>
    </div>
  </div>
</form>

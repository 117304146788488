import { Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { BooleanFieldValue } from '../../shared/annotations';
import { UiInputComponent } from '../ui-input/ui-input.component';

let uniqueId = 0;

@Component({
  selector: 'ui-datepicker',
  templateUrl: 'ui-datepicker.template.html',
  styleUrls: ['ui-datepicker.styles.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UiDatepickerComponent, multi: true }],
})
export class UiDatepickerComponent implements ControlValueAccessor {
  @Input() set formControlRef(formControlRef: FormControl) {
    this._control = formControlRef;
  }

  get control() {
    return this._control;
  }
  @ViewChild('uiInputEl') public uiInputEl: UiInputComponent;
  @Input('elemId') public elemId: string = `ui-datepicker-${uniqueId++}`;
  @Input('name') public name: string;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() @BooleanFieldValue() public quickActions: boolean = false;
  @Input() @BooleanFieldValue() public required: boolean = false;
  @Input() @BooleanFieldValue() public readonly: boolean = false;
  @Input() @BooleanFieldValue() public disabled: boolean = false;
  public calendarOpen = false;
  public inputFocused = false;
  public displayDate = '';
  public displayFormat = 'DD/MM/YYYY';
  public value: any;
  private entryFormat = 'YYYY-MM-DD';
  private previousDisplayDate = '';
  private _control: FormControl;

  private quickOptions = [
    {
      label: 'Hoy',
      value: moment(),
      icon: 'today',
    },
    {
      label: 'Mañana',
      value: moment().add(1, 'days'),
      icon: 'sun',
    },
    {
      label: 'Próxima semana',
      value: moment().add(7, 'days').startOf('isoWeek'),
      icon: 'next-week',
    },
  ];

  constructor() {}

  public writeValue(value: any) {
    if (value !== this.value) {
      this.value = value;
      const tempDate = moment(this.value, this.entryFormat);
      if (tempDate.isValid()) {
        this.displayDate = tempDate.format(this.displayFormat);
        this.previousDisplayDate = this.displayDate;
      } else if (!value) {
        this.displayDate = '';
        this.previousDisplayDate = this.displayDate;
      }
    }
  }
  public registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: (value: any) => void) {
    this.propagateTouch = fn;
  }

  public assignDate(newDate) {
    if (newDate) {
      const tempDate = moment(newDate, this.displayFormat);
      if (tempDate.isValid()) {
        this.value = tempDate.toISOString().split('T')[0];
        this.displayDate = tempDate.format(this.displayFormat);
        this.previousDisplayDate = this.displayDate;
        this.propagateChange(this.value);
      } else {
        this.displayDate = this.previousDisplayDate;
      }
    } else {
      this.value = '';
      this.previousDisplayDate = '';
      this.propagateChange('');
    }
  }
  public changeHandler($event) {
    // $event.preventDefault();
    // $event.stopPropagation();
    this.assignDate($event.target.value);
  }
  public calendarChangeHandler($event) {
    this.uiInputEl.inputEl.nativeElement.focus();
    this.calendarOpen = false;

    this.value = $event;
    const tempDate = moment($event, this.entryFormat);
    this.displayDate = tempDate.format(this.displayFormat);
    this.previousDisplayDate = this.displayDate;
    this.propagateChange($event);
  }
  public iconClickHandler($event) {
    this.calendarOpen = true;
  }
  public focusHandler($event) {
    // if (!this.calendarOpen) this.calendarOpen = true;
    this.inputFocused = true;
  }
  public blurHandler($event) {
    this.inputFocused = false;
    this.propagateTouch();
  }
  public keydownHandler($event) {
    switch ($event.keyCode) {
      case 9: // tab
      case 27: // esc
        this.calendarOpen = false;
        break;
      case 13: // enter
        this.changeHandler($event);
        break;
      case 40: // down arrow
        $event.preventDefault();
        if (!this.calendarOpen) {
          this.calendarOpen = true;
        }
        break;
    }
  }
  public handleSelectQuickOption(date) {
    this.assignDate(date);
  }
  private propagateChange: any = () => {};
  private propagateTouch: any = () => {};
}

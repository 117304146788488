<div class="ui-more-menu">
  <a class="ui-more-menu__link"
    [class.is-active]="isOpen"
    href="#" (click)="clickHandler($event)">
    <span [class]="'icon icon-' + icon"></span>
  </a>
  <ui-menu [(isOpen)]="isOpen"
    [actions]="actions"
    [params]="params"
    [alignRight]="alignRight">
  </ui-menu>
</div>

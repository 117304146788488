import { Component, OnInit } from '@angular/core';
import { PaymentConditionsService } from '../../core/services/payment-conditions.service';
import { Router } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'paymentconditions-index',
  templateUrl: 'paymentconditions-index.template.html',
})
export class PaymentConditionsIndexComponent implements OnInit {
  public paymentconditions;
  public filters = {};
  private filtered_by;
  constructor(
    private paymentconditionsSrv: PaymentConditionsService,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Condiciones de pago';
    this.paymentconditionsSrv
      .fetch()
      .then((collection) => (this.paymentconditions = collection.asArray()));
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
  }
  goTo(url) {
    this.router.navigate([url]);
  }
  filterByStatus() {
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
  }
  disableFilterBy() {
    this.filters = {};
    this.filtered_by = 'all';
  }
}

import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'ui-app-selector',
  templateUrl: 'ui-app-selector.template.html',
  styleUrls: ['ui-app-selector.styles.scss'],
})
export class UiAppSelectorComponent {
  @ViewChild('menuEl') menuEl: ElementRef;
  @Input() userData: any = {};
  private appMenuOpen = false;
  private currentMousedownHandler = null;
  private subActions: any[] = [];

  constructor() {}
  openAppMenu() {
    this.appMenuOpen = true;
    var _this = this;
    this.currentMousedownHandler = function __handler__(evt) {
      if (!_this.menuEl.nativeElement.contains(evt.target)) {
        _this.appMenuOpen = false;
        document.removeEventListener('click', __handler__, true);
        evt.preventDefault();
        evt.stopPropagation();
      }
    };
    document.addEventListener('click', this.currentMousedownHandler, true);
  }
  clickActionHandler($event, action) {
    this.appMenuOpen = !this.appMenuOpen;
    document.removeEventListener('click', this.currentMousedownHandler, true);
  }
  setSubActions(newSubActions: any[]) {
    this.subActions = newSubActions;
  }
}

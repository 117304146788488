import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiModule } from '../ui/ui.module';

import { SigninComponent } from './sessions/signin.component';
import { SignUpComponent } from './sessions/sign-up.component';

import { UiService } from './services/ui.service';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthAdminGuardService } from './services/auth-admin-guard.service';
import { AuthSudoGuardService } from './services/auth-sudo-guard.service';
import { HttpX } from './services/http-x.service';
import { ApiService } from './services/api.service';
import { DummiesService } from './services/dummies.service';
import { CommentsService } from './services/comments.service';
import { CustomersService } from './services/customers.service';
import { UsersService } from './services/users.service';
import { UserGroupsService } from './services/usergroups.service';
import { PeopleService } from './services/people.service';
import { ProjectsService } from './services/projects.service';
import { TasksService } from './services/tasks.service';
import { AttachmentsService } from './services/attachments.service';
import { ProductsService } from './services/products.service';
import { ConditionsService } from './services/conditions.service';
import { OrdersService } from './services/orders.service';
import { CostsService } from './services/costs.service';
import { OrderItemsService } from './services/order-items.service';
import { OrderItemConditionsService } from './services/order-item-conditions.service';
import { PurchaseOrderItemConditionsService } from './services/purchase-order-item-conditions.service';
import { PaymentConditionsService } from './services/payment-conditions.service';
import { SuppliersService } from './services/suppliers.service';
import { SharedModule } from '../shared/shared.module';
import { PurchaseOrdersService } from './services/purchaseOrders.service';
import { PurchaseOrderItemsService } from './services/purchase-order-items.service';

function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, RouterModule, UiModule, SharedModule],
  declarations: [SigninComponent, SignUpComponent],
  exports: [SigninComponent, SignUpComponent],
  providers: [
    UiService,
    AuthService,
    AuthGuardService,
    AuthAdminGuardService,
    AuthSudoGuardService,
    HttpX,
    ApiService,
    DummiesService,
    CommentsService,
    CustomersService,
    UsersService,
    UserGroupsService,
    PeopleService,
    TasksService,
    ProjectsService,
    AttachmentsService,
    ProductsService,
    ConditionsService,
    OrdersService,
    PurchaseOrdersService,
    CostsService,
    OrderItemsService,
    PurchaseOrderItemsService,
    OrderItemConditionsService,
    PurchaseOrderItemConditionsService,
    PaymentConditionsService,
    SuppliersService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';

import { TasksService } from '../../core/services/tasks.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

import { commaListToArray } from '../../shared/helpers';
import { OrderByPipe } from '../../shared/pipes/';
import { BooleanFieldValue } from '../../shared/annotations';

@Component({
  selector: 'task-collection',
  templateUrl: 'task-collection.template.html',
})
export class TaskCollectionComponent implements OnInit, OnChanges {
  @Input() tasks: Array<any>;
  @Input() hideColumns: string;
  @Input() columnOrder: string;
  @Input() @BooleanFieldValue() orderOnUpdate: boolean = false;
  @Input() @BooleanFieldValue() orderByDueDate: boolean = false;
  @Output() onTaskUpdate = new EventEmitter<any>();
  private orderByPipe = new OrderByPipe();
  private isAuthorized = false;
  private currentUser;
  public hideObj: any = {};
  private orderObj: any = {
    status: 1,
    user: 2,
    name: 3,
    project: 4,
    customer: 5,
    group: 6,
    assigned_at: 7,
    due_date: 8,
    time: 9,
    actions: 10,
  };
  private actionMenu = [
    {
      hideIf: (t) => t.status === 'done',
      icon: 'today',
      label: 'Hoy',
      action: (t) => this.updateDueDate(t, 0),
    },
    {
      hideIf: (t) => t.status === 'done',
      icon: 'sun',
      label: 'Mañana',
      action: (t) => this.updateDueDate(t, 1),
    },
    {
      hideIf: (t) => t.status === 'done',
      icon: 'next-week',
      label: 'Próxima semana',
      action: (t) => {
        const date = moment().add(7, 'days');
        this.updateDueDate(t, date.startOf('isoWeek').diff(moment(), 'days') + 1);
      },
    },
    {
      hideIf: (t) => t.status === 'done',
      icon: 'check',
      label: 'Finalizar',
      action: (t) => {
        this.updateTaskStatus(t, 'done');
      },
    },
    // {
    //   icon: 'person-circle',
    //   label: "Asignar a mi",
    //   action: (t) => {
    //     console.warn('estoy haciendo otra cosa', t)
    //   }
    // },
    {
      icon: 'edit',
      label: 'Editar',
      action: (t) => {
        this.goTo(`/tasks/${t.id}/edit`);
      },
    },
    {
      icon: 'caret-right',
      label: 'Ver detalle',
      action: (t) => {
        this.goTo(`/tasks/${t.id}`);
      },
    },
  ];

  constructor(
    private tasksSrv: TasksService,
    private router: Router,
    private ui: UiService,
    private auth: AuthService
  ) {
    this.isAuthorized = this.auth.isAuthorized();
    this.currentUser = this.auth.getCurrentUser();
  }

  goTo(url) {
    this.router.navigate(['tasks' + url]);
  }

  ngOnInit() {
    if (this.orderByDueDate) {
      this.tasks = this.orderByPipe.transform(this.tasks, 'due_date');
    }
  }

  ngOnChanges(changes) {
    if (changes.hideColumns) {
      this.hideObj = commaListToArray(this.hideColumns).reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
    }
    if (changes.columnOrder) {
      this.orderObj = commaListToArray(this.columnOrder).reduce((acc, item, inx) => {
        acc[item] = inx + 1;
        return acc;
      }, {});
    }
  }

  updateTaskStatus(task, newStatus) {
    if (this.isAuthorized || task.user.id == this.currentUser.id) {
      this.tasksSrv.updateStatus(task, newStatus).then(() => {
        this.ui.snackbar.show('Tarea modificada!');
      });
    } else {
      this.ui.snackbar.show('Lo siento. No puedes modificar una tarea que no está asignada a tí.');
    }
  }

  updateDueDate(task, days) {
    this.tasksSrv
      .updateDueDate(task, days)
      .then(() => {
        this.ui.snackbar.show('Fecha de vencimiento modificada!');
        if (this.orderOnUpdate) {
          this.tasks = this.orderByPipe.transform(this.tasks, 'due_date');
          return;
        }
        this.onTaskUpdate.emit();
      })
      .catch((err) => {
        this.ui.snackbar.show('Error al modificar la fecha de vencimiento.');
      });
  }
}

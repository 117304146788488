export function textClamp(str: string, maxSize: number) {
  if (str.length > maxSize) {
    const to = Math.trunc(maxSize * 2 / 3);
    const from = Math.trunc(maxSize * 1 / 3);
    return str.substr(0, to) + "…" + str.substr(str.length - from, str.length);
  }
  return str;
}

export function removeWhitespace(string: string) {
  return string.replace(/\s/g, "");
}

export function commaListToArray(string: string) {
  return removeWhitespace(string).split(",");
}

export function forEachKey(object: Object, callback: Function) {
  Object.keys(object).forEach((key) => {
    callback(key, object[key]);
  });
}


export function objectToArray(object: Object, keyName: string = "id"): Object[] {
  return Object.keys(object).map((key) => object[key]);
  //   // let newObj = {};
  //   // if (keyName) newObj[keyName] = key;
  //   // return Object.assign(newObj, object[key]);
  //   return object[key];
  // });
}

export function arrayToObject(array: Object[], keyName: string = "id"): Object {
  return array.reduce((prev, cur) => {
    prev[cur[keyName]] = cur;
    return prev;
  }, {});
}

export function replaceArray(destination: any[], source: any[]): void {
  destination.splice(0, destination.length);
  source.reduce((acum, item) => {
    acum.push(item);
    return acum;
  }, destination);
}

export function objectFirstChild(object: Object): any {
  return object ? object[Object.keys(object)[0]] : object;
}

export function objectChildrenCount(object: Object): number {
  return Object.keys(object).length;
}

export function stringKeyValue(obj: Object, key: string): Object {
  const keys = key.split(".");
  let i = 1;
  let acc = obj[keys[0]] || "";
  while (acc && i < keys.length) {
    acc = acc[keys[i++]] || "";
  }
  return acc;
  // return acc;
  // return keys.reduce((prev, cur) => {
  //   return prev[cur];
  // }, obj);
}

export function filterArrayBy(array: any[], criteria: any, compareOp: string = "==") {
  if (!array) { return array; }
  const criteriaKeys = Object.keys(criteria);
  return array.filter((item) => {
    let ret = true, inx = 0;
    while (inx < criteriaKeys.length && ret) {
      const criteriaKey = criteriaKeys[inx];
      const itemVal = stringKeyValue(item, criteriaKey);

      switch (compareOp) {
        case "==":
          ret = itemVal === criteria[criteriaKey];
          break;
        case "!=":
          ret = itemVal !== criteria[criteriaKey];
          break;
      }

      inx++;
    }
    return ret;
  });
}

// searches an item, or all items of an array, inside another array.
export function isIn(array: any[], toSearch: any, caseSensitive = false) {
  let i = 0;
  const searchItems = [].concat(toSearch);
  while (i < searchItems.length) {
    let j = 0;
    let isIn = false;
    while (j < array.length && !isIn) {
      isIn = caseSensitive ? (array[j] === searchItems[i])
                           : (array[j].toUpperCase() === searchItems[i].toUpperCase());
      j++;
    }
    if (j === array.length && !isIn) { return false; }
    i++;
  }
  return true;
}

export function orderArrayBy(array: any[], criteria: string) {
  if (!array) { return array; }
  const criteriaArray = criteria.replace(/ /g, "").split(",");
  return array.sort((a, b) => {
    let cinx = 0,
      ret = 0;
    while (ret === 0 && cinx < criteriaArray.length) {
      let inverse, aval, bval, criteriaValue;
      inverse = (criteriaArray[cinx][0] === "-") ? -1 : 1;
      criteriaValue = (inverse === -1) ? criteriaArray[cinx].substr(1) : criteriaArray[cinx];
      aval = stringKeyValue(a, criteriaValue),
      bval = stringKeyValue(b, criteriaValue);
      ret = (aval < bval ? -1 * inverse : (aval > bval ? 1 * inverse : 0));
      cinx++;
    }
    return ret;
  });
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function roundNumber(orderRoundedValues: boolean, n: any) {
  if (n === null) { n = 0; }
  if (orderRoundedValues) {
    return Math.round(n);
  } else {
    return ( n === +n && n !== (n | 0)) ? parseFloat(n).toFixed(2) : n;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';

import { DummiesAppRoutesModule } from './dummies-app-routes.module';

import { DummiesAppComponent } from './dummies-app.component';

import { DummiesIndexComponent } from './dummies/dummies-index.component';
import { DummiesShowComponent } from './dummies/dummies-show.component';
import { DummiesCreateComponent } from './dummies/dummies-create.component';
import { DummiesEditComponent } from './dummies/dummies-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    UiModule,
    DummiesAppRoutesModule
  ],
  declarations: [
    DummiesAppComponent,
    DummiesIndexComponent,
    DummiesShowComponent,
    DummiesCreateComponent,
    DummiesEditComponent
  ],
  exports: [
    DummiesAppComponent,
    DummiesIndexComponent,
    DummiesShowComponent,
    DummiesCreateComponent,
    DummiesEditComponent
  ],
  providers: []
})
export class DummiesAppModule { }

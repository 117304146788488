import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanFieldValue } from '../../shared/annotations';

let uniqueId = 0;

@Component({
  selector: 'ui-check',
  templateUrl: 'ui-check.template.html',
  styleUrls: ['ui-check.styles.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UiCheckComponent, multi: true }],
})
export class UiCheckComponent implements ControlValueAccessor {
  @Input() public label: string;
  @Input('inputId') public id: string = `ui-checkbox-${uniqueId++}`;
  @Input() @BooleanFieldValue() public disabled: boolean = false;
  public focused: boolean = false;

  public value: any;

  constructor() {}

  public writeValue(value: any) {
    this.value = value || false;
    if (!this.value) {
      this.propagateChange(this.value);
    }
  }
  public registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: (value: any) => void) {
    this.propagateTouch = fn;
  }
  public ngModelChangeHandler($event) {
    this.value = $event;
    this.propagateChange(this.value);
  }
  public focusHandler() {
    this.focused = true;
  }
  public blurHandler() {
    this.focused = false;
  }
  private propagateChange: any = () => {};
  private propagateTouch: any = () => {};
}

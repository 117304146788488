import { Component, OnInit, ViewChild } from '@angular/core';
import { ConditionsService } from '../../core/services/conditions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '../../core/services/users.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'conditions-show',
  templateUrl: 'conditions-show.template.html',
})
export class ConditionsShowComponent implements OnInit {
  @ViewChild('attCollectionEl') attCollectionEl;
  public condition = null;
  private currentUserRole = null;
  constructor(
    private conditionsSrv: ConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.currentUserRole = this.auth.getCurrentRole();
    this.ui.toolbar.title = 'Detalle de las condiciones';
    var conditionId = this.route.snapshot.params['id'];
    this.conditionsSrv.find(conditionId).then((condition) => {
      this.condition = condition;
    });
  }
  goTo(url) {
    this.router.navigate([url]);
  }
}

<div #wrapperEl class="ui-autocomplete ui-form-element"
  [class.disabled]="disabled"
  [class.has-errors]="hasErrors()">
  <label #labelEl
    [for]="id"
    [class.is-focused]="isFocused">
    {{ label }}{{ required ? '*' : '' }}
  </label>
  <div class="ui-autocomplete__input-container">
    <input #inputEl
      #inputModel="ngModel"
      type="text"
      [id]="id"
      [name]="name || id || ''"
      [ngModel]="uiModel"
      (ngModelChange)="inputTextChangeHandler($event)"
      [required]="required"
      [readonly]="readonly"
      [disabled]="disabled"
      [placeholder]="placeholder || ''"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
      (keydown)="keyDownHandler($event)"
      class="ui-autocomplete__input"
      autocomplete="off"/>
    <div class="ui-form-element__underline">
      <div class="ui-form-element__highlight"></div>
    </div>
    <span class="ui-autocomplete__input-arrow"></span>
  </div>
  <div class="ui-autocomplete-menu" *ngIf="filteredActions.length">
    <div #menuEl class="ui-autocomplete-menu__modal"
      [class.is-open]="menuOpen">
      <ul class="ui-autocomplete-menu__list">
        <li class="ui-autocomplete-menu__item" *ngFor="let a of filteredActions; let i = index; trackBy: index">
          <div class="ui-autocomplete-menu__row" [class.is-focused]="i === selectedItemInx"
            (click)="clickActionHandler($event, a, i)">
            <img *ngIf="type == 'user'" class="ui-autocomplete-menu__row-img" width="20px" [src]="a.item.person.avatar_url || avatarBlank" />
            <div class="ui-autocomplete-menu__columm">
              <a class="ui-autocomplete-menu__link">
                {{ a.label }}
              </a>
              <p *ngIf="a.item">{{ a.item.email }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="hasErrors()" class="ui-form-element__errors">
    <div *ngIf="checkerModel.errors.required">
      Este campo es requerido
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'users',
  templateUrl: './users.template.html',
})
export class UsersComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}

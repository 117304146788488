<h3 class="ui-panel__pre-title">
  <span class="icon icon-dashboard"></span>
  Tareas pendientes por persona
</h3>
<div class="ui-panel no-padding no-border" *ngIf="dashboardpeople">

  <table class="ui-table">
    <thead>
      <tr>
        <th class="cell--tiny"></th>
        <th>Persona</th>
        <th class="cell--small text-end">Activas</th>
        <th class="cell--small text-end">Vencidas</th>
        <th class="cell--small text-end">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let g of dashboardpeople.tasks">
        <td class="cell--clickable cell--tiny text-center"><img *ngIf="g.avatar_url"
          [src]="g.avatar_url"
          [title]="g.person"
          (click)="goTo('/tasks/users/' + g.user_id )">
        </td>
        <td (click)="goTo('/tasks/users/' + g.user_id )">{{ g.person }}</td>
        <td class="cell--small text-end">{{ g.active }}</td>
        <td class="cell--small text-end">{{ g.due }}</td>
        <td class="cell--small text-end">{{ g.total }}</td>
      </tr>
    </tbody>
  </table>
</div>

<h3 class="ui-panel__pre-title">
  <span class="icon icon-dashboard"></span>
  Tareas pendientes por grupo
</h3>
<div class="ui-panel no-padding no-border" *ngIf="dashboardgroup">

  <table class="ui-table">
    <thead>
      <tr>
        <th>Grupo</th>
        <th class="cell--small text-end">Activas</th>
        <th class="cell--small text-end">Vencidas</th>
        <th class="cell--small text-end">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let g of dashboardgroup.tasks">
        <td (click)="goTo('/tasks/usergroups/' + g.user_group_id )">{{ g.group }}</td>
        <td class="cell--small text-end">{{ g.active }}</td>
        <td class="cell--small text-end">{{ g.due }}</td>
        <td class="cell--small text-end">{{ g.total }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!--
<div class="ui-panel  no-padding">

  <div class="ui-panel__padding">
    <h3>Tareas Activas</h3>
    <ui-select name="task_order" label="Ordenar por" [(ngModel)]="order" [options]="orderOpts"></ui-select>
  </div>
  <task-collection hideColumns="status, group"
    columnOrder="customer, project, name, user, assigned_at, due_date, time"
    [tasks]="tasks | filterBy : filters | orderBy : order">
  </task-collection>

</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn [class.hidden]="!(filtered_by == 'active')" fab color="success" (uiClick)="disableFilterBy()" hint="Ver todos">
      <span class="icon icon-list-check"></span>
    </ui-btn>
    <ui-btn [class.hidden]="!(filtered_by == 'all')" fab color="warning" (uiClick)="filterByStatus()" hint="Ver solo activos">
      <span class="icon icon-list"></span>
    </ui-btn>
  </div>
</div>
-->

<!-- Se deja en comentario para pruebas
<div class="ui-panel">
  <h1>Dashboard</h1>
  <p>{{ menganito }}</p>
  <dashboard-test [(fulanito)]="menganito"></dashboard-test>
</div>
-->

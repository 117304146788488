import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService, ISingleItemResponse } from './api.service';
import { AuthService } from './auth.service';

export interface IPaymentCondition {
  id: number;
  name: string;
  order_text: string;
  registered_by_id: number;
  status: 'active' | 'inactive';
}
@Injectable()
export class PaymentConditionsService extends ApiService {
  private basePaymentCondition = {
    name: '',
    status: '',
    registered_by_id: null,
    order_text: '',
  };
  constructor(private http: HttpX, private auth: AuthService) {
    super('payment_condition', 'payment_conditions', http, auth);
  }
  create(opts = {}) {
    return {
      ...this.basePaymentCondition,
      ...opts,
    };
  }
  async fetch(extras?: string): Promise<ISingleItemResponse<IPaymentCondition>> {
    return super.fetch(extras);
  }
}

<div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
  <div fxFlex="1 1 100" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container *ngIf="activeFilters > 0; else noFilters">
              <ui-badge [value]="activeFilters" small>
                Filtros
              </ui-badge>
            </ng-container>
            <ng-template #noFilters>
              Filtros
            </ng-template>
          </mat-panel-title>
          <mat-panel-description>
            Esto te permitirá filtrar las tareas según criterios
          </mat-panel-description>
        </mat-expansion-panel-header>
        <form [formGroup]="filtersForm" (ngSubmit)="filter()" fxLayout="row" fxLayoutAlign="start center"
          fxLayoutGap="10px">
          <div fxLayout="column" fxFlex="grow">
            <div fxLayout="row" fxFlex="grow" fxLayoutGap="10px">
              <ui-input label="Asignado al grupo" formControlName="taskUserGroup" fxFlex="grow">
              </ui-input>
              <ui-input label="Asignado a" formControlName="taskUser" fxFlex="grow">
              </ui-input>
              <ui-select label="Estado" fxFlex="130px" formControlName="taskStatus" [options]="TASK_STATUSES">
              </ui-select>
              <ui-select label="Ordenar por" fxFlex="130px" formControlName="tasksOrderBy"
                [options]="TASKS_ORDER_OPTIONS">
              </ui-select>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="grow">
              <ui-select label="Rangos de fechas" fxFlex="200px" formControlName="dateRange"
                [options]="DATE_RANGE_OPTIONS">
              </ui-select>
              <ui-datepicker label="Fecha de vencimiento inicial" formControlName="taskDueDateStart" fxFlex="grow">
              </ui-datepicker>
              <ui-datepicker label="Fecha de vencimiento final" formControlName="taskDueDateEnd" fxFlex="grow">
              </ui-datepicker>
            </div>
          </div>
          <ui-btn class="padding" type="submit" color="primary" hint="Filtrar" icon="filter" fab></ui-btn>
        </form>
        <div class="margin-bottom--half">
          <mat-chip-list aria-label="Filtros">
            <mat-chip *ngIf="filters.taskUserGroup as userGroup" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Asignado al grupo</b>: {{ userGroup }}
                <mat-icon matChipRemove (click)="clearFilter('taskUserGroup')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.taskUser as user" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Asignado a</b>: {{ user }}
                <mat-icon matChipRemove (click)="clearFilter('taskUser')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.taskStatus as status" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Estado</b>: {{ status | taskStatus }}
                <mat-icon matChipRemove (click)="clearFilter('taskStatus')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.tasksOrderBy as orderBy" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Ordenar por</b>: {{ orderBy | tasksOrderBy }}
                <mat-icon matChipRemove (click)="clearFilter('tasksOrderBy')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.taskDueDateStart as dueDateStart" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Fecha de vencimiento inicial</b>: {{ dueDateStart }}
                <mat-icon matChipRemove (click)="clearFilter('taskDueDateStart')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.taskDueDateEnd as dueDateEnd" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Fecha de vencimiento final</b>: {{ dueDateEnd }}
                <mat-icon matChipRemove (click)="clearFilter('taskDueDateEnd')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="ui-panel no-padding">
      <ng-container *ngIf="!isLoading; else loading">
        <task-collection hideColumns="status,group"
          columnOrder="customer, project, name, user, assigned_at, due_date, time" [tasks]="tasks"
          (onTaskUpdate)="handleTaskUpdate($event)">
        </task-collection>
        <mat-paginator [pageIndex]="paginator.pageIndex" [length]="paginator.length" [pageSize]="paginator.pageSize"
          [pageSizeOptions]="paginator.pageSizeOptions" (page)="changePage($event)">
        </mat-paginator>
      </ng-container>
      <ng-template #loading>
        <div class="padding" fxLayout="row" fxLayoutAlign="center cener">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__secondary">
    <ui-btn fab color="info" (uiClick)="downloadCSV()" hint="Descargar reporte tareas.csv">
      <span class="icon icon-file"></span>
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="createTaskRediret()" hint="Crear tarea" icon="add">
    </ui-btn>
  </div>
</div>
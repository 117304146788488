import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../core/services/products.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { ProductStatus } from '../../shared/enums';
import { ProductStatusPipe } from '../../shared/pipes/';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'products-index',
  templateUrl: 'products-index.template.html',
})
export class ProductsIndexComponent implements OnInit {
  public PRODUCT_STATUSES: any[];
  public isLoading: boolean;

  private DEFAULT_PAGE_SIZE = 25;
  private DEFAULT_PAGE_INDEX = 0;
  public paginator = {
    pageIndex: this.DEFAULT_PAGE_INDEX,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  };
  public filtersForm: FormGroup;
  public filters = {
    productName: null,
    productStatus: 'active',
  };
  private products;
  private productStatusPipe = new ProductStatusPipe();
  private noSyncForm = false;
  constructor(
    private productsSrv: ProductsService,
    private router: Router,
    private ui: UiService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.PRODUCT_STATUSES = this.productStatusesOptions;
    this.ui.toolbar.title = 'Productos';
    this.initFiltersForm();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (!this.noSyncForm && Object.keys(params).length > 0) {
        this.syncFilterWithUrl(params);
      } else {
        this.noSyncForm = false;
      }
      this.isLoading = Object.keys(params).length === 0 ? undefined : this.isLoading;
      if (this.isLoading === undefined) {
        this.updateRouterQueryParams();
      }
      this.fetch();
    });
  }

  initFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      productName: [],
      productStatus: [],
    });
  }

  fetch() {
    this.isLoading = true;
    this.productsSrv
      .fetch(this.indexQueryParams)
      .then((res) => {
        this.paginator.length = res.pagination.total;
        this.products = (<any[]>res.collection.asArray()).reverse();
        this.isLoading = false;
      })
      .catch(() => (this.isLoading = false));
  }

  changePage(paginator: any) {
    this.paginator.pageIndex = paginator.pageIndex;
    this.paginator.pageSize = paginator.pageSize;
    this.updateRouterQueryParams();
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }

  filter() {
    const value = this.filtersForm.value;
    Object.assign(this.filters, value);
    this.paginator.pageIndex = 0;
    this.noSyncForm = true;
    this.updateRouterQueryParams();
  }

  clearFilter(filterKey: 'productName' | 'productStatus') {
    this.filters[filterKey] = null;
    this.filtersForm.get(filterKey).setValue(null);
    this.filter();
  }

  get activeFilters() {
    return +!!this.filters.productName + +!!this.filters.productStatus;
  }

  private syncFilterWithUrl(queryParams: Params) {
    this.paginator.pageIndex = queryParams.page > -1 ? queryParams.page - 1 : this.DEFAULT_PAGE_INDEX;
    this.paginator.pageSize = this.isPageSizeValid(queryParams.per_page)
      ? Number(queryParams.per_page)
      : this.DEFAULT_PAGE_SIZE;
    this.filters.productName = queryParams.name;
    this.filters.productStatus = queryParams.status;
    this.updateFiltersForm();
  }

  private updateFiltersForm() {
    this.filtersForm.controls.productName.setValue(this.filters.productName);
    this.filtersForm.controls.productStatus.setValue(this.filters.productStatus);
  }

  private updateRouterQueryParams() {
    const queryParams = this.formatToQueryParams;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  private isPageSizeValid(pageSize: string) {
    return this.paginator.pageSizeOptions.indexOf(Number(pageSize)) !== -1;
  }

  private get indexQueryParams() {
    const pageSize = this.paginator.pageSize;
    const pageIndex = this.paginator.pageIndex + 1;
    const { productName, productStatus } = this.filters;
    let url = `?page=${pageIndex}&per_page=${pageSize}`;
    if (this.activeFilters > 0) {
      if (productName) {
        url += `&q[name_cont]=${productName}`;
      }
      if (productStatus) {
        url += `&q[status_eq]=${productStatus}`;
      }
    }
    return url;
  }

  private get productStatusesOptions() {
    return (<any>Object).values(ProductStatus).map((productStatus: string) => {
      return {
        label: this.productStatusPipe.transform(productStatus),
        value: productStatus,
      };
    });
  }

  private get formatToQueryParams() {
    const params = {
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
    } as Params;

    const { productName, productStatus } = this.filters;
    if (productName) {
      params.name = productName;
    }
    if (productStatus) {
      params.status = productStatus;
    }
    if (this.isLoading === undefined) {
      if (!productStatus) {
        params.status = 'active';
      }
    }
    return params;
  }
}

<div class="ui-panel" *ngIf="dummy">
  <h1>{{ dummy.first_name + ' ' + dummy.last_name }}</h1>
  <p>{{ dummy.bio }}</p>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/dummies/' + (dummy?.id || 0) + '/edit')" hint="Edit this dummy">
      <span class="icon icon-edit"></span>
    </ui-btn>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { DummiesService } from '../../core/services/dummies.service';

@Component({
  selector: 'dummies-edit',
  templateUrl: 'dummies-create-edit.template.html',
})
export class DummiesEditComponent implements OnInit {
  public dummy;
  constructor(
    private dummiesSrv: DummiesService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    var dummyId = this.route.snapshot.params['id'];
    this.dummiesSrv.find(dummyId).then((dummy) => (this.dummy = dummy));
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.saveDummy();
  }
  saveDummy() {
    this.dummiesSrv.save(this.dummy).then((dummy) => {
      this.ui.snackbar.show({
        message: 'Dummy updated!',
      });
      this.router.navigate(['/dummies/' + dummy.id]);
    });
  }
}

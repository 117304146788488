<div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
  <div fxFlex fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container *ngIf="activeFilters > 0; else noFilters">
              <ui-badge [value]="activeFilters" small> Filtros </ui-badge>
            </ng-container>
            <ng-template #noFilters> Filtros </ng-template>
          </mat-panel-title>
          <mat-panel-description>
            Esto te permitirá filtrar los clientes según criterios
          </mat-panel-description>
        </mat-expansion-panel-header>
        <form
          [formGroup]="filtersForm"
          (ngSubmit)="filter()"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <ui-input
            label="Nombre"
            fxFlex
            formControlName="customerName"
            name="customer_name"
          >
          </ui-input>
          <ui-input
            label="Razón social"
            fxFlex
            formControlName="legalName"
            name="legal_name"
          >
          </ui-input>
          <ui-btn
            class="padding"
            type="submit"
            fab
            color="primary"
            hint="Filtrar"
            icon="filter"
          >
          </ui-btn>
        </form>
        <div class="margin-bottom--half">
          <mat-chip-list aria-label="Filtros">
            <mat-chip *ngIf="filters.customerName as customer" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Nombre</b>: {{ customer }}
                <mat-icon matChipRemove (click)="clearFilter('customerName')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.legalName as legalName" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Razón social</b>: {{ legalName }}
                <mat-icon matChipRemove (click)="clearFilter('legalName')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="ui-panel no-padding">
      <ng-container *ngIf="!isLoading; else loading">
        <table class="ui-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Contacto</th>
              <th>Telefono</th>
              <th>Correo</th>
              <th>Razón social</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of customers" (click)="goTo('/tasks/customers/' + c.id )">
              <td [title]="c.name">{{ c.name | trunc : 30 }}</td>
              <td>{{ c.people[0]?.first_name }} {{ c.people[0]?.last_name }}</td>
              <td>{{ c.phone }}</td>
              <td>{{ c.email }}</td>
              <td>{{ c.legal_name }}</td>
            </tr>
          </tbody>
        </table>
        <mat-paginator
          [pageIndex]="paginator.pageIndex"
          [length]="paginator.length"
          [pageSize]="paginator.pageSize"
          [pageSizeOptions]="paginator.pageSizeOptions"
          (page)="changePage($event)"
        >
        </mat-paginator>
      </ng-container>
      <ng-template #loading>
        <div class="padding--half" fxLayout="row" fxLayoutAlign="center cener">
          <mat-progress-spinner></mat-progress-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn
      fab
      color="accent"
      (uiClick)="addCustomerRedirect()"
      hint="Crear cliente"
      icon="add"
    >
    </ui-btn>
  </div>
</div>

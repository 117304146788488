import { Component, OnInit } from '@angular/core';
import { DummiesService } from '../../core/services/dummies.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dummies-show',
  templateUrl: 'dummies-show.template.html',
})
export class DummiesShowComponent implements OnInit {
  public dummy = null;
  constructor(
    private dummiesSrv: DummiesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit() {
    var dummyId = this.route.snapshot.params['id'];
    this.dummiesSrv.find(dummyId).then((dummy) => (this.dummy = dummy));
  }
  goTo(url) {
    this.router.navigate([url]);
  }
}

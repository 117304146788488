import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'suppliers',
  templateUrl: 'suppliers.template.html',
})
export class SuppliersComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}

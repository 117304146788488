import { Component, OnInit } from '@angular/core';
import { UiService } from '../core/services/ui.service';
import { sidebarActions } from './tasks-app.config';

@Component({
  selector: 'tasks-app',
  templateUrl: './tasks-app.template.html',
  styleUrls: ['tasks-app.styles.scss'],
})
export class TasksAppComponent implements OnInit {
  constructor(private ui: UiService) {}
  ngOnInit() {
    this.ui.app.setTheme('default');
    this.ui.sidebar.setActions(sidebarActions);
  }
}

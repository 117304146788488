<form #paymentconditionForm="ngForm" (submit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="paymentcondition">
    <h1 class="text-center"
      [class.color-disabled]="!paymentcondition.name">
      {{ paymentcondition.name || 'Agrega un nombre de la condición de pago'}}
    </h1>
    <ui-input label="Nombre" name="paymentcondition_name" [(ngModel)]="paymentcondition.name"
      placeholder="Nombre de la condición de pago" required>
    </ui-input>
    <ui-input type="textarea" label="Descripción" name="paymentcondition_order_text" [(ngModel)]="paymentcondition.order_text"
      placeholder="Adiciona la descripción de tu condición aquí. Esta descripción aparecerá en la orden impresa">
    </ui-input>
  </div>

  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="savePaymentCondition()" hint="Guardar condición de pago"
        [disabled]="!paymentconditionForm.form.valid" icon="save">
      </ui-btn>
    </div>
  </div>
</form>

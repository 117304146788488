import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';
import { loadFiles } from '../shared/helpers';

@Component({
  selector: 'ui-file-loader',
  templateUrl: 'ui-file-loader.template.html',
  styleUrls: ['ui-file-loader.styles.scss'],
})
export class UiFileLoaderComponent {
  @ViewChild('inputEl') inputEl: ElementRef;
  @Input() @BooleanFieldValue() multiple: boolean = false;
  @Input() @BooleanFieldValue() returnAllEvents: boolean = false;
  @Output() load = new EventEmitter();
  private isLoading = false;

  constructor() {}

  open() {
    this.inputEl.nativeElement.click();
  }
  inputChangeHandler($event) {
    $event.preventDefault();

    if (this.returnAllEvents) {
      this.load.emit($event);
    } else {
      loadFiles($event.target.files).then((loadedFiles) => this.load.emit(loadedFiles));
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../core/services/customers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'customers-create',
  templateUrl: 'customers-create-edit.template.html',
})
export class CustomersCreateComponent implements OnInit {
  public customer;
  constructor(
    private customersSrv: CustomersService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Nuevo Cliente';
    this.customer = this.customersSrv.create();
    this.customer = Object.assign({}, this.customersSrv.create());
    this.customer.person = Object.assign({}, this.customersSrv.create().person);
  }
  handleSubmit(evt) {
    evt.preventDefault();
    console.warn('submitié');
    //this.saveCustomer();
  }
  saveCustomer() {
    this.customersSrv.save(this.customer).then((customer) => {
      this.ui.snackbar.show({
        message: 'Cliente creado!',
      });
      this.router.navigate(['/tasks/customers/' + customer.id]);
    });
  }
  stringify(obj) {
    return JSON.stringify(obj);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { PaymentConditionsService } from '../../core/services/payment-conditions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '../../core/services/users.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'paymentconditions-show',
  templateUrl: 'paymentconditions-show.template.html',
})
export class PaymentConditionsShowComponent implements OnInit {
  @ViewChild('attCollectionEl') attCollectionEl;
  public paymentcondition = null;
  private currentUserRole = null;
  constructor(
    private paymentconditionsSrv: PaymentConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.currentUserRole = this.auth.getCurrentRole();
    this.ui.toolbar.title = 'Detalle de las condiciones de pago';
    var paymentconditionId = this.route.snapshot.params['id'];
    this.paymentconditionsSrv.find(paymentconditionId).then((paymentcondition) => {
      this.paymentcondition = paymentcondition;
    });
  }
  goTo(url) {
    this.router.navigate([url]);
  }
}

import { Component, OnInit } from '@angular/core';
import { TasksService } from '../../core/services/tasks.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { UsersService } from '../../core/services/users.service';
import { ProjectsService } from '../../core/services/projects.service';

@Component({
  selector: 'tasks-edit',
  templateUrl: 'tasks-create-edit.template.html',
})
export class TasksEditComponent implements OnInit {
  private task;
  private usersOpts = [];
  private projectsOpts = [];
  private taskUser: {
    text: string;
    initialId: number;
  };
  public isLoading: boolean;
  constructor(
    private tasksSrv: TasksService,
    private usersSrv: UsersService,
    private projectsSrv: ProjectsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {
    this.taskUser = {
      text: null,
      initialId: null,
    };
  }
  ngOnInit() {
    this.ui.toolbar.title = 'Editar Tarea';
    this.fetch();
  }

  taskHandler(task) {
    this.task = task;
    // TODO: Esto es un workaround, debe eliminarse al mergear el branch no-token-expiration del server
    if (!this.task.user_id && this.task.user) this.task.user_id = this.task.user.id;
    if (!this.task.project_id && this.task.project) this.task.project_id = this.task.project.id;
    this.taskUser.initialId = this.task.user_id;
    this.taskUser.text = this.task.user.person.first_name + ' ' + this.task.user.person.last_name;
  }

  fetch() {
    this.isLoading = true;
    const taskId = this.route.snapshot.params['id'];
    Promise.all([this.tasksSrv.find(taskId), this.usersSrv.fetch(), this.projectsSrv.projectsNoPagination()])
      .then((res) => {
        this.taskHandler(res[0]);
        this.usersListHandler(res[1]);
        this.projectsListHandler(res[2]);
        this.isLoading = false;
      })
      .catch((err) => {
        this.ui.snackbar.show({
          message: 'Ocurrió un error cargando la tarea',
        });
        this.isLoading = false;
      });
  }

  usersListHandler(userCollection) {
    this.usersOpts = userCollection
      .filter((user) => user.status === 'active')
      .asObjectOptions('id', { orderBy: 'email' });
  }

  projectsListHandler(projectCollection) {
    this.projectsOpts = projectCollection.asOptions('id', 'name', { orderBy: 'name' });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.saveTask();
  }
  saveTask() {
    this.tasksSrv.save(this.task).then((task) => {
      this.ui.snackbar.show({
        message: 'Tarea modificada!',
      });
      this.router.navigate(['/tasks/tasks/' + task.id]);
    });
  }

  taskUserChangeHandler(id: number) {
    this.task.user_id = id;
  }
}

import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService, ICollectionResponse, ISingleItemResponse } from './api.service';
import { AuthService } from './auth.service';
import { API_URL } from '../../shared/constants';

export interface IOrder {
  approved_at: string;
  id: number;
  name: string;
  customer: any;
  customer_id: number;
  registered_by: any;
  status: string;
  approved_by_id: number;
  finished_by_id: number;
  closed_by_id: number;
}

@Injectable()
export class OrdersService extends ApiService {
  private baseOrder = {
    name: '',
    status: '',
    registered_by_id: null,
    summary: '',
  };
  constructor(private http: HttpX, private auth: AuthService) {
    super('order', 'orders', http, auth);
  }

  create(opts = {}) {
    return {
      ...this.baseOrder,
      ...opts,
    };
  }

  updateStatus(order: IOrder, status: string) {
    order.status = status;
    if (status === 'approved') {
      order.approved_by_id = this.auth.getCurrentUser().id;
    }
    if (status === 'finished') {
      order.finished_by_id = this.auth.getCurrentUser().id;
    }
    if (status === 'closed') {
      order.closed_by_id = this.auth.getCurrentUser().id;
    }
    return this.save(order);
  }

  ordersByCustomer(customerId) {
    return (this.fetch(`?customer_id=${customerId}`) as unknown) as Promise<ISingleItemResponse<IOrder>>;
  }

  validate_approval(orderId) {
    return this.http.get(API_URL + 'orders/' + orderId + '/validate_approval');
  }

  orderStatusDesc = {
    initial: 'Registrada',
    closed: 'Cerrada',
    approved: 'Aprobada',
    finished: 'Finalizada',
    canceled: 'Cancelada',
  };

  async fetch(extras?: string): Promise<ICollectionResponse<IOrder>> {
    return super.fetch(extras);
  }
}

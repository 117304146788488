import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  RoutesRecognized,
} from '@angular/router';

import { UiService } from './core/services/ui.service';

@Component({
  selector: 'app',
  templateUrl: 'app.template.html',
  styleUrls: ['app.styles.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('uiApp', { static: true }) uiAppEl: any;
  @ViewChild('snackbar', { static: true }) snackbarEl: any;

  constructor(private ui: UiService, private router: Router) {}
  ngOnInit() {
    this.ui.app = this.uiAppEl;
    this.ui.snackbar = this.snackbarEl;
    // TODO: This used to handle the toolbar title, given ui config options. Needs a refactor:
    // this.router.events
    //   .filter(event => event instanceof NavigationEnd) // we only care about the NaviGationEnd events
    //   .subscribe((evt: NavigationEnd) => {
    //     if (evt.url && this.ui.routeTitles[evt.url]) {
    //       this.ui.toolbar.title = this.ui.routeTitles[evt.url];
    //     }
    //   }
    // );
  }
}

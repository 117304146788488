import { Component, OnInit } from '@angular/core';
import { PaymentConditionsService } from '../../core/services/payment-conditions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'paymentconditions-edit',
  templateUrl: 'paymentconditions-create-edit.template.html',
})
export class PaymentConditionsEditComponent implements OnInit {
  public paymentcondition;
  constructor(
    private paymentconditionsSrv: PaymentConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Editar Condición de pago';
    var paymentconditionId = this.route.snapshot.params['id'];
    this.paymentconditionsSrv
      .find(paymentconditionId)
      .then((paymentcondition) => (this.paymentcondition = paymentcondition));
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.savePaymentCondition();
  }
  savePaymentCondition() {
    this.paymentconditionsSrv.save(this.paymentcondition).then((paymentcondition) => {
      this.ui.snackbar.show({
        message: 'Condición de pago modificada!',
      });
      this.router.navigate(['/orders/paymentconditions/' + paymentcondition.id]);
    });
  }
}

<div *ngIf="supplier">
  <div class="ui-panel" *ngIf="supplier">
    <h1 class="text-center">{{ supplier.name }}</h1>
    <div class="ui-field-group one-half">
      <ui-field-display
        label="Id"
        [hideIf]="!supplier.identification"
        [value]="'(' + supplier.identification_type + ') ' + supplier.identification"
      >
      </ui-field-display>
      <ui-field-display label="Razón social" [value]="supplier.legal_name"></ui-field-display>
      <ui-field-display label="Correo" [value]="supplier.email"></ui-field-display>
      <ui-field-display label="Teléfono" [value]="supplier.phone"></ui-field-display>
      <ui-field-display label="Ciudad" [value]="supplier.city"></ui-field-display>
      <ui-field-display label="Dirección" [value]="supplier.address"></ui-field-display>
    </div>
    <ui-field-display label="Notas" [value]="supplier.summary"></ui-field-display>
  </div>

  <h3 class="ui-panel__pre-title">
    <span class="icon icon-shopping-cart"></span>
    Ordenes de compra
  </h3>

  <div class="ui-panel no-padding no-border">
    <table class="ui-table">
      <thead>
        <tr>
          <th></th>
          <th>Nombre</th>
          <th>Fecha de creación</th>
          <th>Orden No.</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let o of orders"
          class="bordered"
          [class.bordered--success]="(o.status == 'finished')"
          [class.bordered--canceled]="(o.status == 'canceled')"
          [class.bordered--warning]="(o.status != 'finished' && o.status != 'canceled')"
        >
          <td>
            <span class="icon icon-shopping-cart"></span>
          </td>
          <td (click)="goTo(modeTexts.baseLink + o.id)">{{ o.name }}</td>
          <td>{{ o.created_at | date: "dd/MM/yyyy"}}</td>
          <td>{{ o.sequence }}</td>
          <td>{{ this.purchaseOrdersSrv.orderStatusDesc[o.status] }}</td>
        </tr>
      </tbody>
    </table>
    <mat-paginator
      [pageIndex]="paginator.pageIndex"
      [length]="paginator.length"
      [pageSize]="paginator.pageSize"
      [pageSizeOptions]="paginator.pageSizeOptions"
      (page)="changePage($event)"
    >
    </mat-paginator>
  </div>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn
      fab
      color="accent"
      (uiClick)="goTo('/tasks/suppliers/' + (supplier?.id || 0) + '/edit')"
      hint="Editar proveedor"
      icon="edit"
    >
    </ui-btn>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ProjectsService } from '../../core/services/projects.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';
import { ProjectStatus } from '../../shared/enums';
import { ProjectStatusPipe } from '../../shared/pipes/';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'projects-index',
  templateUrl: 'projects-index.template.html',
})
export class ProjectsIndexComponent implements OnInit {
  public PROJECT_STATUSES: any[];
  public isLoading: boolean;
  private DEFAULT_PAGE_SIZE = 25;
  private DEFAULT_PAGE_INDEX = 0;
  public paginator = {
    pageIndex: this.DEFAULT_PAGE_INDEX,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  };
  public filtersForm: FormGroup;
  public filters = {
    projectName: null,
    projectStatus: null,
    customerName: null,
  };
  private projects;
  private projectStatusPipe = new ProjectStatusPipe();
  private noSyncForm = false;
  private isAuthorized = false;
  constructor(
    private projectsSrv: ProjectsService,
    private router: Router,
    private ui: UiService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.PROJECT_STATUSES = this.projectStatusesOptions;
    this.ui.toolbar.title = 'Proyectos';
    this.initFiltersForm();
    this.isAuthorized = this.auth.isAuthorized();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!this.noSyncForm && Object.keys(params).length > 0) {
        this.syncFilterWithUrl(params);
      } else {
        this.noSyncForm = false;
      }
      this.fetch();
    });
  }

  initFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      projectName: [],
      customerName: [],
      projectStatus: [],
    });
  }

  fetch() {
    this.isLoading = true;
    this.projectsSrv
      .fetch(this.indexQueryParams)
      .then((res) => {
        this.paginator.length = res.pagination.total;
        this.projects = (<any[]>res.collection.asArray()).reverse();
        this.isLoading = false;
      })
      .catch(() => (this.isLoading = false));
  }

  changePage(paginator: any) {
    this.paginator.pageIndex = paginator.pageIndex;
    this.paginator.pageSize = paginator.pageSize;
    this.updateRouterQueryParams();
  }

  goTo(url) {
    this.router.navigate([url]);
  }

  filter() {
    const value = this.filtersForm.value;
    Object.assign(this.filters, value);
    this.paginator.pageIndex = 0;
    this.noSyncForm = true;
    this.updateRouterQueryParams();
  }

  clearFilter(filterKey: 'projectName' | 'customerName' | 'projectStatus') {
    this.filters[filterKey] = null;
    this.filtersForm.get(filterKey).setValue(null);
    this.fetch();
  }

  get activeFilters() {
    return +!!this.filters.projectName + +!!this.filters.customerName + +!!this.filters.projectStatus;
  }

  private syncFilterWithUrl(queryParams: Params) {
    this.paginator.pageIndex = queryParams.page > -1 ? queryParams.page - 1 : this.DEFAULT_PAGE_INDEX;
    this.paginator.pageSize = this.isPageSizeValid(queryParams.per_page)
      ? Number(queryParams.per_page)
      : this.DEFAULT_PAGE_SIZE;
    this.filters.customerName = queryParams.customer_name;
    this.filters.projectName = queryParams.name;
    this.filters.projectStatus = queryParams.status;
    this.updateFiltersForm();
  }

  private updateFiltersForm() {
    this.filtersForm.controls.customerName.setValue(this.filters.customerName);
    this.filtersForm.controls.projectName.setValue(this.filters.projectName);
    this.filtersForm.controls.projectStatus.setValue(this.filters.projectStatus);
  }

  private updateRouterQueryParams() {
    const queryParams = this.formatToQueryParams;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  private isPageSizeValid(pageSize: string) {
    return this.paginator.pageSizeOptions.indexOf(Number(pageSize)) !== -1;
  }

  private get indexQueryParams() {
    const pageSize = this.paginator.pageSize;
    const pageIndex = this.paginator.pageIndex + 1;
    const { projectName, customerName, projectStatus } = this.filters;
    let url = `?page=${pageIndex}&per_page=${pageSize}`;
    if (this.activeFilters > 0) {
      if (customerName) {
        url += `&q[customer_name_cont]=${customerName}`;
      }
      if (projectName) {
        url += `&q[name_cont]=${projectName}`;
      }
      if (projectStatus) {
        url += `&q[status_eq]=${projectStatus}`;
      }
    }
    this.updateRouterQueryParams();
    return url;
  }

  private get projectStatusesOptions() {
    return (<any>Object).values(ProjectStatus).map((projectStatus: string) => {
      return {
        label: this.projectStatusPipe.transform(projectStatus),
        value: projectStatus,
      };
    });
  }

  private get formatToQueryParams() {
    const params = {
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
    } as Params;
    const { projectName, customerName, projectStatus } = this.filters;
    if (projectName) {
      params.name = projectName;
    }
    if (projectStatus) {
      params.status = projectStatus;
    }
    if (customerName) {
      params.customer_name = customerName;
    }
    return params;
  }

  createProjectRedirect() {
    if (this.isAuthorized) {
      this.goTo(`/tasks/projects/new`);
    } else {
      this.ui.snackbar.show('No tienes permisos para crear proyectos.');
    }
  }
}

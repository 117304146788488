<form #conditionForm="ngForm" (submit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="condition">
    <h1 class="text-center"
      [class.color-disabled]="!condition.name">
      {{ condition.name || 'Agrega un nombre de la condición'}}
    </h1>
    <ui-input label="Nombre" name="condition_name" [(ngModel)]="condition.name"
      placeholder="IVA 19%" required>
    </ui-input>
    <ui-input type="textarea" label="Descripción" name="condition_summary" [(ngModel)]="condition.summary"
      placeholder="Añade la descripción de tu condición aquí">
    </ui-input>
    <div class="ui-form-group one-half">
      <ui-input type="number" label="Porcentaje" name="condition_rate" [(ngModel)]="condition.rate"
        placeholder="">
      </ui-input>
    </div>
  </div>

  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="saveCondition()" hint="Guardar condición"
        [disabled]="!conditionForm.form.valid" icon="save">
      </ui-btn>
    </div>
  </div>
</form>

<div class="ui-panel  no-padding">
  <table class="ui-table">
    <thead>
      <tr>
        <th></th>
        <th>Nombre</th>
        <th>Telefono</th>
        <th>Correo</th>
        <th>Razón social</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of suppliers" (click)="goTo('/tasks/suppliers/' + c.id )">
        <td><span class="icon icon-suppliers"></span></td>
        <td [title]="c.name">{{ c.name | trunc : 30 }}</td>
        <td>{{ c.phone }}</td>
        <td>{{ c.email }}</td>
        <td>{{ c.legal_name }}
      </tr>
    </tbody>
  </table>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="createSupplierRedirect()"
      hint="Crear proveedor" icon="add">
    </ui-btn>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';

@Component({
  selector: 'ui-badge',
  templateUrl: 'ui-badge.template.html',
  styleUrls: ['ui-badge.styles.scss'],
})
export class UiBadgeComponent {
  public number: string;
  @Input() @BooleanFieldValue() small: boolean = false;
  @Input() set value(val: number) {
    if (val > 99) {
      this.number = '+99';
    } else {
      this.number = val.toString();
    }
  }
  constructor() {}
}

<div *ngIf="user">
  <div class="ui-panel">
    <!--<div class="ui-panel__avatar">
      <img *ngIf="user.person.avatar_url" [src]="user.person.avatar_url">
    </div>-->
    <img class="ui-panel__avatar" [src]="user.person.avatar_url || avatarBlank" />
    <h1 class="text-center">
      {{ user.person.nickname ? user.person.nickname : user.person.first_name+' '+user.person.last_name }}
    </h1>
    <div class="ui-field-group one-half">
      <ui-field-display *ngIf="user.person.nickname" label="Nombre"
        [value]="user.person.first_name + ' ' + user.person.last_name">
      </ui-field-display>
      <ui-field-display label="Correo" [value]="user.email"></ui-field-display>
      <ui-field-display label="Grupo" [value]="user.user_group?.name"
        (click)="goTo('/tasks/usergroups/' + user.user_group.id )" clickable>
      </ui-field-display>
    </div>
  </div>
  <h3 class="ui-panel__pre-title">
    <span class="icon icon-task"></span>
    Tareas
  </h3>
  <p class="ui-panel__empty-state" *ngIf="!user.tasks.length">
    Este usuario no tiene tareas asociadas.
  </p>
  <div class="ui-panel no-padding no-border" *ngIf="user.tasks.length">
    <task-collection hideColumns="user, group" [tasks]="user.tasks | filterBy : filters" orderOnUpdate orderByDueDate>
    </task-collection>
  </div>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__secondary">
    <ui-btn fab color="accent" (uiClick)="goTo('/tasks/users/' + (user?.id || 0) + '/edit')" hint="Editar persona">
      <span class="icon icon-edit"></span>
    </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <ui-btn *ngIf="filtered_by == 'active'" fab color="success" (uiClick)="disableFilterBy()"
      hint="Ver todas las tareas">
      <span class="icon icon-list-check"></span>
    </ui-btn>
    <ui-btn *ngIf="filtered_by == 'all'" fab color="warning" (uiClick)="filterByStatus()"
      hint="Ver solo tareas pendientes">
      <span class="icon icon-list"></span>
    </ui-btn>
  </div>
</div>
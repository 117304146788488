<div fxLayout="row"
  fxLayoutAlign="center start"
  fxLayoutGap="20px">  
  <div fxFlex
    fxLayout="column"
    fxLayoutAlign="start"
    fxLayoutGap="20px">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container *ngIf="activeFilters > 0; else noFilters">
              <ui-badge [value]="activeFilters"
                small>
                Filtros
              </ui-badge>
            </ng-container>
            <ng-template #noFilters>
              Filtros
            </ng-template>
          </mat-panel-title>
          <mat-panel-description>
            Esto te permitirá filtrar los proyectos según criterios
          </mat-panel-description>
        </mat-expansion-panel-header>
        <form [formGroup]="filtersForm"
          (ngSubmit)="filter()"
          fxLayout="row"
          fxLayoutAlign="start center" 
          fxLayoutGap="10px">
          <ui-input label="Nombre del proyecto"
            fxFlex
            formControlName="projectName"
            name="project_name">
          </ui-input>
          <ui-input label="Cliente"
            fxFlex
            formControlName="customerName"
            name="customer_name">
          </ui-input>
          <ui-select label="Estado"
            fxFlex="100px"
            name="project_status"
            formControlName="projectStatus"
            [options]="PROJECT_STATUSES">
          </ui-select>
          <ui-btn class="padding"
            type="submit"
            fab
            color="primary" 
            hint="Filtrar"
            icon="filter">
          </ui-btn>
        </form>
        <div class="margin-bottom--half">
          <mat-chip-list
            aria-label="Filtros">
            <mat-chip *ngIf="filters.projectName as name"
              selected="true"
              removable>
              <div fxLayout="row"
                fxLayoutAlign="space-between center">
                <b>Nombre</b>: {{ name }}
                <mat-icon matChipRemove
                  (click)="clearFilter('projectName')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.customerName as customer"
              selected="true"
              removable>
              <div fxLayout="row"
                fxLayoutAlign="space-between center">
                <b>Cliente</b>: {{ customer }}
                <mat-icon matChipRemove
                  (click)="clearFilter('customerName')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.projectStatus as status"
              selected="true"
              removable>
              <div fxLayout="row"
                fxLayoutAlign="space-between center">
                <b>Estado</b>: {{ status | projectStatus }}
                <mat-icon matChipRemove
                  (click)="clearFilter('projectStatus')">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="ui-panel  no-padding">
      <ng-container *ngIf="!isLoading; else loading">
        <table class="ui-table">
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>Cliente</th>
              <th>Vence el</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of projects"
              class="bordered"
              [class.bordered--success]="(p.status == 'finished')"
              [class.bordered--warning]="(p.status != 'finished')"
              >
              <td><span class="icon icon-rocket"></span></td>
              <td (click)="goTo('/tasks/projects/' + p.id )">{{ p.name }}</td>
              <td (click)="goTo('/tasks/customers/' + p.customer?.id )">{{ p.customer?.name }}</td>
              <td>{{ p.due_date | date: 'dd/MM/yyyy' }}</td>
            </tr>
          </tbody>
        </table>
        <mat-paginator [pageIndex]="paginator.pageIndex"
          [length]="paginator.length"
          [pageSize]="paginator.pageSize"
          [pageSizeOptions]="paginator.pageSizeOptions"
          (page)="changePage($event)">
        </mat-paginator>
      </ng-container>
      <ng-template #loading>
        <div class="padding"
          fxLayout="row"
          fxLayoutAlign="center cener">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>  

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="createProjectRedirect()"
      hint="Crear proyecto" icon="add">
    </ui-btn>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { OrdersService } from '../../core/services/orders.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { CustomersService } from '../../core/services/customers.service';
import { PaymentConditionsService } from '../../core/services/payment-conditions.service';
import { buyOrders, sellOrders } from './modeHelpers';
import { PurchaseOrdersService } from 'src/app/core/services/purchaseOrders.service';

@Component({
  selector: 'orders-edit',
  templateUrl: 'orders-create-edit.template.html',
})
export class OrdersEditComponent implements OnInit {
  public order;
  public mode;
  private orderService: OrdersService | PurchaseOrdersService;
  private customersOpts = [];
  private paymentConditionsOpts = [];
  private logo: any;
  private background: any;
  public isLoading: boolean = true;
  private orderCustomerName = null;

  constructor(
    private ordersSrv: OrdersService,
    private purchaseOrdersSrv: PurchaseOrdersService,
    private customersSrv: CustomersService,
    private paymentConditionsSrv: PaymentConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {
    route.data.subscribe(({ mode }) => {
      this.orderService = mode === 'buy' ? this.purchaseOrdersSrv : this.ordersSrv;
    });
  }

  ngOnInit() {
    this.fetch();
    this.route.data.subscribe(({ mode }) => {
      this.mode = mode;
      this.ui.toolbar.title = this.modeTexts.editOrderPageTitle;
    });
  }

  fetch() {
    const orderId = this.route.snapshot.params['id'];
    Promise.all([
      this.orderService.find(orderId).then((order) => (this.order = order)),
      this.paymentConditionsSrv
        .fetch()
        .then(
          (paymentConditionsCollection) =>
            (this.paymentConditionsOpts = paymentConditionsCollection.asOptions('id', 'name', { orderBy: 'name' }))
        ),
    ]).then(() => {
      this.orderCustomerName = this.order[this.userProp].name;
      this.isLoading = false;
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.saveOrder();
  }

  saveOrder() {
    Object.assign(this.order, {
      logo: this.logo,
      logo_file_name: `${this.order.name}_logo`,
      background: this.background,
      background_file_name: `${this.order.name}_background`,
    });
    this.orderService.save(this.order).then((order) => {
      this.ui.snackbar.show({
        message: 'Orden modificada!',
      });
      if (this.mode === 'sell') {
        this.router.navigate(['/orders/sell-orders/' + order.id]);
      } else {
        this.router.navigate(['/orders/buy-orders/' + order.id]);
      }
    });
  }

  orderCustomerChangeHandler(id: number) {
    if (this.mode === 'sell') {
      this.order.customer_id = id;
    } else {
      this.order.supplier_id = id;
    }
  }

  public get modeTexts() {
    return this.mode === 'sell' ? sellOrders : buyOrders;
  }

  get userProp() {
    return this.mode === 'buy' ? 'supplier' : 'customer';
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from './core/services/ui.service';
import { AuthService } from './core/services/auth.service';
import { sidebarSubActions } from './app.config';

@Component({
  selector: 'internal-app',
  templateUrl: 'internal-app.template.html',
  styleUrls: ['internal-app.styles.scss'],
})
export class InternalAppComponent implements OnInit {
  @ViewChild('toolbar', { static: true }) toolbarEl: any;
  @ViewChild('sidebar', { static: true }) sidebarEl: any;
  @ViewChild('alert', { static: true }) alertEl: any;
  constructor(public ui: UiService, private auth: AuthService, private router: Router) {}
  ngOnInit() {
    this.sidebarEl.setRouter(this.router);
    this.sidebarEl.setSubActions(sidebarSubActions);

    this.ui.toolbar = this.toolbarEl;
    this.ui.sidebar = this.sidebarEl;
    this.ui.alert = this.alertEl;
  }
  userData() {
    const currentUser = this.auth.getCurrentUser();
    if (!currentUser.person) return {};
    return {
      id: currentUser.id,
      organization_name: currentUser.person.organization.name,
      avatar_url: currentUser.person.avatar_url,
      first_name: currentUser.person.first_name,
      last_name: currentUser.person.last_name,
      nickname: currentUser.person.nickname,
      email: currentUser.email,
      role: currentUser.user_group.name,
    };
  }
  logOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['/']);
      this.ui.snackbar.show('La sesión se finalizó correctamente.');
    });
  }
  closeWelcomeDialog() {
    this.auth.firstSessionMessageShowed();
  }
  openHelpBlog() {
    window.open('https://ontask.co/ayuda/como-comenzar.html', '_blank');
  }
  public get showFirstTimeMessage() {
    return this.auth.showFirstSessionMessage;
  }
}

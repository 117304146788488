import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
// import { FormsModule } from '@angular/forms';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { UiModule } from './ui/ui.module';
import { DummiesAppModule } from './dummies-app/dummies-app.module';
import { TasksAppModule } from './tasks-app/tasks-app.module';
import { OrdersAppModule } from './orders-app/orders-app.module';

import { AppRoutesModule } from './app-routes.module';

import { AppComponent } from './app.component';
import { InternalAppComponent } from './internal-app.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    // FormsModule,
    SharedModule,
    CoreModule,
    UiModule,
    AppRoutesModule,
    DummiesAppModule,
    TasksAppModule,
    OrdersAppModule,
  ],
  declarations: [AppComponent, InternalAppComponent],
  bootstrap: [AppComponent],
  providers: [],
})
export class AppModule {}

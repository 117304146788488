import { Component, OnInit } from '@angular/core';
import { UserGroupsService } from '../../core/services/usergroups.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'userGroups-create',
  templateUrl: './usergroups-create-edit.template.html',
})
export class UserGroupsCreateComponent implements OnInit {
  public userGroup;
  constructor(
    private userGroupsSrv: UserGroupsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Nuevo Grupo';
    this.userGroup = this.userGroupsSrv.create();
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.saveUserGroup();
  }
  saveUserGroup() {
    this.userGroupsSrv.save(this.userGroup).then((userGroup) => {
      this.ui.snackbar.show({
        message: 'Grupo creado!',
      });
      this.router.navigate(['/tasks/usergroups/' + userGroup.id]);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { UiService } from '../core/services/ui.service';
import { sidebarActions } from './orders-app.config';

@Component({
  selector: 'orders-app',
  templateUrl: 'orders-app.template.html',
  styleUrls: ['orders-app.styles.scss'],
})
export class OrdersAppComponent implements OnInit {
  constructor(private ui: UiService) {}
  ngOnInit() {
    this.ui.app.setTheme('green-lite');
    this.ui.sidebar.setActions(sidebarActions);
  }
}

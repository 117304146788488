import { Component, OnInit } from '@angular/core';
import { SuppliersService } from '../../core/services/suppliers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'suppliers-create',
  templateUrl: 'suppliers-create-edit.template.html',
})
export class SuppliersCreateComponent implements OnInit {
  public supplier;
  constructor(
    private suppliersSrv: SuppliersService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Nuevo proveedor';
    this.supplier = this.suppliersSrv.create();
    this.supplier = Object.assign({}, this.suppliersSrv.create());
  }
  handleSubmit(evt) {
    evt.preventDefault();
    console.warn('submitié');
    //this.savesupplier();
  }
  saveSupplier() {
    this.suppliersSrv.save(this.supplier).then((supplier) => {
      this.ui.snackbar.show({
        message: 'Proveedor creado!',
      });
      this.router.navigate(['/tasks/suppliers/' + supplier.id]);
    });
  }
  stringify(obj) {
    return JSON.stringify(obj);
  }
}

import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { IOrder, OrdersService } from '../../core/services/orders.service';
import { OrderItemsService } from '../../core/services/order-items.service';
import { ProductsService } from '../../core/services/products.service';
import { ProjectsService } from '../../core/services/projects.service';
import { ConditionsService } from '../../core/services/conditions.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UsersService } from '../../core/services/users.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';
import { OrderItemConditionsService } from '../../core/services/order-item-conditions.service';
import { roundNumber } from '../../shared/helpers';
import { buyOrders, sellOrders } from './modeHelpers';
import { PurchaseOrdersService } from 'src/app/core/services/purchaseOrders.service';
import { PurchaseOrderItemsService } from 'src/app/core/services/purchase-order-items.service';
import { PurchaseOrderItemConditionsService } from 'src/app/core/services/purchase-order-item-conditions.service';

@Component({
  selector: 'orders-show',
  templateUrl: 'orders-show.template.html',
  styleUrls: ['orders-show.styles.scss'],
})
export class OrdersShowComponent implements OnInit {
  @ViewChild('attCollectionEl') attCollectionEl;
  @ViewChild('orderitemForm') orderitemForm;

  public mode;
  public selectedItem: any;
  private boConditionLocked = true;
  private boPriceLocked = true;
  private boQuoteDisplay = true;
  private condition_type = '';
  private conditionDialogOpen = false;
  private conditionsOpts = [];
  private confirmDialogOpen = false;
  private curOrderItem = null;
  private currentUserRole = null;
  private currentUserEmail = null;
  private hoverConditions = [];
  private itemModalTitle = '';
  private modalConditionOpen = false;
  private modalConditionSubmit = '';
  private modalConditionTitle = '';
  private modalDialogOpen = false;
  private modalOpen = false;
  private modalProjectOpen = false;
  private modalSellOrdersOpen = false;
  public finishOrderDialogOpen = false;
  public cancelOrderDialogOpen = false;
  private modalSendPDFOpen = false;
  public modalSendPDFText = 'Enviar';
  private downloadPDFText = '';
  private sendPDFText = '';
  private customerProjects = [];
  private modalProjectOption = false;
  private netWorth: number = 0;
  private nuOrderItem = null;
  private nuOrderItemCondition = null;
  private sendPDFObject = { client: null, sender: null, cc: null };
  private nuProject = null;
  public order = null;
  private productsOpts = [];
  private submitModal = '';
  private totalDiscounts: number = 0;
  private totalTaxes: number = 0;
  private total: number = 0;
  private orderRoundedValues: boolean = false;
  private totalDiscountApp: number = 0;
  private itemsService: OrderItemsService | PurchaseOrderItemsService;
  private conditionService: OrderItemConditionsService | PurchaseOrderItemConditionsService;
  private DEFAULT_PAGE_SIZE = 5;
  private DEFAULT_PAGE_INDEX = 0;
  public sellOrdersPaginator = {
    pageIndex: this.DEFAULT_PAGE_INDEX,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  };
  public sellOrders = [];
  public addSellOrderFilter = '';
  public addSellOrdersList = [];

  constructor(
    private auth: AuthService,
    private conditionsSrv: ConditionsService,
    private orderItemsSrv: OrderItemsService,
    private purchaseOrderItemsSrv: PurchaseOrderItemsService,
    private orderItemConditionSrv: OrderItemConditionsService,
    private purchaseOrderItemConditionSrv: PurchaseOrderItemConditionsService,
    private ordersSrv: OrdersService,
    private productsSrv: ProductsService,
    private projectsSrv: ProjectsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService,
    private usersSrv: UsersService,
    private purchaseOrdersSrv: PurchaseOrdersService
  ) {}

  ngOnInit() {
    let currentUser = this.auth.getCurrentUser();
    this.currentUserRole = this.auth.getCurrentRole();
    this.orderRoundedValues = currentUser.person.organization.order_rounded_values;
    this.currentUserEmail = currentUser.email;
    const orderId = this.route.snapshot.params['id'];
    this.route.data.subscribe(({ mode }) => {
      this.mode = mode;
      this.ui.toolbar.title = this.modeTexts.detailPageTitle;
      this.itemsService = this.mode === 'buy' ? this.purchaseOrderItemsSrv : this.orderItemsSrv;
      this.conditionService = this.mode === 'buy' ? this.purchaseOrderItemConditionSrv : this.orderItemConditionSrv;
      this.quoteDisplay(this.mode === 'sell' ? this.isAuthorized() : true);
    });
    this.route.queryParams.subscribe(() => {
      if (this.mode === 'buy') {
        this.getSellOrders(orderId);
      }
    });
    this.fetchOrderItems(orderId);
  }

  public getSellOrders(orderId: string) {
    this.purchaseOrdersSrv.saleOrders(orderId, this.sellOrdersQueryParams).then((orders) => {
      this.sellOrders = orders.collection.asArray();
      this.sellOrdersPaginator = { ...this.sellOrdersPaginator, length: orders.pagination.total };
    });
  }

  isAuthorized() {
    if (this.mode === 'sell') {
      return this.isAdmin || this.currentUserRole == 'comercial';
    }
    return true;
  }

  public get isAdmin() {
    return this.currentUserRole == 'admin';
  }

  public get canDownloadPDF() {
    if (this.mode === 'sell') {
      return this.isAuthorized() && this.order.status !== 'finished';
    }
    return true;
  }

  showFixedNumber(number: number) {
    return roundNumber(this.orderRoundedValues, number);
  }

  quoteDisplay(value) {
    if (this.mode === 'sell') {
      if (value) {
        this.downloadPDFText = 'Generar cotización PDF';
        this.sendPDFText = 'Enviar cotización PDF';
      } else {
        this.downloadPDFText = 'Generar orden PDF';
        this.sendPDFText = 'Enviar orden PDF';
      }
    } else {
      this.downloadPDFText = 'Generar PDF';
    }
    this.boQuoteDisplay = value;
  }

  addOrEditOrderItem(item: any) {
    if (item) {
      Object.assign(this.nuOrderItem, item);
      let quantity = parseFloat(this.nuOrderItem.quantity);
      this.condition_type = this.selectedItem ? 'update' : 'create';
      this.nuOrderItem.discount = this.nuOrderItem.discount ? parseFloat(this.nuOrderItem.discount) : 0;
      this.nuOrderItem.total = this.nuOrderItem.price * quantity + this.nuOrderItem.discount;
      if (this.condition_type === 'update') {
        this.ui.snackbar.show('Guardando...');
        this.nuOrderItem[this.itemsConditionProp].forEach((condition) => {
          condition.value = this.nuOrderItem.total * condition.rate;
        });
        this.itemsService.save(this.nuOrderItem).then(() => {
          this.ui.snackbar.hide();
          this.fetchOrderItems(this.order.id);
          this.modalOpen = false;
          this.selectedItem = null;
        });
      } else {
        this.ui.snackbar.show('Creando nuevo item...');
        this.itemsService.save(this.nuOrderItem).then(() => {
          this.ui.snackbar.hide();
          this.fetchOrderItems(this.order.id);
          this.modalOpen = false;
        });
      }
    } else {
      this.modalOpen = false;
      this.selectedItem = null;
    }
  }

  addOrderProject($event, project = null) {
    if (project) this.nuProject = project;
    this.nuProject.order_id = this.order.id;
    this.ui.snackbar.show('Creando nuevo proyecto...');
    this.projectsSrv.save(this.nuProject).then((createdProject) => {
      this.ui.snackbar.hide();
      this.order.projects.push(createdProject);
      this.closeProjectModal();
      // this.nuProject = this.projectsSrv.create({ customer_id: this.customer.id });
    });
  }

  addOrEditItemConditionHandler(event: MouseEvent, title, submit, orderItem: any, itemCondition: any) {
    event.preventDefault();
    this.modalConditionOpen = true;
    this.modalConditionTitle = title;
    this.modalConditionSubmit = submit;
    this.curOrderItem = orderItem;
    if (itemCondition) {
      this.nuOrderItemCondition = { ...itemCondition };
      this.boConditionLocked = this.nuOrderItemCondition.is_locked;
      this.condition_type = 'update';
    } else {
      this.nuOrderItemCondition = {
        condition_id: null,
      };
      this.condition_type = 'create';
    }
  }

  addOrEditItemCondition(event: any) {
    this.nuOrderItemCondition.value = this.curOrderItem.total * this.nuOrderItemCondition.rate;
    if (this.condition_type == 'create') {
      this.conditionService
        .save({ ...this.curOrderItem, condition: this.nuOrderItemCondition }, true)
        .then((updatedOrderItem) => {
          this.fetchOrderItems(this.order.id);
          this.modalConditionOpen = false;
        });
    }
    if (this.condition_type == 'update') {
      this.conditionService
        .save({ ...this.curOrderItem, condition: this.nuOrderItemCondition })
        .then((updatedOrderItem) => {
          this.fetchOrderItems(this.order.id);
          this.modalConditionOpen = false;
        });
    }
  }

  approveOrder() {
    this.ordersSrv.updateStatus(this.order, 'approved').then(() => {
      this.fetchOrderItems(this.route.snapshot.params['id']);
      this.ui.snackbar.show('Orden aprobada!');
    });
  }

  conditionSelectChangeHandler(conditionId: any) {
    let condition = this.conditionsSrv.collection().find(conditionId);
    this.boConditionLocked = condition.is_locked;
    this.nuOrderItemCondition.condition_id = conditionId;
    this.nuOrderItemCondition.organization_id = this.order.organization_id;
    this.nuOrderItemCondition.condition_name = condition.name;
    this.nuOrderItemCondition.rate = parseFloat(condition.rate);
    this.nuOrderItemCondition.sequence = condition.sequence;
    this.nuOrderItemCondition.value = 0;
  }

  closeProjectModal() {
    this.modalProjectOpen = false;
    this.nuProject = this.projectsSrv.create({
      customer_id: this.order.customer ? this.order.customer.id : null,
      order_id: this.order.id,
    });
  }

  deleteOrderItem() {
    this.ui.snackbar.show('Eliminando orden...');
    this.itemsService.delete(this.nuOrderItem).then(() => {
      this.confirmDialogOpen = false;
      this.fetchOrderItems(this.order.id);
      this.ui.snackbar.hide();
    });
  }

  deleteOrderCondition() {
    this.ui.snackbar.show('Eliminando condición...');
    this.conditionService
      .delete({ ...this.curOrderItem, condition: this.nuOrderItemCondition })
      .then((updatedOrderItem) => {
        this.fetchOrderItems(this.order.id);
        this.conditionDialogOpen = false;
        this.ui.snackbar.hide();
      });
  }

  deleteOrderItemHandler(event: MouseEvent, orderItem: any) {
    event.preventDefault();
    this.nuOrderItem = orderItem;
    this.confirmDialogOpen = true;
  }

  deleteItemConditionHandler(event: MouseEvent, itemCondition: any, orderItem: any) {
    event.preventDefault();
    this.nuOrderItemCondition = itemCondition;
    this.curOrderItem = orderItem;
    this.conditionDialogOpen = true;
  }

  displayConditions(orderItem) {
    return orderItem[this.itemsConditionProp].map((condition) => condition.condition_name).join(', ');
  }

  fetchOrderItems(orderId) {
    this.netWorth = 0;
    this.totalDiscounts = 0;
    this.totalTaxes = 0;
    this.total = 0;
    const service = this.mode === 'buy' ? this.purchaseOrdersSrv : this.ordersSrv;
    service.find(orderId).then((order) => {
      this.order = order;
      this.orderItems.forEach((item) => {
        this.netWorth += parseFloat(item.price) * parseFloat(item.quantity);
        this.totalDiscounts += parseFloat(item.discount);
        item[this.itemsConditionProp].forEach((elem) => {
          this.totalTaxes += parseFloat(item.total) * parseFloat(elem.rate);
        });
        this.total += parseFloat(item.total);
      });
      this.nuOrderItem = this.createBaseItem();
      this.nuProject = this.projectsSrv.create({
        customer_id: this.order.customer ? this.order.customer.id : null,
      });
    });
    this.productsSrv.productsNoPagination().then((productsCol) => {
      this.productsOpts = productsCol.asOptions('id', 'name', { orderBy: 'name' });
    });
    this.conditionsSrv.fetch().then((conditionsCol) => {
      this.conditionsOpts = conditionsCol.asOptions('id', 'name', { orderBy: 'name' });
    });
  }

  goTo(url) {
    this.router.navigate([url]);
  }

  async validateOrder() {
    if (this.mode === 'sell') {
      let validateResponse = await this.ordersSrv.validate_approval(this.order.id);
      this.totalDiscountApp = validateResponse.payload.data.total_discount;
      if (validateResponse.payload.data.valid) {
        return true;
      }
      this.ui.snackbar.show('La orden requiere ser aprobada. Total descuentos: ' + this.totalDiscountApp);
      return false;
    }
    return true;
  }
  async generatePDF() {
    let isValid = await this.validateOrder();
    if (!isValid) {
      return;
    }
    this.downloadPDFText = 'Generando...';
    const orderService = this.mode === 'sell' ? this.ordersSrv : this.purchaseOrdersSrv;
    orderService
      .downloadDataById(
        this.order.id,
        `/download?view=${this.boQuoteDisplay}`,
        'application/pdf',
        `${this.mode === 'sell' ? 'Cotización' : 'Orden de compra'} No. ${this.order.sequence}.pdf`
      )
      .then(() => {
        this.quoteDisplay(this.boQuoteDisplay);
        this.ui.snackbar.show('Documento PDF generado correctamente!');
      })
      .catch((err) => {
        this.quoteDisplay(this.boQuoteDisplay);
        this.ui.snackbar.show('Error al generar el documento PDF');
        console.error(err);
      });
  }

  openSendPDFModal() {
    this.ordersSrv.validate_approval(this.order.id).then((response) => {
      this.totalDiscountApp = response.payload.data.total_discount;
      if (response.payload.data.valid) {
        this.sendPDFObject.client = this.order.customer.email;
        this.sendPDFObject.sender = this.currentUserEmail;
        this.modalSendPDFOpen = true;
      } else {
        this.ui.snackbar.show('Orden requiere ser aprobada. Total descuentos: ' + this.totalDiscountApp);
      }
    });
  }

  sendPDF(event: any) {
    event.preventDefault();
    this.modalSendPDFText = 'Enviando...';
    this.ordersSrv
      .post(this.route.snapshot.params['id'], `/send_pdf?view=${this.boQuoteDisplay}`, this.sendPDFObject)
      .then((response) => {
        if (response.status == 204) {
          this.modalSendPDFText = 'Enviar';
          this.ui.snackbar.show('Orden enviada correctamente!');
          this.modalSendPDFOpen = false;
          this.sendPDFObject.client = null;
          this.sendPDFObject.sender = null;
          this.sendPDFObject.cc = null;
        }
      })
      .catch((err) => {
        this.ui.snackbar.show('Error al enviar la orden');
      });
  }

  mouseEnter(conditions: any) {
    this.hoverConditions = conditions;
  }

  mouseLeave(conditions: any) {
    this.hoverConditions = [];
  }

  openOrder() {
    if (this.mode === 'sell') {
      this.ordersSrv.updateStatus(this.order, 'initial').then(() => {
        this.modalDialogOpen = false;
        this.fetchOrderItems(this.route.snapshot.params['id']);
        this.ui.snackbar.show('Orden abierta!');
      });
    } else {
      this.purchaseOrdersSrv.updateStatus(this.order, 'initial').then(() => {
        this.modalDialogOpen = false;
        this.fetchOrderItems(this.route.snapshot.params['id']);
        this.ui.snackbar.show('Orden abierta!');
      });
    }
  }

  updateOrderStatus(status: any) {
    if (this.mode === 'sell') {
      this.ordersSrv.updateStatus(this.order, status).then(() => {
        if (status == 'finished') {
          this.finishOrderDialogOpen = false;
          this.ui.snackbar.show('¡Orden finalizada!');
        }
        if (status == 'canceled') {
          this.cancelOrderDialogOpen = false;
          this.ui.snackbar.show('¡Orden cancelada!');
        }
        if (status == 'closed') this.ui.snackbar.show('¡Orden cerrada!');
      });
    } else {
      this.purchaseOrdersSrv.updateStatus(this.order, status).then(() => {
        if (status == 'finished') {
          this.finishOrderDialogOpen = false;
          this.ui.snackbar.show('¡Orden finalizada!');
        }
        if (status == 'canceled') {
          this.cancelOrderDialogOpen = false;
          this.ui.snackbar.show('¡Orden cancelada!');
        }
      });
    }
  }

  orderItemQuantitytHandler(quantity: number) {
    if (this.nuOrderItem.discount_percent)
      this.nuOrderItem.discount = (
        quantity *
        this.nuOrderItem.price *
        (this.nuOrderItem.discount_percent / -100)
      ).toFixed(2);
  }

  orderItemDiscountHandler(discount: number) {
    this.nuOrderItem.discount = discount > 0 ? -discount : discount;
    this.nuOrderItem.discount_percent = (
      (this.nuOrderItem.discount / (this.nuOrderItem.quantity * this.nuOrderItem.price)) *
      -100
    ).toFixed(2);
  }

  orderItemPercentHandler(discount_percent: number) {
    this.nuOrderItem.discount = (
      this.nuOrderItem.quantity *
      this.nuOrderItem.price *
      (discount_percent / -100)
    ).toFixed(2);
  }

  itemSelectChangeHandler(productId: number) {
    let product = this.productsCollection.find(productId);
    if (product) {
      this.nuOrderItem.product_id = productId;
      this.nuOrderItem.product_name = product.name;
      this.nuOrderItem.summary = product.description;
      this.boPriceLocked = product.price_locked;
      this.nuOrderItem.product_cost = parseFloat(product.base_cost);
      this.nuOrderItem.price = parseFloat(product.base_price);
      this.nuOrderItem.profit_rate = parseFloat(product.profit_rate);
      this.nuOrderItem.maximum_quantity = parseFloat(product.maximum_quantity);
      this.nuOrderItem.minimum_quantity = parseFloat(product.minimum_quantity);
    }
  }

  validateApproval() {
    this.ordersSrv.validate_approval(this.order.id).then((response) => {
      this.totalDiscountApp = response.payload.data.total_discount;
      if (response.payload.data.valid) {
        this.ui.snackbar.show('VALIDA: ' + this.totalDiscountApp);
      } else {
        this.ui.snackbar.show('NECESITA APROBACION: ' + this.totalDiscountApp);
      }
    });
  }

  showOrderItemModal(title, submit, item) {
    this.modalOpen = true;
    this.itemModalTitle = title;
    this.submitModal = submit;
    this.boPriceLocked = true;
    if (item && item.product) {
      let product = item.product;
      delete item.product;
      Object.assign(item, {
        maximum_quantity: parseFloat(product.maximum_quantity),
        minimum_quantity: parseFloat(product.minimum_quantity),
      });
    }
    if (item) {
      this.nuOrderItem = {
        ...item,
        name: item.product_name || item.item_name,
        quantity: parseFloat(item.quantity),
        base_cost: parseFloat(item.product_cost),
        price: parseFloat(item.price),
        discount: parseFloat(item.discount),
        discount_percent: (parseFloat(item.discount) / (parseFloat(item.quantity) * parseFloat(item.price))) * 100,
      };
      this.selectedItem = this.nuOrderItem;
    } else {
      this.nuOrderItem = this.createBaseItem();
    }
  }

  showOpenOrderModal() {
    this.modalDialogOpen = true;
  }

  showFinishOrderModal() {
    this.finishOrderDialogOpen = true;
  }

  showCancelModal() {
    this.cancelOrderDialogOpen = true;
  }

  showProjectModal() {
    this.modalProjectOpen = true;
    this.modalProjectOption = true;
    this.customerProjects = [];
    this.projectsSrv.fetch(`?q[customer_name_eq]=${this.order.customer.name}`).then((projects) => {
      if (projects) {
        this.customerProjects = (<any[]>projects.collection.asArray()).filter(
          (project) => !this.order.projects.map((elem) => elem.id).includes(project.id)
        );
      } else {
        this.modalProjectOption = false;
      }
    });
  }

  showSellOrdersModal() {
    this.modalSellOrdersOpen = true;
    this.searchSellOrders();
  }

  get productsCollection() {
    return this.productsSrv.collection();
  }

  public get modeTexts() {
    return this.mode === 'sell' ? sellOrders : buyOrders;
  }

  public get orderItems(): Array<any> {
    const itemsField = this.mode === 'buy' ? 'purchase_order_items' : 'order_items';
    return this.order[itemsField];
  }

  public get orderUser(): any {
    const userField = this.mode === 'buy' ? 'supplier' : 'customer';
    return this.order[userField];
  }

  public get userDetailsUrl(): any {
    const baseUrl = this.mode === 'buy' ? '/tasks/suppliers/' : '/tasks/customers/';
    const id = this.orderUser?.id;
    return `${baseUrl}/${id}`;
  }

  public createBaseItem() {
    const params = this.mode === 'buy' ? { purchase_order_id: this.order.id } : { order_id: this.order.id };
    return this.itemsService.create(params);
  }

  get productLink() {
    const baseUrl = this.mode === 'buy' ? '/orders/buy-products/' : '/orders/sell-products/';
    return `${baseUrl}/`;
  }

  get itemsConditionProp() {
    return this.mode === 'buy' ? 'purchase_order_item_conditions' : 'order_item_conditions';
  }

  get orderEditLink() {
    return this.mode === 'buy' ? '/orders/buy-orders/' : '/orders/sell-orders/';
  }

  public canOpenOrder(order) {
    if (this.mode === 'sell') {
      return this.isAuthorized() && ['approved', 'finished', 'canceled'].includes(order.status);
    } else {
      return this.isAdmin && ['finished', 'canceled'].includes(order.status);
    }
  }

  public getItemConditions(item): Array<any> {
    return this.mode === 'sell' ? item.order_item_conditions : item.purchase_order_item_conditions;
  }

  public get canShowAddSellOrders(): boolean {
    return this.mode === 'buy' && ['initial', 'finished'].includes(this.order.status);
  }

  private get sellOrdersQueryParams() {
    const pageSize = this.sellOrdersPaginator.pageSize;
    const pageIndex = this.sellOrdersPaginator.pageIndex + 1;

    return `?page=${pageIndex}&per_page=${pageSize}`;
  }

  changeSellOrdersPage(paginator: any) {
    this.sellOrdersPaginator.pageIndex = paginator.pageIndex;
    this.sellOrdersPaginator.pageSize = paginator.pageSize;
    this.updateRouterQueryParams();
  }

  private updateRouterQueryParams() {
    const queryParams = {
      page: this.sellOrdersPaginator.pageIndex + 1,
      per_page: this.sellOrdersPaginator.pageSize,
    } as Params;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }

  private searchSellOrders() {
    const query = this.addSellOrderFilter ? `[sequence_eq]=${this.addSellOrderFilter}&` : null;
    this.ordersSrv.fetch(`?q${query}page=1&per_page=5`).then((orders) => {
      this.addSellOrdersList = orders ? orders.collection.asArray() : [];
    });
  }

  public addSellOrder(order: IOrder) {
    this.purchaseOrdersSrv
      .addBuyOrder(this.order.id, order.id)
      .toPromise()
      .then((res) => {
        if (res.ok) {
          this.sellOrders = [...this.sellOrders, order];
        } else {
          this.ui.snackbar.show('Algo salio mal al añadir la orden de compra');
        }
        this.closeAddSellOrderModal();
        this.getSellOrders(this.order.id);
      });
  }

  public closeAddSellOrderModal() {
    this.modalSellOrdersOpen = false;
  }

  public canSelectSellOrder(order) {
    return !!this.sellOrders.find((o) => o.id === order.id);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ProductStatus } from '../enums/product-status.enum';

@Pipe({
  name: 'productStatus',
})
export class ProductStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case ProductStatus.ACTIVE:
        return 'Activo';
      case ProductStatus.INACTIVE:
        return 'Inactivo';
      default:
        break;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ConditionsService } from '../../core/services/conditions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'conditions-create',
  templateUrl: 'conditions-create-edit.template.html',
})
export class ConditionsCreateComponent implements OnInit {
  public condition;
  constructor(
    private conditionsSrv: ConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Nueva Condición';
    this.condition = this.conditionsSrv.create();
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.saveCondition();
  }
  saveCondition() {
    this.conditionsSrv.save(this.condition).then((condition) => {
      this.ui.snackbar.show({
        message: 'Condición creada!',
      });
      this.router.navigate(['/orders/conditions/' + condition.id]);
    });
  }
}

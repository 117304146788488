import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../core/services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { UserGroupsService } from '../../core/services/usergroups.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'users-create',
  templateUrl: './users-create-edit.template.html',
})
export class UsersCreateComponent implements OnInit {
  public user;
  private userGroupsOpts = [];
  private genderOptions = UsersService.genderOptions;
  private statusOptions = UsersService.statusOptions;
  private currentUserRole;
  constructor(
    private usersSrv: UsersService,
    private userGroupsSrv: UserGroupsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService,
    private auth: AuthService
  ) {}
  ngOnInit() {
    this.user = this.usersSrv.create();
    this.userGroupsSrv
      .fetch()
      .then(
        (userGroupCollection) =>
          (this.userGroupsOpts = userGroupCollection.asOptions('id', 'name'))
      );
    this.currentUserRole = this.auth.getCurrentRole();
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.saveUser();
  }
  saveUser() {
    this.usersSrv.save(this.user).then((user) => {
      if (user) {
        this.ui.snackbar.show({
          message: 'Usuario creado!',
        });
        this.router.navigate(['/tasks/users/' + user.id]);
      } else {
        this.ui.snackbar.show({
          message: 'Email en uso',
        });
      }
    });
  }
}

import { Component, Input } from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';

@Component({
  selector: 'ui-field-display',
  templateUrl: 'ui-field-display.template.html',
  styleUrls: ['ui-field-display.styles.scss'],
})
export class UiFieldDisplayComponent {
  @Input() label: string;
  @Input() value: string;
  // @Input() type: string;
  // @Input() icon: string;
  @Input() width: string;
  @Input() @BooleanFieldValue() dontHide: boolean = false;
  @Input() @BooleanFieldValue() clickable: boolean = false;
  @Input() @BooleanFieldValue() hideIf: boolean = false;

  constructor() {}
}

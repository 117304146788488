import { Component, OnInit } from '@angular/core';
import { DummiesService } from '../../core/services/dummies.service';
import { UiService } from '../../core/services/ui.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dummies-index',
  templateUrl: 'dummies-index.template.html',
})
export class DummiesIndexComponent implements OnInit {
  public dummies;
  constructor(
    private dummiesSrv: DummiesService,
    private router: Router,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Dummies';
    this.dummiesSrv.fetch().then((collection) => (this.dummies = collection.asArray()));
  }
  goTo(url) {
    this.router.navigate([url]);
  }
}

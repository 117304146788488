import { Injectable } from '@angular/core';
import { HttpX } from './http-x.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class AttachmentsService extends ApiService {
  allowedExtensions = [
    'pages', 'rtf', 'txt', 'doc', 'docx', 'pdf', 'odt',
    'csv', 'xml', 'xls', 'xlsx', 'xlr', 'ods',
    'key', 'pps', 'ppt', 'pptx', 'odp',
    'aif', 'm3u', 'm4a', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma',
    '3gp', 'avi', 'flv', 'h264', 'm4v', 'mkv', 'mov', 'mp4', 'mpg', 'mpeg', 'rm', 'swf', 'wmv',
    'fnt', 'fon', 'otf', 'ttf', 'woff',
    '7z', 'zip', 'zipx', 'rar', 'gz',
    'ai', 'psd', 'sketch',
    'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'png', 'ico', 'tif', 'tiff'
  ];
  maxLoadSize = 20000000;
  maxFileCount = 5;

  constructor(
    private http: HttpX,
    private auth: AuthService
  ) {
    super('attachment', 'attachments', http, auth);
    this.asyncSave = true;
  }
}

import { Component, Input } from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';

@Component({
  selector: 'ui-more-menu',
  templateUrl: 'ui-more-menu.template.html',
  styleUrls: ['ui-more-menu.styles.scss'],
})
export class UiMoreMenuComponent {
  @Input() public actions: any[] = [];
  @Input() public params: any;
  @Input() public icon: string = 'more-vert';
  @Input() @BooleanFieldValue() public alignRight: boolean = false;

  public isOpen = false;
  constructor() {}
  public clickHandler($event) {
    $event.preventDefault();
    this.isOpen = true;
  }
}

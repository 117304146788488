import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tasks',
  templateUrl: 'tasks.template.html',
})
export class TasksComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}

<div class="ui-toolbar">
  <div class="ui-toolbar__actions">
    <ng-content select=".ui-toolbar-actions"></ng-content>
  </div>
  <div class="ui-toolbar__content">
    <ng-content select=".ui-toolbar-content"></ng-content>
  </div>
  <div class="ui-toolbar__menu">
    <div class="ui-toolbar__menu-action">
      <button class="ui-toolbar__btn" (click)="handleAction($event)">
        <span class="icon icon-menu"></span>
      </button>
    </div>
    <img class="ui-toolbar__logo" src="https://ontask.co/assets/images/ontask-logo.png">
    <h2 class="ui-toolbar__menu-title">
      {{ title }}
    </h2>
  </div>

</div>

<button #button [class]="'ui-btn' + (color ? ' ui-btn--' + color : '')" [class.ui-btn--flat]="flat"
  [class.ui-btn--fab]="fab || side" [class.ui-btn--side]="side" [class.ui-btn--text-raw]=textRaw [disabled]="disabled"
  [type]="type || 'button'" (mousedown)="mouseDownHandler($event)" (click)="clickHandler($event)"
  [tabindex]="notab ? '-1' : null">
  <span class="ui-btn__label">
    <span *ngIf="icon" [class]="'icon icon-' + icon"></span>
    <img *ngIf="image" class="ui-btn__image" [src]="image" />
    <ng-content></ng-content>
  </span>
  <div #ripple class="ui-btn__ripple"></div>
</button>
<span *ngIf="hint" class="ui-btn__hint">
  {{ hint }}
</span>
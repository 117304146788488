<div [class]="'ui-form-element ' + (width || '')"
  [class.is-active]="isActive"
  [class.disabled]="disabled"
  [class.has-errors]="hasErrors()">
  <label #labelEl [for]="elemId">
    {{ label }}
  </label>
  <div class="ui-select">
    <span *ngIf="!value" class="ui-select__placeholder">
      {{ placeholder }}
    </span>
    <select #selectModel="ngModel"
      [id]="elemId"
      [attr.name]="name || elemId"
      [ngModel]="value"
      (ngModelChange)="ngModelChangeHandler($event)"
      [required]="required"
      [disabled]="disabled"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
      class="ui-select__element">
      <option *ngIf="!required" [ngValue]="null"></option>
      <option *ngFor="let o of options" [value]="o.value">
        {{ o.label }}
      </option>
    </select>
    <span class="ui-form-element__icon ui-select__arrow"></span>
    <div class="ui-form-element__underline">
      <div class="ui-form-element__highlight"></div>
    </div>
  </div>
  <div *ngIf="hasErrors()" class="ui-form-element__errors">
    <div [hidden]="!selectModel.errors.required">
      This field is required
    </div>
  </div>
</div>

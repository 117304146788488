<div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
  <div fxFlex="grow" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container *ngIf="activeFilters > 0; else noFilters">
              <ui-badge [value]="activeFilters" small> Filtros </ui-badge>
            </ng-container>
            <ng-template #noFilters> Filtros </ng-template>
          </mat-panel-title>
          <mat-panel-description> Esto te permitirá filtrar las productos según criterios </mat-panel-description>
        </mat-expansion-panel-header>
        <form
          [formGroup]="filtersForm"
          (ngSubmit)="filter()"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <ui-input label="Nombre del producto" fxFlex="grow" formControlName="productName"> </ui-input>
          <ui-select label="Estado" fxFlex="100px" formControlName="productStatus" [options]="PRODUCT_STATUSES">
          </ui-select>
          <ui-btn class="padding" type="submit" fab color="primary" hint="Filtrar" icon="filter"> </ui-btn>
        </form>
        <div class="margin-bottom--half">
          <mat-chip-list aria-label="Filtros">
            <mat-chip *ngIf="filters.productName as name" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Nombre</b>: {{ name }}
                <mat-icon matChipRemove (click)="clearFilter('productName')"> cancel </mat-icon>
              </div>
            </mat-chip>
            <mat-chip *ngIf="filters.productStatus as status" selected="true" removable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <b>Estado</b>: {{ status | productStatus }}
                <mat-icon matChipRemove (click)="clearFilter('productStatus')"> cancel </mat-icon>
              </div>
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="ui-panel no-padding">
      <ng-container *ngIf="!isLoading; else loading">
        <table class="ui-table">
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>Precio</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of products">
              <td><span class="icon icon-products"></span></td>
              <td (click)="goTo('/orders/products/' + p.id )">{{ p.name }}</td>
              <td>{{ p.base_price | number }}</td>
              <td>{{ p.status | productStatus }}</td>
            </tr>
          </tbody>
        </table>
        <mat-paginator
          [pageIndex]="paginator.pageIndex"
          [length]="paginator.length"
          [pageSize]="paginator.pageSize"
          [pageSizeOptions]="paginator.pageSizeOptions"
          (page)="changePage($event)"
        >
        </mat-paginator>
      </ng-container>
      <ng-template #loading>
        <div class="padding" fxLayout="row" fxLayoutAlign="center cener">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__secondary">
    <!-- <ui-btn
      *ngIf="filtered_by == 'active'"
      fab
      color="success"
      (uiClick)="disableFilterBy()"
      hint="Ver todos los productos"
      icon="list-check"
    >
    </ui-btn> -->
    <!-- <ui-btn *ngIf="filtered_by == 'all'" fab color="warning" (uiClick)="filterByStatus()"
      hint="Ver sólo productos activos" icon="list">
    </ui-btn> -->
  </div>
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/orders/products/new')" hint="Crear producto" icon="add"> </ui-btn>
  </div>
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/orders/products/new')" hint="Crear producto" icon="add"> </ui-btn>
  </div>
</div>

export const sidebarSubActions = [
  {
    label: 'Tareas',
    icon: 'task',
    url: '/tasks/dashboard'
  },
  {
    label: 'Dummies',
    icon: 'person',
    url: '/dummies/'
  },
  {
    label: 'Orders',
    icon: 'fire',
    url: '/orders/products'
  }
];

// export const routeTitles = {
//   '/dashboard': 'Resumen',
//   '/customers': 'Clientes',
//   '/projects': 'Proyectos',
//   '/tasks': 'Tareas',
//   '/usergroups': 'Grupos',
//   '/users': 'Personas',
// };

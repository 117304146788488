import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { OrderByPipe } from '../../shared/pipes/';

import { CustomersService } from '../../core/services/customers.service';
import { UiService } from '../../core/services/ui.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'customers-index',
  templateUrl: 'customers-index.template.html',
})
export class CustomersIndexComponent implements OnInit {
  private customers;
  private isAuthorized = false;
  public isLoading: boolean;
  private DEFAULT_PAGE_SIZE = 25;
  private DEFAULT_PAGE_INDEX = 0;
  public paginator = {
    pageIndex: this.DEFAULT_PAGE_INDEX,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  };
  public filtersForm: FormGroup;
  public filters = {
    customerName: null,
    legalName: null,
  };
  private noSyncForm = false;
  private orderBy = new OrderByPipe();

  constructor(
    private customersSrv: CustomersService,
    private router: Router,
    private ui: UiService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Clientes';
    this.initFiltersForm();
    this.isAuthorized = this.auth.isAuthorized();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!this.noSyncForm && Object.keys(params).length > 0) {
        this.syncFilterWithUrl(params);
      } else {
        this.noSyncForm = false;
      }
      this.fetch();
    });
  }

  initFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      customerName: [],
      legalName: [],
    });
  }

  fetch() {
    this.isLoading = true;
    this.customersSrv
      .fetch(this.indexQueryParams)
      .then((res) => {
        this.paginator.length = res.pagination.total;
        this.customers = this.orderBy.transform(res.collection.asArray(), 'name');
        this.isLoading = false;
      })
      .catch(() => (this.isLoading = false));
  }

  changePage(paginator: any) {
    this.paginator.pageIndex = paginator.pageIndex;
    this.paginator.pageSize = paginator.pageSize;
    this.updateRouterQueryParams();
  }

  goTo(url) {
    this.router.navigate([url]);
  }

  filter() {
    const value = this.filtersForm.value;
    Object.assign(this.filters, value);
    this.paginator.pageIndex = 0;
    this.noSyncForm = true;
    this.updateRouterQueryParams();
  }

  clearFilter(filterKey: 'customerName' | 'legalName') {
    this.filters[filterKey] = null;
    this.filtersForm.get(filterKey).setValue(null);
    this.fetch();
  }

  get activeFilters() {
    return +!!this.filters.customerName + +!!this.filters.legalName;
  }

  private syncFilterWithUrl(queryParams: Params) {
    this.paginator.pageIndex = queryParams.page > -1 ? queryParams.page - 1 : this.DEFAULT_PAGE_INDEX;
    this.paginator.pageSize = this.isPageSizeValid(queryParams.per_page)
      ? Number(queryParams.per_page)
      : this.DEFAULT_PAGE_SIZE;
    this.filters.customerName = queryParams.customer_name;
    this.filters.legalName = queryParams.legal_name;
    this.updateFiltersForm();
  }

  private updateFiltersForm() {
    this.filtersForm.controls.customerName.setValue(this.filters.customerName);
    this.filtersForm.controls.legalName.setValue(this.filters.legalName);
  }

  private updateRouterQueryParams() {
    const queryParams = this.formatToQueryParams;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  private isPageSizeValid(pageSize: string) {
    return this.paginator.pageSizeOptions.indexOf(Number(pageSize)) !== -1;
  }

  private get indexQueryParams() {
    const pageSize = this.paginator.pageSize;
    const pageIndex = this.paginator.pageIndex + 1;
    const { customerName, legalName } = this.filters;
    let url = `?page=${pageIndex}&per_page=${pageSize}`;
    if (this.activeFilters > 0) {
      if (customerName) {
        url += `&q[name_cont]=${customerName}`;
      }
      if (legalName) {
        url += `&q[legal_name_cont]=${legalName}`;
      }
    }
    this.updateRouterQueryParams();
    return url;
  }

  private get formatToQueryParams() {
    const params = {
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
    } as Params;
    const { customerName, legalName } = this.filters;
    if (customerName) {
      params.customer_name = customerName;
    }
    if (legalName) {
      params.legal_name = legalName;
    }
    return params;
  }

  addCustomerRedirect() {
    if (this.isAuthorized) {
      this.goTo(`/tasks/customers/new`);
    } else {
      this.ui.snackbar.show('No tienes permisos para crear clientes.');
    }
  }
}

export function isString(value: any) {
  return (value.constructor === String);
}

export function loadFile(file) {
  return new Promise((resolve, reject) => {
    if (file.size > 20000000) {
      reject({ errors: { maxSizeExceeded: true } });
    } else {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        resolve({
          type: file.type,
          name: file.name,
          size: file.size,
          extension: file.name.split(".").slice(-1)[0],
          data: evt.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  });
}

export function loadFiles(fileList: FileList) {
  const promiseArr = [];
  for (let i = 0; i < fileList.length; i++) {
    promiseArr.push(loadFile(fileList[i]));
  }
  return Promise.all(promiseArr)
    .then((loadedFiles) => {
      let size = 0;
      const extensions = [];
      const extObj = {};
      for (const loadedFile of loadedFiles) {
        size += loadedFile.size;
        if (!extObj[loadedFile.extension]) {
          extObj[loadedFile.extension] = true;
          extensions.push(loadedFile.extension);
        }
      }
      return {
        size,
        extensions,
        files: loadedFiles,
      };
    }).catch((error) => {
      return error;
    });
}

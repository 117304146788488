<form #userGroupForm="ngForm" (submit)="handleSubmit($event)">
  <div class="ui-panel" *ngIf="userGroup">
    <h1 class="text-center"
      [class.color-disabled]="!userGroup.name">
      {{ userGroup.name || 'Agrega un nombre de grupo'}}
    </h1>
    <ui-input label="Nombre" name="userGroup_name" [(ngModel)]="userGroup.name"
      placeholder="Grupo de ejemplo" required>
    </ui-input>
    <ui-input type="textarea" label="Descripción" name="userGroup_summary" [(ngModel)]="userGroup.summary"
      placeholder="Añade una descripción para tu grupo aquí">
    </ui-input>
  </div>

  <div class="ui-speed-dial">
    <div class="ui-speed-dial__main">
      <ui-btn fab color="accent" (uiClick)="saveUserGroup()" hint="Guardar grupo"
        [disabled]="!userGroupForm.form.valid" icon="save">
      </ui-btn>
    </div>
  </div>
</form>

import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { InternalAppComponent } from './internal-app.component';
import { SigninComponent } from './core/sessions/signin.component';
import { SignUpComponent } from './core/sessions/sign-up.component';

import { AuthGuardService } from './core/services/auth-guard.service';
import { AuthAdminGuardService } from './core/services/auth-admin-guard.service';

import { TasksAppModule } from './tasks-app/tasks-app.module';
import { DummiesAppModule } from './dummies-app/dummies-app.module';
import { OrdersAppModule } from './orders-app/orders-app.module';

const routes: Routes = [
  { path: '', component: SigninComponent },
  { path: 'signup', component: SignUpComponent },
  {
    path: '',
    component: InternalAppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'tasks', loadChildren: () => TasksAppModule },
      { path: 'dummies', loadChildren: () => DummiesAppModule },
      { path: 'orders', loadChildren: () => OrdersAppModule },
      // { path: 'tasks', loadChildren: './tasks-app/tasks-app.module.ts#TasksAppModule'},
      // { path: 'dummies', loadChildren: './dummies-app/dummies-app.module.ts#DummiesAppModule'}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [AuthGuardService, AuthAdminGuardService],
})
export class AppRoutesModule {}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ui-modal",
  templateUrl: "ui-modal.template.html",
  styleUrls: ["ui-modal.styles.scss"],
})
export class UiModalComponent implements OnInit {
  @Input() public isOpen: boolean = false;
  @Output() public isOpenChange = new EventEmitter();
  @Input() public modalWidth: string = "";
  constructor() {}

  public ngOnInit() {}

  public close() {
    this.isOpenChange.emit(false);
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cost-collection',
  templateUrl: 'cost-collection.template.html',
})
export class CostCollectionComponent {
  @Input() costs: Array<any>;
  @Input() isAuthorized: boolean;
  @Output() editCost = new EventEmitter();
  @Output() deleteCost = new EventEmitter();
  private actionMenu = [
    {
      icon: 'edit',
      label: 'Editar',
      action: (cost) => {
        this.editCost.emit(cost);
      },
    },
    {
      icon: 'delete',
      label: 'Eliminar',
      action: (cost) => {
        this.deleteCost.emit(cost);
      },
    },
  ];
  constructor() {}
}

<div *ngIf="paymentcondition">
  <div class="ui-panel">
    <h1 class="text-center">{{ paymentcondition.name }}</h1>
    <ui-field-display label="Descripción" [value]="paymentcondition.order_text"></ui-field-display>
    <div class="ui-field-group one-half">
      <ui-field-display label="Registrado por" *ngIf="paymentcondition.registered_by"
        [value]="paymentcondition.registered_by?.person.first_name + ' ' + paymentcondition.registered_by?.person.last_name"
        (click)="goTo('/tasks/users/' + paymentcondition.registered_by?.id )" clickable>
      </ui-field-display>
    </div>
  </div>
</div>

<div class="ui-speed-dial" *ngIf="paymentcondition">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/orders/paymentconditions/' + (paymentcondition?.id || 0) + '/edit')"
      hint="Editar condición de pago" icon="edit">
    </ui-btn>
  </div>
</div>

<div class="ui-user-actions" *ngIf="userData">
  <div class="ui-user-header" (click)="openUserMenu($event)">
    <span class="ui-user-header__label">
      {{ userData.nickname ? userData.nickname : userData.first_name }}
    </span>
    <img class="ui-user-header__avatar" [src]="userData.avatar_url || avatarBlank" />
  </div>

  <div #menuEl class="ui-user-menu" [class.is-open]="userMenuOpen">
    <div class="ui-user-menu__padding">
      <img class="ui-user-menu__avatar" [src]="userData.avatar_url || avatarBlank" />
      <div class="ui-user-menu__content">
        <p class="ui-user-menu__name">{{ userData.first_name }} {{ userData.last_name }}</p>
        <p>{{ userData.email  }}</p>
        <p>{{ userData.role }}</p>
        <p>{{ userData.organization_name }}</p>
        <div class="ui-user-menu__extras">
          <ui-btn color="accent" (uiClick)="goTo('/tasks/users/' + (userData.id || 0))">Mi perfil</ui-btn>
        </div>
        <div class="ui-user-menu__extras">
          <ui-btn color="accent" (uiClick)="logoutClickHandler($event)">Cerrar Sesión</ui-btn>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BooleanFieldValue } from '../../shared/annotations';

const uniqueId = 0;

@Component({
  selector: 'ui-menu',
  templateUrl: 'ui-menu.template.html',
  styleUrls: ['ui-menu.styles.scss'],
})
export class UiMenuComponent {
  @Input() public isOpen: boolean = false;
  @Output() public isOpenChange = new EventEmitter();
  @Input() public actions: any[] = [];
  @Input() public params: any = null;
  @Input() @BooleanFieldValue() public alignRight: boolean = false;

  constructor() {}

  public isOpenChangeHandler($event) {
    this.isOpenChange.emit($event);
  }
  public close() {
    this.isOpenChange.emit(false);
  }
  public clickActionHandler($event, action) {
    $event.preventDefault();
    if (action.action) {
      action.action(this.params);
    }
    this.close();
  }
  public shouldHideAction(action) {
    return action.hideIf && action.hideIf(this.params);
  }
}

import { Component } from "@angular/core";
import { isString } from "../shared/helpers";

@Component({
  selector: "ui-alert",
  templateUrl: "ui-alert.template.html",
  styleUrls: ["ui-alert.styles.scss"],
})
export class UiAlertComponent {
  public params: any = {};
  public isOpen: boolean = false;

  public show(newParams) {
    this.params = isString(newParams) ? { message: newParams } : newParams;
    this.isOpen = true;
  }
  public hide() {
    this.isOpen = false;
  }
  public cancelHandler() {
    if (this.params.onCancel) { this.params.onCancel(); }
    this.hide();
  }
  public noHandler() {
    if (this.params.onNo) { this.params.onNo(); }
    this.hide();
  }
  public okHandler() {
    if (this.params.onOk) { this.params.onOk(); }
    this.hide();
  }
}

<div class="ui-calendar" *ngIf="month">
  <div class="ui-calendar__header">
    <span class="ui-calendar__header-control float-start" (click)="changeMonth(-1)" tabindex="-1">
      <span class="icon icon-caret-left"></span>
    </span>
    {{ month.label }}
    <!-- , {{ month.yearLabel }} -->
    <span class="ui-calendar__header-control float-end" (click)="changeMonth(1)" tabindex="-1">
      <span class="icon icon-caret-right"></span>
    </span>
  </div>

  <div class="ui-calendar__controls">
    <span class="ui-calendar__control float-start" (click)="changeYear(-1)" tabindex="-1">
      <span class="icon icon-caret-left"></span>
    </span>
    {{ month.yearLabel }}
    <span class="ui-calendar__control float-end" (click)="changeYear(1)" tabindex="-1">
      <span class="icon icon-caret-right"></span>
    </span>
  </div>

  <div class="ui-calendar__quick-options">
    <div *ngFor="let q of quickOptions" (click)="handleSelectQuickOption(q.value)" tabindex="-1">
      <span [ngClass]="['icon icon-' + q.icon]"></span>
      <span>{{ q.label }}</span>
    </div>
  </div>

  <div class="ui-calendar__table-container">
    <table class="ui-calendar__table">
      <thead>
        <tr>
          <th *ngFor="let day of daysOfWeek">{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let week of month.weeks">
          <td *ngFor="let day of week">
            <span class="ui-calendar__day" [class.is-disabled]="day.diffCurrentMonth" [class.is-today]="day.isToday"
              [class.is-selected]="day.isSelected" (click)="selectDay(day)">
              {{ day.label }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
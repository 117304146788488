<div class="ui-panel  no-padding">
  <table class="ui-table">
    <thead>
      <tr>
        <th></th>
        <th>Nombre</th>
        <th>Descripción</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of paymentconditions | filterBy : filters ">
        <td><span class="icon icon-conditions"></span></td>
        <td (click)="goTo('/orders/paymentconditions/' + c.id )">{{ c.name }}</td>
        <td>{{ c.order_text }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="ui-speed-dial">
  <div class="ui-speed-dial__main">
    <ui-btn fab color="accent" (uiClick)="goTo('/orders/paymentconditions/new')"
      hint="Crear condición de pago" icon="add">
    </ui-btn>
  </div>
</div>

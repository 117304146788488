import { Component, OnInit } from '@angular/core';
import { SuppliersService } from '../../core/services/suppliers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../../core/services/ui.service';
import { PurchaseOrdersService } from 'src/app/core/services/purchaseOrders.service';
import { buyOrders } from 'src/app/orders-app/orders/modeHelpers';
@Component({
  selector: 'suppliers-show',
  templateUrl: 'suppliers-show.template.html',
})
export class SuppliersShowComponent implements OnInit {
  private DEFAULT_PAGE_SIZE = 25;
  private DEFAULT_PAGE_INDEX = 0;

  public supplier = null;
  public orders = [];
  public modeTexts = buyOrders;
  public paginator = {
    pageIndex: this.DEFAULT_PAGE_INDEX,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
  };
  constructor(
    private suppliersSrv: SuppliersService,
    private purchaseOrdersSrv: PurchaseOrdersService,
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ui: UiService
  ) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Detalle de proveedor';
    const supplierId = this.route.snapshot.params['id'];
    this.suppliersSrv.find(supplierId).then((supplier) => {
      this.supplier = supplier;
      this.activatedRoute.queryParams.subscribe((params) => {
        this.fetch({
          page: this.paginator.pageIndex,
          per_page: this.paginator.pageSize,
          ...params,
          supplierId,
        } as any);
      });
    });
  }

  public fetch({ page, per_page }) {
    this.purchaseOrdersSrv.fetch(`?page=${page}&per_page=${per_page}&supplier_id=${this.supplier.id}`).then((data) => {
      this.orders = data.collection.asArray().reverse();
      this.paginator.length = data.pagination.total;
      this.paginator.pageSize = data.pagination.perPage;
    });
  }
  goTo(url) {
    this.router.navigate([url]);
  }

  changePage(paginator: any) {
    this.paginator.pageIndex = paginator.pageIndex;
    this.paginator.pageSize = paginator.pageSize;
    this.updateRouterQueryParams();
  }

  private updateRouterQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: this.paginator.pageIndex + 1,
        per_page: this.paginator.pageSize,
      },
    });
  }
}

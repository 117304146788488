export class Logger {
  private css = {
    main: 'color: #2196F3;',
    warning: 'color: #FFC107;',
    success: 'color: #009688;',
    error: 'color: #E53935;',
    stateChange: 'color: #333; background-color: #F0F0F0; font-weight: bold;',
  };
  private active = false;
  constructor() {
    this.active = window.location.hostname === 'localhost' || window.location.hostname === '0.0.0.0';
  }
  public log(msg, p1?, p2?, p3?) {
    this.base(msg, this.css.main, p1, p2, p3);
  }
  public warn(msg, p1?, p2?, p3?) {
    this.base(msg, this.css.warning, p1, p2, p3);
  }
  public success(msg, p1?, p2?, p3?) {
    this.base(msg, this.css.success, p1, p2, p3);
  }
  public error(msg, p1?, p2?, p3?) {
    this.base(msg, this.css.error, p1, p2, p3);
  }
  public stateChange(msg, p1?, p2?, p3?) {
    this.base(msg, this.css.stateChange, p1, p2, p3);
  }
  private baseLog(message) {
    console.log.apply(arguments);
  }
  private base(msg, css, p1?, p2?, p3?) {
    if (this.active) {
      const fmsg = '%c' + msg;
      const args = [];
      args.push(fmsg) && args.push(css) && p1 && args.push(p1) && p2 && args.push(p2) && p3 && args.push(p3);
    }
  }
}

export const logger = new Logger();

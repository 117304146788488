import { Component, OnInit } from '@angular/core';
import { ConditionsService } from '../../core/services/conditions.service';
import { Router } from '@angular/router';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'conditions-index',
  templateUrl: 'conditions-index.template.html',
})
export class ConditionsIndexComponent implements OnInit {
  public conditions;
  public filters = {};
  private filtered_by;
  public loading = true;

  constructor(private conditionsSrv: ConditionsService, private router: Router, private ui: UiService) {}
  ngOnInit() {
    this.ui.toolbar.title = 'Condiciones';
    this.conditionsSrv.fetch().then((collection) => {
      this.loading = false;
      this.conditions = collection.asArray();
    });
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
  }
  goTo(url) {
    this.router.navigate([url]);
  }
  filterByStatus() {
    this.filters = { status: 'active' };
    this.filtered_by = 'active';
  }
  disableFilterBy() {
    this.filters = {};
    this.filtered_by = 'all';
  }
}
